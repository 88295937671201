<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page">
            Pooling Agreements
        </li>
    </ol>
</nav>
<div class="container">
    <app-alert-display></app-alert-display>

    <div class="row">
        <div class="col">
            <div style="margin: 1rem 0 1rem 0" class="float-end">
                <a
                    class="btn btn-splash btn-sm ms-3"
                    routerLink="/pooling-agreements/create"
                    *ngIf="isCurrentUserAnAdministrator()"
                    >Create a Pooling Agreement</a
                >
            </div>
            <h2 class="d-inline-block">Pooling Agreements</h2>
        </div>
    </div>
    <div class="row mt-1 mb-2">
        <div class="col">
            <custom-rich-text
                [customRichTextTypeID]="richTextTypeID"
            ></custom-rich-text>
        </div>
    </div>
</div>
<div class="container">
    <div
        class="row mb-2 align-items-end"
        *ngIf="isCurrentUserAnAdministrator()"
    >
        <div class="col-auto">
            <span class="mt-auto"
                >Selected rows: {{ selectedRows().length }}</span
            >
        </div>
        <div class="col-auto ms-auto">
            <clear-grid-filters-button
                [grid]="poolingAgreementsGrid"
            ></clear-grid-filters-button>
            <button
                class="btn btn-splash ms-2"
                [disabled]="selectedRows().length === 0"
                (click)="onDeleteClicked()"
            >
                Delete Selected
            </button>
        </div>
    </div>
    <div class="table-responsive">
        <ag-grid-angular
            #poolingAgreementsGrid
            style="width: 100%; height: 400px"
            class="ag-theme-balham"
            [rowData]="poolingAgreements"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [pagination]="true"
            [paginationPageSize]="100"
            rowSelection="multiple"
            [suppressMenuHide]="true"
        >
        </ag-grid-angular>
    </div>
</div>

<ng-template #deletePoolingAgreement let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="deletePoolingAgreement">
            Delete Pooling Agreement
        </h4>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="!disabled">
            Are you sure you want to delete the selected pooling agreement(s)?
            This action cannot be undone.
        </ng-container>
    </div>
    <div class="modal-footer">
        <ng-container *ngIf="!disabled">
            <button
                type="button"
                class="btn btn-splash btn-sm"
                [disabled]="isPerformingAction"
                (click)="onDelete()"
            >
                <span
                    *ngIf="isPerformingAction"
                    class="fa fa-spinner loading-spinner"
                ></span>
                Confirm
            </button>
            <button
                type="button"
                class="btn btn-secondary btn-sm"
                (click)="modal.close('Cancel click')"
                [disabled]="isPerformingAction"
            >
                Cancel
            </button>
        </ng-container>
        <ng-container *ngIf="disabled">
            <button
                type="button"
                class="btn btn-splash btn-sm"
                (click)="modal.close('Cancel click')"
            >
                Close
            </button>
        </ng-container>
    </div>
</ng-template>
