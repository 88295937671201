<h2>{{ stepTitle() }}</h2>
<div class="row m-2">
    <p class="col">
        Select the tracts to include in this pooling agreement. Click the map to
        select individual tracts involved in this transfer or use the “Select
        All” button and the volume will be distributed proportionally across all
        tracts in the pool.
    </p>
</div>
<div class="row m-2">
    <div class="col-md-8">
        <button
            class="me-1 btn btn-sm btn-secondary"
            (click)="selectAllTracts()"
            [disabled]="visibleTractIDs.length === selectedTracts.length"
        >
            Select All
        </button>
        <button
            class="me-1 btn btn-sm btn-secondary"
            (click)="removeAllTracts()"
            [disabled]="selectedTracts.length === 0"
        >
            Remove All
        </button>
        <small
            >[{{ selectedTracts.length }} out of
            {{ visibleTractIDs.length }} selected]</small
        >
    </div>
</div>

<tract-map
    #tractsMap
    mapID="tractsMap{{ poolingAgreementTractType }}"
    mapHeight="500px"
    [zoomMapToDefaultExtent]="false"
    [visibleTractIDs]="visibleTractIDs"
    [disableDefaultClick]="true"
    [selectedTractIDs]="getSelectedTractIDs()"
    [selectedTractLayerName]="selectedTractLayerName"
    [displayWells]="false"
>
</tract-map>

<div class="col-lg-12 m-2">
    <div>
        <table
            class="table table-condensed table-responsive"
            *ngIf="selectedTracts?.length"
        >
            <thead>
                <tr>
                    <th>Tract</th>
                    <th>Allocation</th>
                    <th>Water Available</th>
                    <th>Volume to Transfer</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="
                        let selectedTract of selectedTracts;
                        let index = index;
                        trackBy: trackByFunction
                    "
                >
                    <td>
                        Tract {{ selectedTract.tract.TractNumber }}
                        <br />
                        <button
                            class="btn btn-xs btn-splash"
                            (click)="openTractDetailsModal(selectedTract.tract)"
                            style="cursor: pointer"
                            title="Click to view tract details"
                        >
                            Details
                        </button>
                    </td>
                    <td>
                        {{
                            getTractAllocationForAllocationPeriod(
                                selectedTract.tract
                            ) | number: "1.1-2"
                        }}
                    </td>
                    <td>
                        {{
                            getTractRemainingWaterForAllocationPeriod(
                                selectedTract.tract
                            ) | number: "1.1-2"
                        }}
                    </td>
                    <td>
                        <input
                            required
                            type="number"
                            name="acreInchesTransferred[{{ index }}]"
                            class="form-control"
                            style="width: 50%"
                            [(ngModel)]="
                                selectedTracts[index].AcreInchesTransferred
                            "
                        />
                        <small
                            class="text-danger"
                            *ngIf="isTractInvalid(selectedTracts[index])"
                        >
                            Volume transferred must be greater than zero
                        </small>
                        <small
                            class="text-danger"
                            *ngIf="
                                poolingAgreementTractType ==
                                    PoolingAgreementTractTypeEnum.Sending &&
                                !tractTransferVolumeIsValid(
                                    selectedTracts[index]
                                )
                            "
                        >
                            Volume transferred must not exceed available water
                            for each tract.
                        </small>
                    </td>
                    <td>
                        {{
                            (100.0 * selectedTract.AcreInchesTransferred) /
                                poolingAgreement.TransferVolume
                                | number: "1.0-0"
                        }}
                        %
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td></td>
                    <td></td>
                    <td class="fw-bold">Remaining</td>
                    <td>
                        {{ getRemainingTransferVolume() | number: "1.1-2" }}
                        <small
                            class="text-danger"
                            *ngIf="!getRemainingTransferVolumeWithinTolerance()"
                        >
                            <br />
                            Remaining transfer volume must be zero
                        </small>
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td class="fw-bold">Total Transfer Volume</td>
                    <td>
                        {{ poolingAgreement.TransferVolume | number: "1.1-2" }}
                    </td>
                    <td></td>
                </tr>
            </tfoot>
        </table>
        <small *ngIf="selectedTracts.length" class="text-italic"
            >All water volumes are presented in acre-inches.</small
        >
        <p *ngIf="!selectedTracts?.length">No Tracts have been selected yet.</p>
    </div>
</div>

<ng-template #tractDetailsModal let-modal>
    <div class="modal-header w-100">
        <h4 class="modal-title" id="upsertModalTitle">
            Tract {{ moreDetailsTract.TractNumber }} Details
        </h4>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body w-100">
        <dl class="row">
            <dt class="text-sm-end col-sm-6 col-xs-12">Landowner</dt>
            <dd class="col-sm-6 col-xs-12">
                <a
                    *ngIf="moreDetailsTract.Landowner"
                    routerLink="/accounts/{{
                        moreDetailsTract.Landowner.AccountID
                    }}"
                    >{{ moreDetailsTract.Landowner.FullName }}</a
                >
            </dd>
        </dl>
        <dl class="row">
            <dt class="text-sm-end col-sm-6 col-xs-12">Primary Operator</dt>
            <dd class="col-sm-6 col-xs-12">
                <a
                    *ngIf="moreDetailsTract.PrimaryOperator"
                    routerLink="/accounts/{{
                        moreDetailsTract.PrimaryOperator.AccountID
                    }}"
                    >{{ moreDetailsTract.PrimaryOperator.FullName }}</a
                >
            </dd>
        </dl>
        <dl class="row">
            <dt class="text-sm-end col-sm-6 col-xs-12">
                <field-definition
                    [fieldDefinitionType]="'Legal'"
                ></field-definition>
            </dt>
            <dd class="col-sm-6 col-xs-12">
                {{ moreDetailsTract.Legal }}
            </dd>
        </dl>
        <dl class="row">
            <dt class="text-sm-end col-sm-6 col-xs-12">
                Section/Township/Range
            </dt>
            <dd class="col-sm-6 col-xs-12">
                {{ moreDetailsTract.Section }}-T{{
                    moreDetailsTract.Township
                }}-R{{ moreDetailsTract.Range
                }}{{ moreDetailsTract.RangeDirection }}
            </dd>
        </dl>
    </div>
    <div class="modal-footer w-100">
        <button
            type="button"
            class="btn btn-primary btn-sm"
            (click)="modal.close('Cancel click')"
            [disabled]="isPerformingAction"
        >
            Close
        </button>
    </div>
</ng-template>
