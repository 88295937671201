<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page">
            NeDNR Annual Report
        </li>
    </ol>
</nav>
<div class="container">
    <app-alert-display></app-alert-display>
    <div class="row">
        <div class="col">
            <h2 class="d-inline-block">NeDNR Annual Report</h2>
        </div>
    </div>
    <div class="row mt-1 mb-2">
        <div class="col">
            <custom-rich-text
                [customRichTextTypeID]="richTextTypeID"
            ></custom-rich-text>
        </div>
    </div>
    <div class="form-group row mt-4">
        <label class="col-sm-2 col-form-label">Reporting Year: </label>
        <div class="col-sm-2">
            <select [(ngModel)]="reportingYear" class="form-select">
                <option *ngFor="let year of reportingYears" [ngValue]="year">
                    {{ year }}
                </option>
            </select>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col">
            <div *ngIf="uploadError" class="alert alert-danger">
                {{ uploadErrorMessage }}
            </div>
            <button
                type="button"
                class="btn btn-primary"
                (click)="downloadAnnualReport()"
                ngbTooltip="Download Annual Report"
                [disabled]="isDownloading"
            >
                <span
                    *ngIf="isDownloading"
                    class="fa fa-spinner loading-spinner me-1"
                ></span>
                Download Annual Report
            </button>
        </div>
    </div>
</div>
