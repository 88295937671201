import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ChangeDetectionStrategy,
  ApplicationRef,
} from '@angular/core';
import { Feature, FeatureCollection, Polygon } from 'geojson';
import { environment } from 'src/environments/environment';
import { WfsService } from '../../services/wfs.service';
import * as L from 'leaflet';
import { CustomCompileService } from '../../services/custom-compile.service';
import { WellDetailPopupComponent } from '../well-detail-popup/well-detail-popup.component';
import { WellService } from '../../generated/api/well.service';
import { BoundingBoxDto } from '../../generated/model/bounding-box-dto';

declare let $: any;

@Component({
  selector: 'well-map',
  templateUrl: './well-map.component.html',
  styleUrls: ['./well-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WellMapComponent implements OnInit, AfterViewInit {
  @Input() public mapID: string = '';

  @Input() public visibleWellStyle: string = 'well';

  @Input() public visibleWellIDs: Array<number> = [];

  @Input() public selectedWellStyle: string = 'well_with_name';

  @Input() public selectedWellIDs: Array<number> = [];

  @Input() public visibleTractIDs: Array<number> = [];

  @Input() public onEachFeatureCallback?: (feature, layer) => void;

  @Input() public zoomMapToDefaultExtent: boolean = true;

  @Input() public disableDefaultClick: boolean = false;

  @Input() public displaywellsLayerOnLoad: boolean = true;

  @Input() public mapHeight: string = '300px';

  @Input() public defaultFitBoundsOptions?: L.FitBoundsOptions = null;

  @Input() public selectedWellLayerName: string =
    '<img src=\'./assets/main/map-legend-images/marker.png\' style=\'height:16px; margin-bottom:3px\'> Selected Wells';

  @Output() public afterSetControl: EventEmitter<L.Control.Layers> =
    new EventEmitter();

  @Output() public afterLoadMap: EventEmitter<L.LeafletEvent> =
    new EventEmitter();

  @Output() public onMapMoveEnd: EventEmitter<L.LeafletEvent> =
    new EventEmitter();

  public component: any;

  public map: L.Map;
  public featureLayer: any;
  public layerControl: L.Control.Layers;
  public tileLayers: { [key: string]: any } = {};
  public overlayLayers: { [key: string]: any } = {};
  public boundingBox: BoundingBoxDto;
  private selectedWellLayer: any;
  private defaultWellsWMSOptions: L.WMSOptions;

  constructor(
    private wfsService: WfsService,
    private wellService: WellService,
    private appRef: ApplicationRef,
    private compileService: CustomCompileService,
  ) {}

  public ngOnInit(): void {
    // Default bounding box
    this.boundingBox = new BoundingBoxDto();
    this.boundingBox.Left = -98.49706346987776;
    this.boundingBox.Bottom = 40.4303080924072;
    this.boundingBox.Right = -96.95153112621863;
    this.boundingBox.Top = 41.269115170389895;

    this.tileLayers = Object.assign(
      {},
      {
        Aerial: L.tileLayer(
          'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          {
            attribution: 'Aerial',
          },
        ),
        Street: L.tileLayer(
          'https://services.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
          {
            attribution: 'Aerial',
          },
        ),
        Terrain: L.tileLayer(
          'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
          {
            attribution: 'Terrain',
          },
        ),
      },
      this.tileLayers,
    );

    this.defaultWellsWMSOptions = {
      layers: 'Splash:Wells',
      transparent: true,
      format: 'image/png',
      tiled: true,
    } as L.WMSOptions;

    const wellsWMSOptions = Object.assign(
      { styles: this.visibleWellStyle },
      this.defaultWellsWMSOptions,
    );
    if (this.visibleWellIDs.length > 0) {
      wellsWMSOptions.cql_filter = this.createWellMapFilter(
        this.visibleWellIDs,
      );
      this.fitBoundsToVisibleWells(this.visibleWellIDs);
    }

    const tractsWMSOptions = {
      layers: 'Splash:Tracts',
      transparent: true,
      format: 'image/png',
      tiled: true,
      cql_filter: this.createTractMapFilter(this.visibleTractIDs),
    } as L.WMSOptions;

    this.overlayLayers = Object.assign(
      {
        '<img src=\'./assets/main/map-legend-images/tract_outline_only.png\' style=\'height:16px; margin-bottom:3px\'> Related Tracts':
                    L.tileLayer.wms(
                      environment.geoserverMapServiceUrl + '/wms?',
                      tractsWMSOptions,
                    ),
        '<img src=\'./assets/main/map-legend-images/well.png\' style=\'height:16px; margin-bottom:3px\'> Related Wells':
                    L.tileLayer.wms(
                      environment.geoserverMapServiceUrl + '/wms?',
                      wellsWMSOptions,
                    ),
      },
      this.overlayLayers,
    );

    this.compileService.configure(this.appRef);
  }

  public updateSelectedWellsOverlayLayer(wellIDs: Array<number>) {
    if (this.selectedWellLayer) {
      this.layerControl.removeLayer(this.selectedWellLayer);
      this.map.removeLayer(this.selectedWellLayer);
    }

    const wmsParameters = Object.assign(
      {
        styles: this.selectedWellStyle,
        cql_filter: this.createWellMapFilter(wellIDs),
      },
      this.defaultWellsWMSOptions,
    );
    this.selectedWellLayer = L.tileLayer.wms(
      environment.geoserverMapServiceUrl + '/wms?',
      wmsParameters,
    );
    this.layerControl.addOverlay(
      this.selectedWellLayer,
      this.selectedWellLayerName,
    );

    this.selectedWellLayer.addTo(this.map).bringToFront();
  }

  private fitBoundsToVisibleWells(wellIDs: Array<number>) {
    this.wellService
      .wellsBoundingBoxPut(wellIDs)
      .subscribe((boundingBox) => {
        this.boundingBox = boundingBox;
        this.map.fitBounds([
          [this.boundingBox.Bottom, this.boundingBox.Left],
          [this.boundingBox.Top, this.boundingBox.Right],
        ]);
      });
  }

  private fitBoundsToSelectedWells(wellIDs: Array<number>) {
    this.wellService
      .wellsBoundingBoxWithTractsPut(wellIDs)
      .subscribe((boundingBox) => {
        this.boundingBox = boundingBox;
        this.map.fitBounds([
          [this.boundingBox.Bottom, this.boundingBox.Left],
          [this.boundingBox.Top, this.boundingBox.Right],
        ]);
      });
  }

  private createWellMapFilter(wellIDs: Array<number>): any {
    return `WellID in (${wellIDs.join(',')})`;
  }

  private createTractMapFilter(tractIDs: Array<number>): any {
    return `TractID in (${tractIDs.join(',')})`;
  }

  public ngAfterViewInit(): void {
    const mapOptions: L.MapOptions = {
      // center: [46.8797, -110],
      // zoom: 6,
      minZoom: 9,
      maxZoom: 17,
      layers: [this.tileLayers.Aerial],
    } as L.MapOptions;
    this.map = L.map(this.mapID, mapOptions);

    this.map.on('load', (event: L.LeafletEvent) => {
      this.afterLoadMap.emit(event);
    });
    this.map.on('moveend', (event: L.LeafletEvent) => {
      this.onMapMoveEnd.emit(event);
    });
    this.map.fitBounds(
      [
        [this.boundingBox.Bottom, this.boundingBox.Left],
        [this.boundingBox.Top, this.boundingBox.Right],
      ],
      this.defaultFitBoundsOptions,
    );

    this.setControl();
    this.registerClickEvents();

    if (this.selectedWellIDs.length > 0) {
      this.updateSelectedWellsOverlayLayer(this.selectedWellIDs);
      this.fitBoundsToSelectedWells(this.selectedWellIDs);
    }

    if (!this.disableDefaultClick) {
      const wfsService = this.wfsService;
      const self = this;
      this.map.on('click', (event: L.LeafletMouseEvent): void => {
        wfsService
          .getWellByCoordinate(event.latlng.lng, event.latlng.lat)
          .subscribe((wellFeatureCollection: FeatureCollection) => {
            wellFeatureCollection.features.forEach(
              (feature: Feature) => {
                // Flip the coordinates
                switch (feature.geometry.type) {
                case 'Polygon':
                  const polygon: Polygon =
                                            feature.geometry as Polygon;
                  polygon.coordinates =
                                            polygon.coordinates.map(
                                              (coordinate) =>
                                                coordinate.map((point) => [
                                                  point[1],
                                                  point[0],
                                                ]),
                                            );
                  break;
                }
                new L.Popup({
                  minWidth: 250,
                })
                  .setLatLng(event.latlng)
                  .setContent(
                    this.compileService.compile(
                      WellDetailPopupComponent,
                      (c) => {
                        c.instance.feature = feature;
                      },
                    ),
                  )
                  .openOn(self.map);
              },
            );
          });
      });
    }
  }

  public setControl(): void {
    this.layerControl = new L.Control.Layers(
      this.tileLayers,
      this.overlayLayers,
      { collapsed: false },
    ).addTo(this.map);
    if (this.displaywellsLayerOnLoad) {
      this.overlayLayers[
        '<img src=\'./assets/main/map-legend-images/tract_outline_only.png\' style=\'height:16px; margin-bottom:3px\'> Related Tracts'
      ].addTo(this.map);
      this.overlayLayers[
        '<img src=\'./assets/main/map-legend-images/well.png\' style=\'height:16px; margin-bottom:3px\'> Related Wells'
      ].addTo(this.map);
    }
    this.afterSetControl.emit(this.layerControl);
  }

  public registerClickEvents(): void {
    const leafletControlLayersSelector = '.leaflet-control-layers';
    const closeButtonClass = 'leaflet-control-layers-close';

    const closem = L.DomUtil.create('a', closeButtonClass);
    closem.innerHTML = 'Close';
    L.DomEvent.on(closem, 'click', function (e) {
      $(leafletControlLayersSelector).removeClass(
        'leaflet-control-layers-expanded',
      );
    });

    $(leafletControlLayersSelector).append(closem);
  }
}
