/**
 * Splash.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { GenerateReportsDto } from '../model/generate-reports-dto';
import { ReportTemplateDto } from '../model/report-template-dto';

import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class ReportTemplateService {
  protected basePath = 'http://localhost';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    private apiService: ApiService,
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
     *
     *
     * @param generateReportsDto
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public reportTemplatesGeneratePut(
    generateReportsDto?: GenerateReportsDto,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<Blob>;
  public reportTemplatesGeneratePut(
    generateReportsDto?: GenerateReportsDto,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Blob>>;
  public reportTemplatesGeneratePut(
    generateReportsDto?: GenerateReportsDto,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Blob>>;
  public reportTemplatesGeneratePut(
    generateReportsDto?: GenerateReportsDto,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    const httpContentTypeSelected: string | undefined =
            this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient
      .put(
        `${this.basePath}/reportTemplates/generate`,
        generateReportsDto,
        {
          responseType: 'blob',
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress,
        },
      )
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }

  /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public reportTemplatesGet(
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<Array<ReportTemplateDto>>;
  public reportTemplatesGet(
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Array<ReportTemplateDto>>>;
  public reportTemplatesGet(
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Array<ReportTemplateDto>>>;
  public reportTemplatesGet(
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient
      .get<Array<ReportTemplateDto>>(`${this.basePath}/reportTemplates`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    })
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }

  /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public reportTemplatesModelsGet(
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<any>;
  public reportTemplatesModelsGet(
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<any>>;
  public reportTemplatesModelsGet(
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<any>>;
  public reportTemplatesModelsGet(
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient
      .get<any>(`${this.basePath}/reportTemplates/models`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    })
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }

  /**
     *
     *
     * @param displayName
     * @param reportTemplateModelID
     * @param fileResource
     * @param description
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public reportTemplatesPost(
    displayName: string,
    reportTemplateModelID: number,
    fileResource?: Blob,
    description?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<ReportTemplateDto>;
  public reportTemplatesPost(
    displayName: string,
    reportTemplateModelID: number,
    fileResource?: Blob,
    description?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<ReportTemplateDto>>;
  public reportTemplatesPost(
    displayName: string,
    reportTemplateModelID: number,
    fileResource?: Blob,
    description?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<ReportTemplateDto>>;
  public reportTemplatesPost(
    displayName: string,
    reportTemplateModelID: number,
    fileResource?: Blob,
    description?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (displayName === null || displayName === undefined) {
      throw new Error(
        'Required parameter displayName was null or undefined when calling reportTemplatesPost.',
      );
    }

    if (
      reportTemplateModelID === null ||
            reportTemplateModelID === undefined
    ) {
      throw new Error(
        'Required parameter reportTemplateModelID was null or undefined when calling reportTemplatesPost.',
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['multipart/form-data'];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: {
      append(param: string, value: any): void | HttpParams;
    };
    let useForm = false;
    const convertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({
        encoder: new CustomHttpUrlEncodingCodec(),
      });
    }

    if (fileResource !== undefined) {
      formParams =
                formParams.append('FileResource', <any>fileResource) ||
                formParams;
    }
    if (displayName !== undefined) {
      formParams =
                formParams.append('DisplayName', <any>displayName) ||
                formParams;
    }
    if (description !== undefined) {
      formParams =
                formParams.append('Description', <any>description) ||
                formParams;
    }
    if (reportTemplateModelID !== undefined) {
      formParams =
                formParams.append(
                  'ReportTemplateModelID',
                    <any>reportTemplateModelID,
                ) || formParams;
    }

    return this.httpClient
      .post<ReportTemplateDto>(
      `${this.basePath}/reportTemplates`,
      convertFormParamsToString ? formParams.toString() : formParams,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    )
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }

  /**
     *
     *
     * @param reportTemplateID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public reportTemplatesReportTemplateIDGet(
    reportTemplateID: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<ReportTemplateDto>;
  public reportTemplatesReportTemplateIDGet(
    reportTemplateID: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<ReportTemplateDto>>;
  public reportTemplatesReportTemplateIDGet(
    reportTemplateID: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<ReportTemplateDto>>;
  public reportTemplatesReportTemplateIDGet(
    reportTemplateID: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (reportTemplateID === null || reportTemplateID === undefined) {
      throw new Error(
        'Required parameter reportTemplateID was null or undefined when calling reportTemplatesReportTemplateIDGet.',
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient
      .get<ReportTemplateDto>(
      `${this.basePath}/reportTemplates/${encodeURIComponent(String(reportTemplateID))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    )
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }

  /**
     *
     *
     * @param reportTemplateID
     * @param displayName
     * @param reportTemplateModelID
     * @param fileResource
     * @param description
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public reportTemplatesReportTemplateIDPut(
    reportTemplateID: number,
    displayName: string,
    reportTemplateModelID: number,
    fileResource?: Blob,
    description?: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<ReportTemplateDto>;
  public reportTemplatesReportTemplateIDPut(
    reportTemplateID: number,
    displayName: string,
    reportTemplateModelID: number,
    fileResource?: Blob,
    description?: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<ReportTemplateDto>>;
  public reportTemplatesReportTemplateIDPut(
    reportTemplateID: number,
    displayName: string,
    reportTemplateModelID: number,
    fileResource?: Blob,
    description?: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<ReportTemplateDto>>;
  public reportTemplatesReportTemplateIDPut(
    reportTemplateID: number,
    displayName: string,
    reportTemplateModelID: number,
    fileResource?: Blob,
    description?: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (reportTemplateID === null || reportTemplateID === undefined) {
      throw new Error(
        'Required parameter reportTemplateID was null or undefined when calling reportTemplatesReportTemplateIDPut.',
      );
    }

    if (displayName === null || displayName === undefined) {
      throw new Error(
        'Required parameter displayName was null or undefined when calling reportTemplatesReportTemplateIDPut.',
      );
    }

    if (
      reportTemplateModelID === null ||
            reportTemplateModelID === undefined
    ) {
      throw new Error(
        'Required parameter reportTemplateModelID was null or undefined when calling reportTemplatesReportTemplateIDPut.',
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['multipart/form-data'];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: {
      append(param: string, value: any): void | HttpParams;
    };
    let useForm = false;
    const convertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({
        encoder: new CustomHttpUrlEncodingCodec(),
      });
    }

    if (fileResource !== undefined) {
      formParams =
                formParams.append('FileResource', <any>fileResource) ||
                formParams;
    }
    if (displayName !== undefined) {
      formParams =
                formParams.append('DisplayName', <any>displayName) ||
                formParams;
    }
    if (description !== undefined) {
      formParams =
                formParams.append('Description', <any>description) ||
                formParams;
    }
    if (reportTemplateModelID !== undefined) {
      formParams =
                formParams.append(
                  'ReportTemplateModelID',
                    <any>reportTemplateModelID,
                ) || formParams;
    }

    return this.httpClient
      .put<ReportTemplateDto>(
      `${this.basePath}/reportTemplates/${encodeURIComponent(String(reportTemplateID))}`,
      convertFormParamsToString ? formParams.toString() : formParams,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    )
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }
}
