import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Input,
  OnDestroy,
} from '@angular/core';
import { forkJoin, Observable, Subscription } from 'rxjs';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { PoolingAgreementWorkflowStep } from '../pooling-agreement-workflow-step.interface';
import { AllocationPlanDto } from 'src/app/shared/generated/model/allocation-plan-dto';
import { AllocationPlanService } from 'src/app/shared/generated/api/allocation-plan.service';
import { PoolService } from 'src/app/shared/generated/api/pool.service';
import { PoolWithAllocationPlanValuesDto } from 'src/app/shared/generated/model/pool-with-allocation-plan-values-dto';
import { PoolingAgreementDto } from 'src/app/shared/generated/model/pooling-agreement-dto';
import { PoolDto } from 'src/app/shared/generated/model/pool-dto';
import { PoolingAgreementAttachmentUpsertDto } from 'src/app/shared/generated/model/pooling-agreement-attachment-upsert-dto';
import { PoolingAgreementService } from 'src/app/shared/generated/api/pooling-agreement.service';

@Component({
  selector: 'splash-pooling-agreement-workflow-basics',
  templateUrl: './pooling-agreement-workflow-basics.component.html',
  styleUrls: ['./pooling-agreement-workflow-basics.component.scss'],
  //encapsulation: ViewEncapsulation.None
})
export class PoolingAgreementWorkflowBasicsComponent
implements OnInit, OnDestroy, PoolingAgreementWorkflowStep
{
  @Input()
    poolingAgreementObservable: Observable<PoolingAgreementDto>;
  poolingAgreementSubscription: Subscription;

  notesLength: number = 0;
  allocationPlans: AllocationPlanDto[];
  pools: PoolWithAllocationPlanValuesDto[];

  public upsertForm = new UntypedFormGroup({
    allocationPlan: new UntypedFormControl('', [Validators.required]),
    receivingPool: new UntypedFormControl('', [Validators.required]),
    sendingPool: new UntypedFormControl('', [Validators.required]),
    transferVolume: new UntypedFormControl('', [
      Validators.required,
      Validators.min(0),
    ]),
    notes: new UntypedFormControl(''),
    establishmentDate: new UntypedFormControl(''),
    attachments: new UntypedFormControl(),
  });

  public selectDropdownConfig = {
    search: true,
    height: '320px',
    placeholder: 'Select a pool from the list',
    displayKey: 'PoolName',
    searchOnKey: 'PoolName',
  };

  public allocationPeriod: AllocationPlanDto;
  public sendingPool: PoolDto;
  public receivingPool: PoolDto;
  public transferVolume: number;
  public notes: string;
  public establishmentDate: string;
  public attachments: PoolingAgreementAttachmentUpsertDto[] = [];
  public filesToUpload: { file: File; index: number }[] = [];
  public poolingAgreement: PoolingAgreementDto;
  public attachmentsToDelete: any;
  public displayFileTypeMessage: boolean = false;

  constructor(
    private allocationPlanService: AllocationPlanService,
    private poolService: PoolService,
    private cdr: ChangeDetectorRef,
    private poolingAgreementService: PoolingAgreementService,
  ) {}

  ngOnInit(): void {
    forkJoin({
      allocationPlans:
                this.allocationPlanService.allocationPlansActiveGet(),
      pools: this.poolService.poolsGet(),
    }).subscribe(({ allocationPlans, pools }) => {
      this.allocationPlans = allocationPlans;
      this.pools = pools;

      if (this.allocationPlans.length === 1) {
        this.upsertForm.patchValue({
          allocationPlan: this.allocationPlans[0],
        });
      }

      this.poolingAgreementSubscription =
                this.poolingAgreementObservable.subscribe((x) => {
                  this.poolingAgreement = x;
                  this.establishmentDate = x.EstablishmentDate?.toString();
                  if (x != null) {
                    this.patch();
                    this.poolingAgreementService
                      .poolingAgreementsPoolingAgreementIDAttachmentsGet(
                        x.PoolingAgreementID,
                      )
                      .subscribe((attachments) => {
                        this.attachments = attachments;
                        this.filesToUpload = [];
                        this.cdr.detectChanges();
                      });
                  }
                });
    });

    this.upsertForm.get('notes').valueChanges.subscribe((val) => {
      this.notesLength = val?.length ?? 0;
    });
  }

  ngOnDestroy(): void {
    this.poolingAgreementSubscription?.unsubscribe();
  }

  getDto(): PoolingAgreementDto {
    return new PoolingAgreementDto({
      PoolingAgreementID: this.poolingAgreement?.PoolingAgreementID ?? -1,
      AllocationPlan: this.upsertForm.value.allocationPlan,
      SendingPool: this.upsertForm.value.sendingPool,
      ReceivingPool: this.upsertForm.value.receivingPool,
      TransferVolume: this.upsertForm.value.transferVolume,
      Notes: this.upsertForm.value.notes,
      EstablishmentDate: this.upsertForm.value.establishmentDate,
    });
  }

  patch() {
    const poolingAgreement = this.poolingAgreement;
    const allocationPlan = this.allocationPlans.find(
      (x) =>
        x.AllocationPlanID ==
                poolingAgreement.AllocationPlan.AllocationPlanID,
    );
    const sendingPool = this.pools.find(
      (x) => x.PoolID == poolingAgreement.SendingPool.PoolID,
    );
    const receivingPool = this.pools.find(
      (x) => x.PoolID == poolingAgreement.ReceivingPool.PoolID,
    );

    this.upsertForm.patchValue({ allocationPlan: allocationPlan });
    this.upsertForm.patchValue({ sendingPool: sendingPool });
    this.upsertForm.patchValue({ receivingPool: receivingPool });
    this.upsertForm.patchValue({
      transferVolume: poolingAgreement.TransferVolume,
    });
    this.upsertForm.patchValue({ notes: poolingAgreement.Notes });

    if (poolingAgreement.EstablishmentDate) {
      const jsdate = new Date(poolingAgreement.EstablishmentDate);
      const mdpdate = {
        singleDate: {
          date: {
            year: jsdate.getFullYear(),
            month: jsdate.getMonth() + 1,
            day: jsdate.getDate(),
          },
          jsdate: jsdate,
        },
      };
      this.upsertForm.patchValue({ establishmentDate: mdpdate });
    }
    console.log(this.upsertForm);

    this.upsertForm.markAsPristine();
  }

  public isDirty(): boolean {
    return this.upsertForm.dirty;
  }

  public isValid(): boolean {
    return this.upsertForm.valid;
  }

  get f() {
    return this.upsertForm.controls;
  }

  public onFileInput(files: File[]): void {
    const file = files[0];
    const allowedExtensions = ['pdf', 'png', 'jpg', 'jpeg'];

    const extension = file.name.toLowerCase().split('.').pop();

    if (!allowedExtensions.includes(extension)) {
      this.displayFileTypeMessage = true;
      return;
    } else {
      this.displayFileTypeMessage = false;
    }
    const index = this.filesToUpload.length;
    this.filesToUpload.push({ file: files[0], index: index });
  }

  public removeStagedFile(file: { file: File; index: number }) {
    const index = this.filesToUpload.indexOf(file);
    if (index > -1) {
      this.filesToUpload.splice(index, 1);
    }
  }

  public deleteAttachment(attachment: PoolingAgreementAttachmentUpsertDto) {
    const index = this.attachments.indexOf(attachment);
    if (index > -1) {
      this.attachments.splice(index, 1);
    }
    this.cdr.detectChanges();
  }

  public getAttachments() {
    return this.attachments;
  }

  public getFilesToUpload() {
    return this.filesToUpload;
  }
}
