import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError as _throw } from 'rxjs';
import { BusyService } from '../busy/busy.service';
import { AlertService } from '../alert.service';
import { Alert } from 'src/app/shared/models/alert';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { AlertContext } from '../../models/enums/alert-context.enum';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private busyService: BusyService,
    private alertService: AlertService,
    private oauthService: OAuthService,
    private router: Router,
  ) {}

  handleError(
    error: any,
    supressErrorMessage = false,
    clearBusyGlobally = true,
  ): Observable<any> {
    if (clearBusyGlobally) {
      this.busyService.setBusy(false);
    }

    if (!supressErrorMessage) {
      if (error && error.status === 401) {
        this.alertService.pushAlert(
          new Alert('Access token expired...'),
        );
        this.oauthService.initCodeFlow();
      } else if (error && error.status === 403) {
        this.alertService.pushNotFoundUnauthorizedAlert();
        this.router.navigate(['/']);
      } else if (error.error && typeof error.error === 'string') {
        this.alertService.pushNotFoundUnauthorizedAlert();
        this.alertService.pushAlert(new Alert(error.error));
      } else if (error.error && error.status === 404) {
        // let the caller handle not found appropriate to whatever it was doing
      } else if (error.error && !(error.error instanceof ProgressEvent)) {
        //This is for handling errors that are generated by .NET
        //Eg. When model binding fails
        //https://docs.microsoft.com/en-us/aspnet/core/web-api/?view=aspnetcore-5.0#automatic-http-400-responses
        if (
          typeof error.error === 'object' &&
                    error.error !== null &&
                    error.error.hasOwnProperty('errors')
        ) {
          for (const key of Object.keys(error.error.errors)) {
            //We don't want to display any errors to the page if it's an error that we are defining to exist within a modal
            if (key != environment.modalErrorString) {
              const newLocal = new Alert(
                (error.error.errors[key] as string[])
                  .map((fe: string) => {
                    return key + ': ' + fe;
                  })
                  .join(','),
                AlertContext.Danger,
              );
              this.alertService.pushAlert(newLocal);
            }
          }
        } else {
          for (const key of Object.keys(error.error)) {
            //We don't want to display any errors to the page if it's an error that we are defining to exist within a modal
            if (key != environment.modalErrorString) {
              // FIXME: will break if errror.error[key] is not a string[]
              const newLocal = new Alert(
                (error.error[key] as string[])
                  .map((fe: string) => {
                    return key + ': ' + fe;
                  })
                  .join(','),
                AlertContext.Danger,
              );
              this.alertService.pushAlert(newLocal);
            }
          }
        }
      } else {
        this.alertService.pushAlert(
          new Alert(
            'Oops! Something went wrong and we couldn\'t complete the action...',
          ),
        );
      }
    }

    return _throw(error);
  }

  private requestCleaner(name: string, val: any) {
    if ((name || '').toString().indexOf('$') === 0) {
      return undefined; // remove from data
    } else {
      return val; // return as is
    }
  }
}
