import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ApplicationRef,
} from '@angular/core';
import { FeatureCollection } from 'geojson';
import { environment } from 'src/environments/environment';
import { WfsService } from '../../shared/services/wfs.service';
import * as L from 'leaflet';
import 'node_modules/leaflet.fullscreen/Control.FullScreen.js';
import { CustomCompileService } from '../../shared/services/custom-compile.service';
import { Observable } from 'rxjs';
import { WellDto } from 'src/app/shared/generated/model/well-dto';
import { TractService } from 'src/app/shared/generated/api/tract.service';
import { TractWithAccountDto } from 'src/app/shared/generated/model/tract-with-account-dto';
import { BoundingBoxDto } from 'src/app/shared/generated/model/bounding-box-dto';
import { AccountDto } from 'src/app/shared/generated/model/account-dto';
import { PoolDto } from 'src/app/shared/generated/model/pool-dto';
import { AccountBasicDto } from 'src/app/shared/generated/model/account-basic-dto';
import { PoolSimpleDto } from 'src/app/shared/generated/model/pool-simple-dto';

declare let $: any;

@Component({
  selector: 'splash-pool-detail-map',
  templateUrl: './pool-detail-map.component.html',
  styleUrls: ['./pool-detail-map.component.scss'],
})
export class PoolDetailMapComponent implements OnInit {
  @Input() public mapID: string = 'poolDetailMap';

  @Input() public visibleTractStyle: string = 'tract_purple_outline_only';
  agreementTractsStyle: string = 'tract_dotted_purple_outline_only';

  @Input() public visibleTractWithAccountDtos: TractWithAccountDto[] = [];

  @Input() public selectedTractStyle: string = 'tract_yellow';

  @Input() public visibleWells: WellDto[] = [];

  @Input() public selectedWellStyle: string = 'well_yellow';

  @Input() public onEachFeatureCallback?: (feature, layer) => void;

  @Input() public zoomMapToDefaultExtent: boolean = true;

  @Input() public mapHeight: string = '300px';

  @Input() public defaultFitBoundsOptions?: L.FitBoundsOptions = null;

  @Input() public agreementTractIDsObservable: Observable<number[]>;

  @Output() public afterSetControl: EventEmitter<L.Control.Layers> =
    new EventEmitter();

  @Output() public afterLoadMap: EventEmitter<L.LeafletEvent> =
    new EventEmitter();

  @Output() public onMapMoveEnd: EventEmitter<L.LeafletEvent> =
    new EventEmitter();

  public component: any;

  public map: L.Map;
  public featureLayer: any;
  public layerControl: L.Control.Layers;
  public tileLayers: { [key: string]: any } = {};
  public overlayLayers: { [key: string]: any } = {};
  public boundingBox: BoundingBoxDto;
  private defaultTractsWMSOptions: L.WMSOptions;
  public selectedListItem: string;
  public selectedListItemDetails: { [key: string]: any } = {};
  public PoolDetailObjectTypeEnum = PoolDetailObjectTypeEnum;
  public selectedObjectType: PoolDetailObjectTypeEnum;
  public selectedObjectLayer: L.Layer;
  public selectedDto: TractWithAccountAndWellDto;

  public visibleWellIDs: Array<number>;
  public visibleTractIDs: Array<number>;
  defaultWellsWMSOptions: any;
  public agreementTractsLayer: any;

  constructor(
    private wfsService: WfsService,
    private tractService: TractService,
    private appRef: ApplicationRef,
    private compileService: CustomCompileService,
  ) {}

  public ngOnInit(): void {
    // Default bounding box
    this.boundingBox = new BoundingBoxDto();
    this.boundingBox.Left = -98.49706346987776;
    this.boundingBox.Bottom = 40.4303080924072;
    this.boundingBox.Right = -96.95153112621863;
    this.boundingBox.Top = 41.269115170389895;

    this.tileLayers = Object.assign(
      {},
      {
        Aerial: L.tileLayer(
          'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          {
            attribution: 'Aerial',
          },
        ),
        Street: L.tileLayer(
          'https://services.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
          {
            attribution: 'Aerial',
          },
        ),
        Terrain: L.tileLayer(
          'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
          {
            attribution: 'Terrain',
          },
        ),
      },
      this.tileLayers,
    );

    this.defaultTractsWMSOptions = {
      layers: 'Splash:Tracts',
      transparent: true,
      format: 'image/png',
      tiled: true,
    } as L.WMSOptions;

    const tractsWMSOptions = Object.assign(
      { styles: this.visibleTractStyle },
      this.defaultTractsWMSOptions,
    );
    if (this.visibleTractWithAccountDtos.length > 0) {
      this.visibleTractIDs = this.visibleTractWithAccountDtos.map(
        (x) => x.TractID,
      );
      tractsWMSOptions.cql_filter = this.createTractMapFilter(
        this.visibleTractIDs,
      );
      this.fitBoundsByTractIDs(this.visibleTractIDs);
    }

    this.defaultWellsWMSOptions = {
      layers: 'Splash:Wells',
      transparent: true,
      format: 'image/png',
      tiled: true,
    } as L.WMSOptions;

    const wellsWMSOptions = Object.assign({}, this.defaultWellsWMSOptions);
    if (this.visibleWells) {
      this.visibleWellIDs = this.visibleWells.map((x) => x.WellID);
      wellsWMSOptions.cql_filter = this.createWellMapFilter(
        this.visibleWellIDs,
      );
    }

    this.overlayLayers = Object.assign(
      {
        '<img src=\'./assets/main/map-legend-images/tract_outline_only.png\' style=\'height:16px; margin-bottom:3px\'> Pool Tracts':
                    L.tileLayer.wms(
                      environment.geoserverMapServiceUrl + '/wms?',
                      tractsWMSOptions,
                    ),
        '<img src=\'./assets/main/map-legend-images/well.png\' style=\'height:16px; margin-bottom:3px\'> Pool Wells':
                    L.tileLayer.wms(
                      environment.geoserverMapServiceUrl + '/wms?',
                      wellsWMSOptions,
                    ),
      },
      this.overlayLayers,
    );

    this.compileService.configure(this.appRef);
  }

  private fitBoundsByTractIDs(tractIDs: Array<number>) {
    this.tractService
      .tractsBoundingBoxPut(tractIDs)
      .subscribe((boundingBox) => {
        this.boundingBox = boundingBox;
        this.map.fitBounds(
          [
            [this.boundingBox.Bottom, this.boundingBox.Left],
            [this.boundingBox.Top, this.boundingBox.Right],
          ],
          this.defaultFitBoundsOptions,
        );
      });
  }

  private createTractMapFilter(tractIDs: Array<number>): any {
    return `TractID in (${tractIDs.join(',')})`;
  }

  private createWellMapFilter(wellIDs: Array<number>): any {
    return `WellID in (${wellIDs.join(',')})`;
  }

  public ngAfterViewInit(): void {
    const mapOptions: L.MapOptions = {
      // center: [46.8797, -110],
      // zoom: 6,
      minZoom: 9,
      maxZoom: 17,
      layers: [this.tileLayers.Aerial],
      fullscreenControl: true,
    } as L.MapOptions;
    this.map = L.map(this.mapID, mapOptions);

    this.map.on('load', (event: L.LeafletEvent) => {
      this.afterLoadMap.emit(event);
    });
    this.map.on('moveend', (event: L.LeafletEvent) => {
      this.onMapMoveEnd.emit(event);
    });
    this.map.fitBounds(
      [
        [this.boundingBox.Bottom, this.boundingBox.Left],
        [this.boundingBox.Top, this.boundingBox.Right],
      ],
      this.defaultFitBoundsOptions,
    );

    this.setControl();
    this.registerClickEvents();

    this.agreementTractIDsObservable.subscribe((tractIDs) => {
      this.updateAgreementTractsLayer(tractIDs);
    });
  }

  public updateAgreementTractsLayer(tractIDs: number[]) {
    if (this.agreementTractsLayer != null) {
      this.layerControl.removeLayer(this.agreementTractsLayer);
      this.map.removeLayer(this.agreementTractsLayer);
      this.agreementTractsLayer = null;
    }
    const agreementTractsWmsOptions = Object.assign(
      { styles: this.agreementTractsStyle },
      this.defaultTractsWMSOptions,
    );
    agreementTractsWmsOptions.cql_filter =
            this.createTractMapFilter(tractIDs);
    this.agreementTractsLayer = L.tileLayer.wms(
      environment.geoserverMapServiceUrl + '/wms?',
      agreementTractsWmsOptions,
    );

    this.agreementTractsLayer.addTo(this.map);
    this.layerControl.addOverlay(
      this.agreementTractsLayer,
      '<img src=\'./assets/main/map-legend-images/tract_dotted_outline_only.png\' style=\'height:16px; margin-bottom:3px\'> Agreement Tracts',
    );
    const allVisibleTractIDs = this.visibleTractIDs.concat(tractIDs);
    this.fitBoundsByTractIDs(allVisibleTractIDs);
  }

  public setControl(): void {
    this.layerControl = new L.Control.Layers(
      this.tileLayers,
      this.overlayLayers,
      { collapsed: false },
    ).addTo(this.map);
    this.overlayLayers[
      '<img src=\'./assets/main/map-legend-images/tract_outline_only.png\' style=\'height:16px; margin-bottom:3px\'> Pool Tracts'
    ].addTo(this.map);
    this.overlayLayers[
      '<img src=\'./assets/main/map-legend-images/well.png\' style=\'height:16px; margin-bottom:3px\'> Pool Wells'
    ].addTo(this.map);
    this.afterSetControl.emit(this.layerControl);
  }

  public registerClickEvents(): void {
    const leafletControlLayersSelector = '.leaflet-control-layers';
    const closeButtonClass = 'leaflet-control-layers-close';

    const closem = L.DomUtil.create('a', closeButtonClass);
    closem.innerHTML = 'Close';
    L.DomEvent.on(closem, 'click', function () {
      $(leafletControlLayersSelector).removeClass(
        'leaflet-control-layers-expanded',
      );
    });

    $(leafletControlLayersSelector).append(closem);

    const wfsService = this.wfsService;
    this.map.on('click', (event: L.LeafletMouseEvent): void => {
      wfsService
        .getWellOrTractByCoordinate(event.latlng.lng, event.latlng.lat)
        .subscribe(
          (wellOrTractFeatureCollection: FeatureCollection) => {
            if (wellOrTractFeatureCollection.features) {
              const wellFeatures =
                                wellOrTractFeatureCollection.features
                                  .filter((x) =>
                                    x.id
                                      ?.toString()
                                      .includes(
                                        PoolDetailObjectTypeEnum.Well,
                                      ),
                                  )
                                  .filter((x) =>
                                    this.visibleWellIDs.includes(
                                      x.properties.WellID,
                                    ),
                                  );
              if (wellFeatures.length > 0) {
                this.getDtoInfoAndHighlightMap(
                  PoolDetailObjectTypeEnum.Well,
                  wellFeatures[0].properties.WellID,
                );
                return null;
              }

              const tractFeatures =
                                wellOrTractFeatureCollection.features
                                  .filter((x) =>
                                    x.id
                                      ?.toString()
                                      .includes(
                                        PoolDetailObjectTypeEnum.Tract,
                                      ),
                                  )
                                  .filter((x) =>
                                    this.visibleTractIDs.includes(
                                      x.properties.TractID,
                                    ),
                                  );

              if (tractFeatures.length > 0) {
                this.getDtoInfoAndHighlightMap(
                  PoolDetailObjectTypeEnum.Tract,
                  tractFeatures[0].properties.TractID,
                );
                return null;
              }
            }
          },
        );
    });
  }

  public getDtoInfoAndHighlightMap(
    type: PoolDetailObjectTypeEnum,
    objectID: number,
  ) {
    this.selectedObjectType = type;
    if (this.selectedListItem) {
      this.selectedListItem = null;
      this.selectedListItemDetails = {};
      this.map.removeLayer(this.selectedObjectLayer);
      this.selectedObjectLayer = null;
    }
    this.selectedListItem = type + objectID;
    let selectedNumber = null;
    let selectedAttributes = null;
    let wmsParameters = null;

    switch (type) {
    case PoolDetailObjectTypeEnum.Tract:
      this.selectedDto = this.visibleTractWithAccountDtos.filter(
        (x) => x.TractID == objectID,
      )[0];
      selectedNumber = this.selectedDto.TractNumber;
      selectedAttributes = [
        `<strong>Type:</strong> ${this.selectedDto.TractType}`,
        `<strong>APN:</strong> ${this.selectedDto.ParcelNumber}`,
        `<strong>Final Acres:</strong> ${this.selectedDto.FinalAcres}`,
      ];
      wmsParameters = Object.assign(
        {
          styles: this.selectedTractStyle,
          cql_filter: `TractID = ${objectID}`,
        },
        this.defaultTractsWMSOptions,
      );
      break;

    case PoolDetailObjectTypeEnum.Well:
      this.selectedDto = this.visibleWells.filter(
        (x) => x.WellID == objectID,
      )[0];
      selectedNumber = this.selectedDto.WellNumber;
      selectedAttributes = [
        `<strong>Primary Use:</strong> ${this.selectedDto.WellUse}`,
        `<strong>Reg #:</strong> ${this.selectedDto.RegistrationNumber}`,
      ];
      wmsParameters = Object.assign(
        {
          styles: this.selectedWellStyle,
          cql_filter: `WellID = ${objectID}`,
        },
        this.defaultWellsWMSOptions,
      );
      break;

    default:
    }

    if (this.selectedDto) {
      this.selectedObjectLayer = L.tileLayer.wms(
        environment.geoserverMapServiceUrl + '/wms?',
        wmsParameters,
      );
      this.selectedObjectLayer.addTo(this.map).bringToFront();
      this.selectedListItemDetails.title = `${type} ${selectedNumber}`;
      this.selectedListItemDetails.attributes = selectedAttributes;
      this.selectedListItemDetails.URL = `/${type.toLowerCase() + 's'}/${objectID}`;
      if (type != PoolDetailObjectTypeEnum.Well) {
        this.overlayLayers[
          '<img src=\'./assets/main/map-legend-images/well.png\' style=\'height:16px; margin-bottom:3px\'> Pool Wells'
        ].bringToFront();
      }
    }
  }
}

export enum PoolDetailObjectTypeEnum {
  Tract = 'Tract',
  Well = 'Well',
}

export class TractWithAccountAndWellDto{
  WellID?: number;
  BeehiveAssetID?: number;
  WellName?: string;
  RegistrationNumber?: string;
  ReportingOperator?: AccountDto;
  Quarter?: string;
  SubQuarter?: string;
  Section?: string;
  Township?: string;
  Range?: string;
  RangeDirection?: string;
  County?: string;
  Comments?: string;
  Pool?: PoolDto;
  IsInBeehive?: boolean;
  WellUse?: string;
  WellLifeCycle?: string;
  SecondaryWellUse?: string;
  WellNumber?: number;
  TractID?: number;
  TractNumber?: number;
  TractType?: string;
  TractCategory?: string;
  TractIrrigationMethod?: string;
  FinalAcres?: number;
  AssessorAcres?: number;
  ProvisionalAcres?: number;
  ReportedAcres?: number;
  TractPhase?: string;
  NRDAdjustment?: number;
  CertificationNotes?: string;
  Legal?: string;
  GWMA2FarmName?: string;
  ParcelNumber?: string;
  Landowner?: AccountBasicDto;
  PrimaryOperator?: AccountBasicDto;
  SecondaryOperator?: AccountBasicDto;
  TertiaryOperator?: AccountBasicDto;
  Rep?: AccountBasicDto;
  GWMA2?: AccountBasicDto;
  NumberOfWells?: number;
  GroundwaterTransferNumbers?: string;
  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}
