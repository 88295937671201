import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import * as _ from 'lodash';
import { AllocationPlanDto } from 'src/app/shared/generated/model/allocation-plan-dto';
import { WithdrawalSimpleDto } from 'src/app/shared/generated/model/withdrawal-simple-dto';
declare let vegaEmbed: any;

@Component({
  selector: 'splash-pool-water-use-and-allocation-chart',
  templateUrl: './pool-water-use-and-allocation-chart.component.html',
  styleUrls: ['./pool-water-use-and-allocation-chart.component.scss'],
})
export class PoolWaterUseAndAllocationChartComponent
implements OnInit, OnChanges
{
  public chartID: string = 'poolWaterUseAndAllocationChart';

  @Input() withdrawals: WithdrawalSimpleDto[];
  @Input() allocationPlanToDisplay: AllocationPlanDto;
  @Input() acreage: number;
  @Input() unitsShown: string = 'ac-in';

  vegaView: any;

  constructor(private cdr: ChangeDetectorRef) {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    this.buildChart();
  }

  ngOnInit(): void {
    this.buildChart();
  }

  buildChart() {
    const self = this;
    vegaEmbed(`#${this.chartID}`, this.chartSpec(), {
      actions: false,
      tooltip: {
        theme: 'custom',
      },
    }).then(function (res) {
      self.vegaView = res.view;
    });
  }

  public chartSpec() {
    return {
      $schema: 'https://vega.github.io/schema/vega-lite/v4.13.1.json',
      description: 'A simple bar chart with embedded data.',
      width: 'container',
      height: 'container',
      padding: {
        left: 30,
        top: 20,
        bottom: 0,
        right: 0,
      },
      data: {
        name: 'data',
        values: this.makeVegaData(),
      },
      layer: [
        {
          // this is super cool. instant combo-chart
          mark: {
            type: 'bar',
            tooltip: true,
            cornerRadiusEnd: 4,
            color: 'blue',
          },
          transform: [
            {
              calculate: `datum.WaterUse + ' ${this.unitsShown}'`,
              as: 'Usage',
            },
          ],
          encoding: {
            x: {
              field: 'Year',
              type: 'ordinal',
              axis: {
                title: false,
                labelFont: 'Helvetica',
                labelFontSize: 14,
                labelAngle: -75,
              },
              sort: null,
            },
            y: {
              field: 'WaterUse',
              type: 'quantitative',
              axis: {
                title: `Usage (${this.unitsShown})`,
                titleFont: 'Helvetica',
                labelFont: 'Helvetica',
                titleFontSize: 18,
                labelFontSize: 14,
                titlePadding: 20,
              },
            },
            tooltip: [
              { field: 'Year', type: 'ordinal' },
              { field: 'Usage', type: 'ordinal' },
            ],
          },
        },
        {
          // this is super cool. instant combo-chart
          mark: {
            type: 'line',
            tooltip: true,
            color: 'red',
            point: { shape: 'circle', size: 100, color: 'red' },
          },
          transform: [
            {
              calculate: `datum.AllocationAmount + ' ${this.unitsShown}'`,
              as: 'Allocation',
            },
          ],
          encoding: {
            x: {
              field: 'Year',
              type: 'ordinal',
              axis: {
                title: false,
                labelFont: 'Helvetica',
                labelFontSize: 14,
                labelAngle: -75,
              },
              sort: null,
            },
            y: {
              field: 'AllocationAmount',
              type: 'quantitative',
              axis: {
                title: `Usage (${this.unitsShown})`,
                titleFont: 'Helvetica',
                labelFont: 'Helvetica',
                titleFontSize: 18,
                labelFontSize: 14,
                titlePadding: 20,
              },
            },
            tooltip: [{ field: 'Allocation', type: 'nominal' }],
          },
        },
      ],
    };
  }

  makeVegaData(): ChartEntry[] {
    const grouped = _.groupBy(
      this.filteredWithdrawals(),
      (x) => x.WithdrawalYear,
    );

    const sortedYearlyUsages = Object.entries(grouped)
      .map(([key, value]) => {
        const entry: ChartEntry = {
          Year: Number(key),
          WaterUse: value
            .map((x) =>
              this.getResultInUnitsShown(x.TotalAcreInches),
            )
            .reduce((x, y) => x + y, 0),
        };
        if (this.isRealAllocationPlan()) {
          entry.AllocationAmount = this.getAllocationVolume();
        }
        return entry;
      })
      .sort((x, y) => x.Year - y.Year);

    let cumulus = 0;
    for (let i = 0; i < sortedYearlyUsages.length; i++) {
      sortedYearlyUsages[i].WaterUse += cumulus;
      cumulus = sortedYearlyUsages[i].WaterUse;
    }

    return sortedYearlyUsages;
  }

  getAllocationVolume(): number {
    if (this.unitsShown === 'ac-in') {
      return this.allocationPlanToDisplay.AllocationVolume * this.acreage;
    } else {
      return this.allocationPlanToDisplay.AllocationVolume;
    }
  }

  isRealAllocationPlan() {
    return this.allocationPlanToDisplay.AllocationPlanID != -1;
  }

  filteredWithdrawals(): WithdrawalSimpleDto[] {
    return this.augmentedWithdrawals().filter(
      (x) =>
        x.WithdrawalYear >= this.allocationPlanToDisplay.StartYear &&
                x.WithdrawalYear <
                    this.allocationPlanToDisplay.StartYear +
                        this.allocationPlanToDisplay.Duration,
    );
  }

  augmentedWithdrawals(): WithdrawalSimpleDto[] {
    const augmented = [...this.withdrawals];
    // add dummies to the list so that all years show even if there's nothing to show for a year.
    if (this.isRealAllocationPlan()) {
      for (let i = 0; i < this.allocationPlanToDisplay.Duration; i++) {
        augmented.push({
          WithdrawalYear: this.allocationPlanToDisplay.StartYear + i,
          TotalAcreInches: 0,
          WellID: -1,
          WellName: '',
        });
      }
    }
    return augmented;
  }

  getResultInUnitsShown(result) {
    if (this.unitsShown === 'ac-in / ac') {
      return result / this.acreage;
    } else {
      return result;
    }
  }
}

class ChartEntry {
  Year: number;
  WaterUse: number;
  AllocationAmount?: number;
}
