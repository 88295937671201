<nav aria-label="breadcrumb" *ngIf="pool">
    <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">
            <a routerLink="/pools">Pools</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
            Pool: {{ pool.PoolName }}
        </li>
    </ol>
</nav>
<div class="container mt-sm-4" *ngIf="pool">
    <app-alert-display></app-alert-display>
    <div class="row">
        <div class="col">
            <h2 class="d-inline-block">
                {{ pool.PoolType.PoolTypeDisplayName }} Pool:
                {{ pool.PoolName }}
            </h2>
        </div>
        <div class="col ms-auto col-auto float-end">
            <splash-allocation-plan-select
                [allocationPlans]="allocationPlans"
                [(selectedAllocationPlan)]="allocationPlanToDisplay"
                (selectedAllocationPlanChange)="updateAnnualData()"
            ></splash-allocation-plan-select>
        </div>
    </div>
    <div class="row mb-2 me-1">
        <div class="col float-end">
            <div class="btn-group btn-group-toggle float-end">
                <label
                    class="btn btn-toggle btn-sm btn-rio"
                    [ngClass]="unitsShown === 'ac-in' ? 'active' : ''"
                    style="width: 65px"
                >
                    <input
                        type="radio"
                        class="btn-check"
                        name="units"
                        id="ac-in"
                        (click)="toggleUnitsShown('ac-in')"
                    />
                    ac-in
                </label>
                <label
                    class="btn btn-toggle btn-sm btn-rio"
                    [ngClass]="unitsShown === 'ac-in / ac' ? 'active' : ''"
                >
                    <input
                        type="radio"
                        class="btn-check"
                        name="units"
                        id="ac-in/ac"
                        (click)="toggleUnitsShown('ac-in / ac')"
                    />
                    ac-in/ac
                </label>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-header">
                    <span class="align-middle">Water Budget Overview</span>
                </div>
                <div class="card-body">
                    <splash-water-budget-overview
                        [withdrawals]="withdrawals"
                        [unitsShown]="unitsShown"
                        [tracts]="tracts"
                        [wells]="wells"
                        [allocationPlanToDisplay]="allocationPlanToDisplay"
                        [poolingAgreements]="poolingAgreements"
                        [pool]="pool"
                    >
                    </splash-water-budget-overview>
                </div>
            </div>
        </div>
        <div class="col" *ngIf="withdrawals">
            <div class="card">
                <div class="card-header">Water Usage by Year</div>
                <div class="card-body">
                    <splash-pool-water-use-and-allocation-chart
                        [withdrawals]="withdrawals"
                        [allocationPlanToDisplay]="allocationPlanToDisplay"
                        [acreage]="getTotalTractAcreage()"
                        [unitsShown]="unitsShown"
                    >
                    </splash-pool-water-use-and-allocation-chart>
                    <p class="small">
                        Bars represent cumulative usage through each year of the
                        selected period
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4" *ngIf="withdrawals">
        <div class="col">
            <div class="card">
                <div class="card-header">
                    <div class="btn-group btn-group-toggle float-end">
                        <label
                            class="btn btn-toggle btn-sm"
                            [ngClass]="
                                waterUsageByWellViewType === 'chart'
                                    ? 'active'
                                    : ''
                            "
                            placement="top"
                            ngbTooltip="Chart"
                        >
                            <input
                                type="radio"
                                class="btn-check"
                                name="units"
                                id="chart-icon"
                                (click)="toggleWaterUsageByWellView()"
                            /><i class="fas fa-chart-bar"></i>
                        </label>
                        <label
                            class="btn btn-toggle btn-sm"
                            [ngClass]="
                                waterUsageByWellViewType === 'table'
                                    ? 'active'
                                    : ''
                            "
                            placement="top"
                            ngbTooltip="Table"
                        >
                            <input
                                type="radio"
                                class="btn-check"
                                name="units"
                                id="table-icon"
                                (click)="toggleWaterUsageByWellView()"
                            /><i class="fas fa-table"></i>
                        </label>
                    </div>
                    Water Usage by Well
                </div>
                <div class="card-body">
                    <div
                        *ngIf="
                            waterUsageByWellViewType === 'chart';
                            then chartBlock;
                            else tableBlock
                        "
                    ></div>
                    <ng-template #chartBlock>
                        <splash-water-use-by-well-chart
                            [withdrawals]="withdrawals"
                            [allocationPlanToDisplay]="allocationPlanToDisplay"
                            [acreage]="getTotalTractAcreage()"
                            [unitsShown]="unitsShown"
                        ></splash-water-use-by-well-chart>
                    </ng-template>
                    <ng-template #tableBlock>
                        <table class="table table-responsive table-condensed">
                            <thead>
                                <tr>
                                    <th>Well</th>
                                    <th
                                        class="text-end"
                                        *ngFor="
                                            let year of getAvailableYears();
                                            let i = index
                                        "
                                    >
                                        {{ year }}
                                    </th>
                                    <th *ngIf="unitsShown !== 'ac-in / ac'">
                                        Total
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let well of wells">
                                    <td>{{ well.WellName }}</td>
                                    <td
                                        class="text-end"
                                        *ngFor="let year of getAvailableYears()"
                                    >
                                        {{
                                            getWaterUsageForWellAndYear(
                                                well,
                                                year
                                            ) | number: "1.1-3"
                                        }}
                                    </td>
                                    <td *ngIf="unitsShown !== 'ac-in / ac'">
                                        {{
                                            getTotalWaterUsageForWell(well)
                                                | number: "1.1-3"
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <th><strong>Total</strong></th>
                                <th
                                    class="text-end"
                                    *ngFor="let year of getAvailableYears()"
                                >
                                    {{
                                        getTotalWaterUsageForYear(year)
                                            | number: "1.1-3"
                                    }}
                                </th>
                                <th
                                    class="text-end"
                                    *ngIf="unitsShown !== 'ac-in / ac'"
                                >
                                    {{
                                        getWaterUsageToDate() | number: "1.1-3"
                                    }}
                                </th>
                            </tfoot>
                        </table>
                        <p class="small">
                            Measurements are in {{ unitsShownText }}
                        </p>
                        <p class="small" *ngIf="unitsShown === 'ac-in / ac'">
                            Calculated by dividing Reported Withdrawals by the
                            Total Acres of the Pool
                        </p>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-12">
            <div class="card">
                <div class="card-header">Pooling Agreement Activity</div>
                <div class="card-body">
                    <table class="table table-striped table-condensed w-100">
                        <thead>
                            <tr>
                                <th scope="col">Agreement</th>
                                <th scope="col">Establishment Date</th>
                                <th scope="col">Allocation Period</th>
                                <th scope="col">Volume Sent</th>
                                <th scope="col">Volume Received</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="
                                    let poolingAgreement of getFilteredPoolingAgreements()
                                "
                            >
                                <td>
                                    <a
                                        routerLink="/pooling-agreements/{{
                                            poolingAgreement.PoolingAgreementNumber
                                        }}"
                                    >
                                        {{
                                            poolingAgreement.PoolingAgreementNumber
                                        }}
                                    </a>
                                </td>
                                <td>
                                    {{
                                        poolingAgreement.EstablishmentDate
                                            | date: "mediumDate"
                                    }}
                                </td>
                                <td>
                                    {{
                                        poolingAgreement.AllocationPlanDisplayName
                                    }}
                                </td>
                                <td>
                                    <ng-container
                                        *ngIf="
                                            poolingAgreement.SendingPoolID ===
                                            pool.PoolID
                                        "
                                        >{{
                                            poolingAgreement.TransferVolume
                                        }}
                                        ac-in</ng-container
                                    >
                                    <ng-container
                                        *ngIf="
                                            poolingAgreement.SendingPoolID !==
                                            pool.PoolID
                                        "
                                        >0 ac-in</ng-container
                                    >
                                </td>
                                <td>
                                    <ng-container
                                        *ngIf="
                                            poolingAgreement.ReceivingPoolID ===
                                            pool.PoolID
                                        "
                                        >{{
                                            poolingAgreement.TransferVolume
                                        }}
                                        ac-in</ng-container
                                    >
                                    <ng-container
                                        *ngIf="
                                            poolingAgreement.ReceivingPoolID !==
                                            pool.PoolID
                                        "
                                        >0 ac-in</ng-container
                                    >
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-12">
            <div class="card">
                <div class="card-header">Tracts and Wells</div>
                <div class="card-body">
                    <splash-pool-detail-map
                        mapHeight="500px"
                        [visibleTractWithAccountDtos]="getVisibleTracts()"
                        [visibleWells]="getVisibleWells()"
                        [agreementTractIDsObservable]="
                            agreementTractsSubject.asObservable()
                        "
                    ></splash-pool-detail-map>
                </div>
            </div>
        </div>
    </div>
</div>
