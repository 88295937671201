import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { PoolingAgreementWorkflowStep } from '../pooling-agreement-workflow-step.interface';
import { AllocationPlanDto } from 'src/app/shared/generated/model/allocation-plan-dto';
import { PoolService } from 'src/app/shared/generated/api/pool.service';
import { PoolingAgreementDto } from 'src/app/shared/generated/model/pooling-agreement-dto';
import { PoolDto } from 'src/app/shared/generated/model/pool-dto';
import { PoolingAgreementTractUpsertDto } from 'src/app/shared/generated/model/pooling-agreement-tract-upsert-dto';
import { PoolingAgreementTractService } from 'src/app/shared/generated/api/pooling-agreement-tract.service';
import { PoolUsageSummaryDto } from 'src/app/shared/generated/model/pool-usage-summary-dto';
import { PoolingAgreementTractTypeEnum } from 'src/app/shared/generated/model/pooling-agreement-tract-type-enum';
import { PoolingAgreementTractDetailedDto } from 'src/app/shared/generated/model/pooling-agreement-tract-detailed-dto';
import { TractWithAccountDto } from 'src/app/shared/generated/model/tract-with-account-dto';
import { AccountDto } from 'src/app/shared/generated/model/account-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { PoolWithAllocationPlanValuesDto } from 'src/app/shared/generated/model/pool-with-allocation-plan-values-dto';

@Component({
  selector: 'splash-pooling-agreement-workflow-review-and-publish',
  templateUrl:
        './pooling-agreement-workflow-review-and-publish.component.html',
  styleUrls: [
    './pooling-agreement-workflow-review-and-publish.component.scss',
  ],
})
export class PoolingAgreementWorkflowReviewAndPublishComponent
implements OnInit, OnDestroy, PoolingAgreementWorkflowStep
{
  @Input()
    poolingAgreementObservable: Observable<PoolingAgreementDto>;
  private poolingAgreementSubscription: Subscription;

  private currentUser: UserDto;

  public pools: PoolWithAllocationPlanValuesDto[];
  public poolingAgreement: PoolingAgreementDto;
  public poolBalanceAndAgreementSummaries: PoolBalanceAndAgreementSummary[] =
    new Array<PoolBalanceAndAgreementSummary>();
  public tractInfoAndAgreementSummaries: TractInfoAndAgreementSummary[] =
    new Array<TractInfoAndAgreementSummary>();

  public sendingPool: PoolDto;
  public receivingPool: PoolDto;
  public sendingPoolTracts: TractWithAccountDto[];
  public receivingPoolTracts: TractWithAccountDto[];
  public allocationPlan: AllocationPlanDto;
  public sendingPoolAgreementTracts: PoolingAgreementTractUpsertDto[];
  public receivingPoolAgreementTracts: PoolingAgreementTractUpsertDto[];
  public closeResult: string;
  public isPerformingAction: any;

  constructor(
    private poolService: PoolService,
    private cdr: ChangeDetectorRef,
    private poolingAgreementTractService: PoolingAgreementTractService,
  ) {}

  ngOnInit(): void {
    this.poolingAgreementSubscription =
            this.poolingAgreementObservable.subscribe((x) => {
              this.poolingAgreement = x;

              this.allocationPlan = x.AllocationPlan;
              this.sendingPool = x.SendingPool;
              this.receivingPool = x.ReceivingPool;

              forkJoin({
                sendingPoolSummary:
                        this.poolService.poolsPoolIDUsageSummaryAllocationPlanIDGet(
                          this.sendingPool.PoolID,
                          this.allocationPlan.AllocationPlanID,
                        ),
                receivingPoolSummary:
                        this.poolService.poolsPoolIDUsageSummaryAllocationPlanIDGet(
                          this.receivingPool.PoolID,
                          this.allocationPlan.AllocationPlanID,
                        ),
                sendingPoolTractsDetailed:
                        this.poolingAgreementTractService.poolingAgreementsPoolingAgreementIDTractTypesPoolingAgreementTractTypeTractsDetailedGet(
                          x.PoolingAgreementID,
                          PoolingAgreementTractTypeEnum.Sending,
                        ),
                receivingPoolTractsDetailed:
                        this.poolingAgreementTractService.poolingAgreementsPoolingAgreementIDTractTypesPoolingAgreementTractTypeTractsDetailedGet(
                          x.PoolingAgreementID,
                          PoolingAgreementTractTypeEnum.Receiving,
                        ),
              }).subscribe(
                ({
                  sendingPoolSummary,
                  receivingPoolSummary,
                  sendingPoolTractsDetailed,
                  receivingPoolTractsDetailed,
                }) => {
                  this.poolBalanceAndAgreementSummaries.push(
                    this.getBalanceAndAgreementSummaryForPool(
                      this.sendingPool,
                      sendingPoolSummary,
                    ),
                  );
                  this.poolBalanceAndAgreementSummaries.push(
                    this.getBalanceAndAgreementSummaryForPool(
                      this.receivingPool,
                      receivingPoolSummary,
                    ),
                  );

                  this.tractInfoAndAgreementSummaries =
                            this.tractInfoAndAgreementSummaries.concat(
                              this.getTractInfoAndAgreementSummariesForPool(
                                sendingPoolTractsDetailed,
                              ),
                            );
                  this.tractInfoAndAgreementSummaries =
                            this.tractInfoAndAgreementSummaries.concat(
                              this.getTractInfoAndAgreementSummariesForPool(
                                receivingPoolTractsDetailed,
                              ),
                            );
                },
              );
            });
  }

  ngOnDestroy(): void {
    this.poolingAgreementSubscription?.unsubscribe();
  }

  getBalanceAndAgreementSummaryForPool(
    pool: PoolDto,
    poolSummary: PoolUsageSummaryDto,
  ): PoolBalanceAndAgreementSummary {
    const totalAvailableWater =
            poolSummary.PoolAcres * this.allocationPlan.AllocationVolume;
    const remainingAvailableWater =
            totalAvailableWater - poolSummary.TotalUsageForAllocationPlan;
    const updatedTotalAvailableWater =
            remainingAvailableWater +
            (pool.PoolID == this.sendingPool.PoolID
              ? this.poolingAgreement.TransferVolume * -1
              : this.poolingAgreement.TransferVolume);

    return new PoolBalanceAndAgreementSummary({
      PoolID: pool.PoolID,
      PoolName: pool.PoolName,
      PoolAcres: poolSummary.PoolAcres,
      TotalAvailableWater: totalAvailableWater,
      TotalUsage: poolSummary.TotalUsageForAllocationPlan,
      RemainingAvailableWater: remainingAvailableWater,
      WaterSent:
                pool.PoolID == this.sendingPool.PoolID
                  ? this.poolingAgreement.TransferVolume
                  : null,
      WaterReceived:
                pool.PoolID == this.receivingPool.PoolID
                  ? this.poolingAgreement.TransferVolume
                  : null,
      UpdatedTotalAvailableWater: updatedTotalAvailableWater,
    });
  }

  getTractInfoAndAgreementSummariesForPool(
    poolingAgreementTractsDetailed: PoolingAgreementTractDetailedDto[],
  ): TractInfoAndAgreementSummary[] {
    return poolingAgreementTractsDetailed.map((x) => {
      const tractInfo = x.TractWithAccountDto;
      const poolID = tractInfo.Pool.PoolID;
      const tractAvailableWater =
                this.getTractRemainingWaterForAllocationPeriod(tractInfo);
      const waterSent =
                poolID == this.sendingPool.PoolID && x.TransferVolume != null
                  ? x.TransferVolume
                  : null;
      const waterReceived =
                poolID == this.receivingPool.PoolID && x.TransferVolume != null
                  ? x.TransferVolume
                  : null;

      return new TractInfoAndAgreementSummary({
        PoolID: poolID,
        Tract: tractInfo,
        TractOwner: tractInfo.Landowner,
        TractOperator: tractInfo.PrimaryOperator,
        TotalAvailableWater:
                    tractAvailableWater +
                    x.AllocationPlanOverallTractBalanceFromPoolingAgreements,
        WaterSent: waterSent,
        WaterReceived: waterReceived,
        UpdatedTotalAvailableWater:
                    tractAvailableWater +
                    x.AllocationPlanOverallTractBalanceFromPoolingAgreements -
                    waterSent +
                    waterReceived,
      });
    });
  }

  public getTractAllocationForAllocationPeriod(
    tract: TractWithAccountDto,
  ): number {
    return (
      (tract.FinalAcres ?? 0) *
            this.poolingAgreement.AllocationPlan.AllocationVolume
    );
  }

  public getTractRemainingWaterForAllocationPeriod(
    tract: TractWithAccountDto,
  ): number {
    const poolUsageSummary = this.poolBalanceAndAgreementSummaries.filter(
      (x) => x.PoolID == tract.Pool.PoolID,
    )[0];
    const weight = this.getTractWeight(tract, poolUsageSummary);
    const tractUsage = poolUsageSummary.TotalUsage * weight;
    return this.getTractAllocationForAllocationPeriod(tract) - tractUsage;
  }

  public getTractWeight(
    tract: TractWithAccountDto,
    poolUsageSummary: PoolBalanceAndAgreementSummary,
  ): number {
    return tract.FinalAcres / poolUsageSummary.PoolAcres;
  }

  getTractSummariesForPoolID(poolID: number): TractInfoAndAgreementSummary[] {
    return this.tractInfoAndAgreementSummaries.filter(
      (x) => x.PoolID == poolID,
    );
  }

  //Data can't be changed in this view
  isDirty(): boolean {
    return false;
  }

  //Data can't be changed in this view
  isValid(): boolean {
    return true;
  }
}

export class PoolBalanceAndAgreementSummary {
  PoolID: number;
  PoolName: number;
  PoolAcres: number;
  TotalAvailableWater: number;
  TotalUsage: number;
  RemainingAvailableWater: number;
  WaterSent: number;
  WaterReceived: number;
  UpdatedTotalAvailableWater: number;

  public constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export class TractInfoAndAgreementSummary {
  PoolID: number;
  Tract: TractWithAccountDto;
  TractOwner: AccountDto;
  TractOperator: AccountDto;
  TotalAvailableWater: number;
  WaterSent: number;
  WaterReceived: number;
  UpdatedTotalAvailableWater: number;

  public constructor(obj: any) {
    Object.assign(this, obj);
  }
}
