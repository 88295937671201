import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  AfterContentChecked,
  OnDestroy,
} from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PoolingAgreementWorkflowBasicsComponent } from '../pooling-agreement-workflow-basics/pooling-agreement-workflow-basics.component';
import { PoolingAgreementWorkflowStepEnum } from './pooling-agreement-workflow-step.enum';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, forkJoin, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PoolingAgreementWorkflowStep } from '../pooling-agreement-workflow-step.interface';
import { PoolingAgreementWorkflowTractsComponent } from '../pooling-agreement-workflow-tracts/pooling-agreement-workflow-tracts.component';
import { PoolingAgreementWorkflowReviewAndPublishComponent } from '../pooling-agreement-workflow-review-and-publish/pooling-agreement-workflow-review-and-publish.component';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { PoolingAgreementDto } from 'src/app/shared/generated/model/pooling-agreement-dto';
import { PoolingAgreementService } from 'src/app/shared/generated/api/pooling-agreement.service';
import { PoolingAgreementAttachmentUpsertDto } from 'src/app/shared/generated/model/pooling-agreement-attachment-upsert-dto';
import { PoolingAgreementTractTypeEnum } from 'src/app/shared/generated/model/pooling-agreement-tract-type-enum';
import { PoolingAgreementTractService } from 'src/app/shared/generated/api/pooling-agreement-tract.service';
import { FileResourceService } from 'src/app/shared/generated/api/file-resource.service';
import { PoolingAgreementStatusEnum } from 'src/app/shared/generated/enum/pooling-agreement-status-enum';

@Component({
  selector: 'splash-pooling-agreement-workflow',
  templateUrl: './pooling-agreement-workflow.component.html',
  styleUrls: ['./pooling-agreement-workflow.component.scss'],
})
export class PoolingAgreementWorkflowComponent
implements OnInit, AfterContentChecked, OnDestroy
{
  @ViewChild('changeStepModal') changeStepModal: any;
  @ViewChild('publishModal') publishModal: any;
  @ViewChild('cancelModal') cancelModal: any;
  @ViewChild('basicsStep')
    basicsStep: PoolingAgreementWorkflowBasicsComponent;
  @ViewChild('sendingDetailsStep')
    sendingDetailsStep: PoolingAgreementWorkflowTractsComponent;
  @ViewChild('receivingDetailsStep')
    receivingDetailsStep: PoolingAgreementWorkflowTractsComponent;
  @ViewChild('reviewAndPublishStep')
    reviewAndPublishStep: PoolingAgreementWorkflowReviewAndPublishComponent;

  public currentUser: UserDto;
  public poolingAgreement: PoolingAgreementDto;
  public modalReference: NgbModalRef;
  public closeResult: string;

  public PoolingAgreementWorkflowStepEnum = PoolingAgreementWorkflowStepEnum;
  public PoolingAgreementTractTypeEnum = PoolingAgreementTractTypeEnum;
  public activeWorkflowStep: PoolingAgreementWorkflowStepEnum =
    PoolingAgreementWorkflowStepEnum.BasicInformation;
  public isPerformingAction: boolean = false;

  public poolingAgreementSubject: Subject<PoolingAgreementDto> =
    new BehaviorSubject<PoolingAgreementDto>(null);
  public isNew: boolean;
  public targetStep: PoolingAgreementWorkflowStepEnum;

  constructor(
    private authenticationService: AuthenticationService,
    private poolingAgreementService: PoolingAgreementService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private fileResourceService: FileResourceService,
    private poolingAgreementTractService: PoolingAgreementTractService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const poolingAgreementNumber =
            this.activatedRoute.snapshot.paramMap.get('poolingAgreementNumber');
    this.authenticationService.getCurrentUser().subscribe((currentUser) => {
      this.currentUser = currentUser;

      if (poolingAgreementNumber) {
        this.poolingAgreementService
          .poolingAgreementsPoolingAgreementNumberGet(
            poolingAgreementNumber,
          )
          .subscribe((poolingAgreement) => {
            this.poolingAgreement = poolingAgreement;
            this.cdr.detectChanges();
            this.poolingAgreementSubject.next(poolingAgreement);
          });
      } else {
        this.isNew = true;
      }
    });
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.cdr.detach();
  }

  public getActiveStepComponent(): PoolingAgreementWorkflowStep {
    switch (this.activeWorkflowStep) {
    case PoolingAgreementWorkflowStepEnum.BasicInformation: {
      return this.basicsStep;
    }
    case PoolingAgreementWorkflowStepEnum.SendingDetails: {
      return this.sendingDetailsStep;
    }
    case PoolingAgreementWorkflowStepEnum.ReceivingDetails: {
      return this.receivingDetailsStep;
    }
    case PoolingAgreementWorkflowStepEnum.ReviewAndPublish: {
      return this.reviewAndPublishStep;
    }
    default: {
      throw new Error('Cannot find desired workflow step');
    }
    }
  }

  public changeStep(targetStep: PoolingAgreementWorkflowStepEnum) {
    const activeStepComponent = this.getActiveStepComponent();

    if (activeStepComponent?.isDirty()) {
      this.targetStep = targetStep;
      this.launchModal(this.changeStepModal, 'changeStepModal');
    } else {
      this.activeWorkflowStep = targetStep;
    }
  }

  public advanceToStep() {
    this.modalReference.close();
    this.activeWorkflowStep = this.targetStep;
    this.targetStep = null;
  }

  public launchModal(modalContent: any, modalAriaLabel: string) {
    this.modalReference = this.modalService.open(modalContent, {
      windowClass: 'confirm-change-step-modal',
      ariaLabelledBy: modalAriaLabel,
      beforeDismiss: () => this.isPerformingAction,
      backdrop: 'static',
      keyboard: false,
    });
    this.modalReference.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed`;
      },
    );
  }

  public openPublishModal() {
    this.launchModal(this.publishModal, 'confirmReviewAndPublish');
  }

  public openCancelModal() {
    this.launchModal(this.cancelModal, 'cancelReviewAndPublish');
  }

  public currentUserIsAdmin(): boolean {
    return this.authenticationService.isCurrentUserAnAdministrator();
  }

  public areSaveButtonsDisabled(): boolean {
    return !this.getActiveStepComponent()?.isValid();
  }

  public onSubmit(proceed: boolean) {
    this.alertService.clearAlerts();

    switch (this.activeWorkflowStep) {
    case PoolingAgreementWorkflowStepEnum.BasicInformation: {
      this.submitBasics(proceed);
      break;
    }
    case PoolingAgreementWorkflowStepEnum.SendingDetails: {
      this.submitPoolDetails(
        proceed,
        PoolingAgreementTractTypeEnum.Sending,
        this.sendingDetailsStep,
      );
      break;
    }
    case PoolingAgreementWorkflowStepEnum.ReceivingDetails: {
      this.submitPoolDetails(
        proceed,
        PoolingAgreementTractTypeEnum.Receiving,
        this.receivingDetailsStep,
      );
      break;
    }
    default: {
      throw new Error('Section not implemented');
    }
    }
  }

  submitBasics(proceed: boolean) {
    this.isPerformingAction = true;

    if (this.basicsStep.upsertForm.valid) {
      const upsertDto = this.basicsStep.getDto();

      this.poolingAgreementService
        .poolingAgreementsPost(upsertDto)
        .subscribe(
          (updatedPoolingAgreement) => {
            const filesToUpload = this.basicsStep.filesToUpload;
            const attachments = [...this.basicsStep.attachments];
            attachments.map(
              (x) =>
                (x.PoolingAgreementID =
                                    this.poolingAgreement.PoolingAgreementID),
            );

            if (filesToUpload.length) {
              forkJoin(
                filesToUpload.map((x) =>
                  this.fileResourceService
                    .fileResourcePost(x.file as any)
                    .pipe(
                      tap((uploaded) => {
                        // TODO
                        attachments.push(
                          new PoolingAgreementAttachmentUpsertDto(
                            {
                              PoolingAgreementID:
                                                                updatedPoolingAgreement.PoolingAgreementID,
                              FileResourceID:
                                                                uploaded.FileResourceID,
                              PoolingAgreementAttachmentID:
                                                                0 - 1 - x.index,
                            },
                          ),
                        );
                      }),
                    ),
                ),
              ).subscribe(
                () => {
                  this.mergeAttachmentsAndHandleResponse(
                    updatedPoolingAgreement,
                    attachments,
                    proceed,
                  );
                },
                (error) => {
                  this.handleUploadError();
                },
              );
            } else {
              this.mergeAttachmentsAndHandleResponse(
                updatedPoolingAgreement,
                attachments,
                proceed,
              );
            }
          },
          (error) => {
            this.handleError();
          },
        );
    } else {
      Object.keys(this.basicsStep.upsertForm.controls).forEach(
        (field) => {
          const control = this.basicsStep.upsertForm.get(field);
          control.markAsTouched({ onlySelf: true });
        },
      );

      this.isPerformingAction = false;
    }
  }

  submitPoolDetails(
    proceed: boolean,
    poolingAgreementTractType: PoolingAgreementTractTypeEnum,
    poolDetailsStep: PoolingAgreementWorkflowTractsComponent,
  ) {
    this.isPerformingAction = true;

    if (poolDetailsStep.isValid()) {
      const poolingAgreementTracts = poolDetailsStep.getDto();
      this.poolingAgreementTractService
        .poolingAgreementsPoolingAgreementIDTractTypesPoolingAgreementTractTypeTractsPut(
          this.poolingAgreement.PoolingAgreementID,
          poolingAgreementTractType,
          poolingAgreementTracts,
        )
        .subscribe(
          (x) => {
            this.handleSuccess(proceed);
          },
          (error) => {
            this.handleError();
          },
        );
    } else {
      this.isPerformingAction = false;
    }
  }

  mergeAttachmentsAndHandleResponse(
    updatedPoolingAgreement,
    attachments,
    proceed: boolean,
  ) {
    this.poolingAgreementService
      .poolingAgreementsPoolingAgreementIDAttachmentsPut(
        updatedPoolingAgreement.PoolingAgreementID,
        attachments,
      )
      .subscribe(
        () => {
          this.poolingAgreement = updatedPoolingAgreement;
          this.handleSuccess(proceed);
        },
        (error) => {
          this.poolingAgreement = updatedPoolingAgreement;
          this.handleUploadError();
        },
      );
  }

  handleError() {
    this.alertService.pushAlert(
      new Alert(
        `There was an error ${!this.isNew ? 'updating' : 'creating'} the pooling agreement`,
        AlertContext.Danger,
        true,
      ),
    );
    this.isPerformingAction = false;
    this.cdr.detectChanges();
  }

  handleUploadError() {
    this.alertService.pushAlert(
      new Alert(
        `The Pooling Agreement was ${!this.isNew ? 'updated' : 'created'} successfully, but there was an error updating the attachments.`,
        AlertContext.Warning,
        true,
      ),
    );
    this.isPerformingAction = false;
    this.cdr.detectChanges();
  }

  handleSuccess(proceed: boolean) {
    this.isPerformingAction = false;
    this.alertService.pushAlert(
      new Alert(
        `Successfully ${!this.isNew ? 'updated' : 'created'} pooling agreement`,
        AlertContext.Success,
        true,
      ),
    );
    this.isNew = false;

    if (proceed) {
      switch (this.activeWorkflowStep) {
      case PoolingAgreementWorkflowStepEnum.BasicInformation: {
        this.activeWorkflowStep =
                        PoolingAgreementWorkflowStepEnum.SendingDetails;
        break;
      }
      case PoolingAgreementWorkflowStepEnum.SendingDetails: {
        this.activeWorkflowStep =
                        PoolingAgreementWorkflowStepEnum.ReceivingDetails;
        break;
      }
      case PoolingAgreementWorkflowStepEnum.ReceivingDetails: {
        this.activeWorkflowStep =
                        PoolingAgreementWorkflowStepEnum.ReviewAndPublish;
        break;
      }
      case PoolingAgreementWorkflowStepEnum.ReviewAndPublish: {
        throw new Error('No step after Review and Publish');
      }
      default: {
        throw new Error('Cannot find desired workflow step');
      }
      }
    } else {
      window.scrollTo(0, 0);
    }

    this.poolingAgreementSubject.next(this.poolingAgreement);
    this.cdr.detectChanges();
  }

  public publishPoolingAgreement() {
    this.isPerformingAction;
    this.poolingAgreementService
      .poolingAgreementsPoolingAgreementIDPublishPut(
        this.poolingAgreement.PoolingAgreementID,
      )
      .subscribe(
        (result) => {
          this.modalReference.close();
          this.isPerformingAction = false;
          this.router
            .navigate([
              `/pooling-agreements/${this.poolingAgreement.PoolingAgreementNumber}`,
            ])
            .then((x) => {
              this.alertService.pushAlert(
                new Alert(
                  `Successfully published pooling agreement`,
                  AlertContext.Success,
                  true,
                ),
              );
            });
        },
        (error) => {
          this.modalReference.close();
          this.isPerformingAction = false;
          this.alertService.pushAlert(
            new Alert(
              `There was an error publishing the pooling agreement`,
              AlertContext.Danger,
              true,
            ),
          );
          this.cdr.detectChanges();
        },
      );
  }

  public deletePoolingAgreement() {
    this.isPerformingAction;
    this.poolingAgreementService
      .poolingAgreementsPoolingAgreementIDDelete(
        this.poolingAgreement.PoolingAgreementID,
      )
      .subscribe(
        (result) => {
          this.modalReference.close();
          this.isPerformingAction = false;
          this.router.navigate(['/pooling-agreements']).then((x) => {
            this.alertService.pushAlert(
              new Alert(
                `Successfully deleted pooling agreement`,
                AlertContext.Success,
                true,
              ),
            );
          });
        },
        (error) => {
          this.modalReference.close();
          this.alertService.pushAlert(
            new Alert(
              `There was an error deleting the pooling agreement`,
              AlertContext.Danger,
              true,
            ),
          );
          this.isPerformingAction = false;
          this.cdr.detectChanges();
        },
      );
  }

  public isPublished() {
    return (
      this.poolingAgreement.PoolingAgreementStatus
        .PoolingAgreementStatusID ==
            PoolingAgreementStatusEnum.Published
    );
  }
}
