<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page">Reports</li>
    </ol>
</nav>
<div class="container">
    <app-alert-display></app-alert-display>

    <div class="row">
        <div class="col">
            <h2 class="d-inline-block">Nitrogen Reports</h2>
        </div>
    </div>
    <div class="row mt-1 mb-2">
        <div class="col">
            <custom-rich-text
                [customRichTextTypeID]="richTextTypeID"
            ></custom-rich-text>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-auto ms-auto">
            <clear-grid-filters-button
                [grid]="nitrogenReportsGrid"
                class="me-2"
            ></clear-grid-filters-button>

            <csv-download-button
                [grid]="nitrogenReportsGrid"
                fileName="nitrogen-reports"
                [colIDsToExclude]="['0']"
                class="ms-2 mr-5" 
            ></csv-download-button>

            <select
                name="ReportTemplateID"
                class="form-select"
                style="width: auto; min-width: 100px"
                [(ngModel)]="selectedReportTemplateID"
                required
            >
                <option value="undefined" disabled>
                    Select a Report Template
                </option>
                <option
                    *ngFor="let reportTemplate of reportTemplates"
                    [value]="reportTemplate.ReportTemplateID"
                >
                    {{ reportTemplate.DisplayName }}
                </option>
            </select>

            <button
                (click)="generateReport()"
                [disabled]="isLoadingSubmit"
                class="btn btn-splash btn-md generate-report-button"
            >
                <span
                    *ngIf="isLoadingSubmit"
                    class="fa fa-spinner loading-spinner"
                ></span>
                Generate Report
            </button>
        </div>
    </div>
</div>
<div class="container">
    <div class="table-responsive">
        <ag-grid-angular
            #nitrogenReportsGrid
            style="width: 100%; height: 800px"
            class="ag-theme-balham"
            [rowData]="accountSimpleDto"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [suppressMenuHide]="true"
            [rowSelection]="'multiple'"
        >
        </ag-grid-angular>
    </div>
    <div>
        <span
            >{{ getSelectedRows().length }} of
            {{ getFilteredRowsCount() }} Selected</span
        >
    </div>
</div>
