//  IMPORTANT:
//  This file is generated. Your changes will be lost.
//  Source Table: [dbo].[CustomRichTextType]

export enum CustomRichTextTypeEnum {
  PlatformOverview = 1,
  Disclaimer = 2,
  Homepage = 3,
  WellList = 4,
  TractList = 5,
  AccountList = 6,
  Help = 7,
  PoolList = 8,
  ManageBeehiveIntegration = 9,
  FlowMeterList = 10,
  Withdrawals = 11,
  LabelsAndDefinitionsList = 12,
  AllocationPlan = 13,
  PoolingAgreement = 14,
  Training = 15,
  NeDNRAnnualReport = 16,
  ReportsList = 17,
  PoolingReportsList = 18,
  NitrogenReportsList = 19
}
