/**
 * Splash.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AllocationPlanDto } from '././allocation-plan-dto';
import { UserDto } from '././user-dto';
import { PoolDto } from '././pool-dto';
import { PoolingAgreementStatusDto } from '././pooling-agreement-status-dto';

export class PoolingAgreementDto {
  PoolingAgreementID?: number;
  PoolingAgreementNumber?: string;
  PoolingAgreementStatus?: PoolingAgreementStatusDto;
  AllocationPlan?: AllocationPlanDto;
  SendingPool?: PoolDto;
  ReceivingPool?: PoolDto;
  TransferVolume?: number;
  Notes?: string;
  EstablishmentDate?: string;
  LastUpdateDate?: string;
  LastUpdatedByUser?: UserDto;
  CreateDate?: string;
  CreatedByUser?: UserDto;
  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}
