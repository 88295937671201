<nav aria-label="breadcrumb" *ngIf="account">
    <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">
            <a routerLink="/accounts">Accounts</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
            Account: {{ account.FullName }}
        </li>
    </ol>
</nav>
<div class="container mt-sm-4" *ngIf="account">
    <app-alert-display></app-alert-display>
    <h2 class="d-inline-block">Account: {{ account.FullName }}</h2>
    <div class="row mt-4">
        <div class="col-sm-6">
            <div class="card">
                <div class="card-header">Basics</div>
                <div class="card-body">
                    <dl class="row">
                        <dt class="text-sm-end col-sm-5 col-xs-12">
                            Full Name
                        </dt>
                        <dd class="col-sm-7 col-xs-12">
                            {{ account.FullName }}
                        </dd>

                        <dt class="text-sm-end col-sm-5 col-xs-12">
                            Account Number
                        </dt>
                        <dd class="col-sm-7 col-xs-12">
                            {{ account.AccountNumber }}
                        </dd>

                        <dt class="text-sm-end col-sm-5 col-xs-12">
                            First Name
                        </dt>
                        <dd class="col-sm-7 col-xs-12">
                            {{ account.FirstName }}
                        </dd>

                        <dt class="text-sm-end col-sm-5 col-xs-12">
                            Last Name
                        </dt>
                        <dd class="col-sm-7 col-xs-12">
                            {{ account.LastName }}
                        </dd>
                        <dt class="text-sm-end col-sm-5 col-xs-12">Company</dt>
                        <dd class="col-sm-7 col-xs-12">
                            {{ account.Company }}
                        </dd>
                        <dt class="text-sm-end col-sm-5 col-xs-12">Email</dt>
                        <dd class="col-sm-7 col-xs-12">
                            {{ account.Email }}
                        </dd>
                        <dt class="text-sm-end col-sm-5 col-xs-12">Address</dt>
                        <dd class="col-sm-7 col-xs-12">
                            {{ account.Address }}
                        </dd>
                        <dt class="text-sm-end col-sm-5 col-xs-12">City</dt>
                        <dd class="col-sm-7 col-xs-12">
                            {{ account.City }}
                        </dd>
                        <dt class="text-sm-end col-sm-5 col-xs-12">State</dt>
                        <dd class="col-sm-7 col-xs-12">
                            {{ account.State }}
                        </dd>
                        <dt class="text-sm-end col-sm-5 col-xs-12">Zip</dt>
                        <dd class="col-sm-7 col-xs-12">
                            {{ account.Zip }}
                        </dd>
                        <dt class="text-sm-end col-sm-5 col-xs-12">
                            Home Phone
                        </dt>
                        <dd class="col-sm-7 col-xs-12">
                            {{ account.HomePhone }}
                        </dd>
                        <dt class="text-sm-end col-sm-5 col-xs-12">
                            Mobile Phone
                        </dt>
                        <dd class="col-sm-7 col-xs-12">
                            {{ account.MobilePhone }}
                        </dd>
                        <dt class="text-sm-end col-sm-5 col-xs-12">
                            In Care Of
                        </dt>
                        <dd class="col-sm-7 col-xs-12">
                            {{ account.InCareOf }}
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="card">
                <div class="card-header">Pools</div>
                <div
                    class="card-body table-responsive"
                    style="max-height: 600px; overflow-y: auto"
                >
                    <ng-template #noPools>
                        <p>No pools related to this account.</p>
                    </ng-template>
                    <table
                        class="table table-condensed table-striped"
                        *ngIf="pools.length > 0; else noPools"
                    >
                        <thead>
                            <tr>
                                <th>Pool</th>
                                <th>Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let pool of pools">
                                <td style="border: 0 none; white-space: nowrap">
                                    <a routerLink="/pools/{{ pool.PoolID }}">{{
                                        pool.PoolName
                                    }}</a>
                                </td>
                                <td style="border: 0 none">
                                    {{ pool.PoolTypeName }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">Related Tracts</div>
                <div
                    class="card-body table-responsive"
                    style="max-height: 600px; overflow-y: auto"
                >
                    <ng-template #noTractAccounts>
                        <p>No tracts related to this account.</p>
                    </ng-template>
                    <table
                        class="table table-condensed table-striped"
                        *ngIf="tractAccounts.length > 0; else noTractAccounts"
                    >
                        <thead>
                            <tr>
                                <th>Tract</th>
                                <th>
                                    <field-definition
                                        [fieldDefinitionType]="'Type'"
                                    ></field-definition>
                                </th>
                                <th style="text-align: right">Acres</th>
                                <th>Relationship</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let tractAccount of tractAccounts">
                                <td style="border: 0 none; white-space: nowrap">
                                    <a
                                        routerLink="/tracts/{{
                                            tractAccount.Tract.TractID
                                        }}"
                                        >{{ tractAccount.Tract.TractNumber }}</a
                                    >
                                </td>
                                <td style="border: 0 none">
                                    {{ tractAccount.Tract.TractType }}
                                </td>
                                <td
                                    style="
                                        border: 0 none;
                                        white-space: nowrap;
                                        text-align: right;
                                    "
                                >
                                    {{
                                        tractAccount.Tract.FinalAcres
                                            | number: "1.1-1"
                                    }}
                                </td>
                                <td style="border: 0 none">
                                    {{ tractAccount.RelationshipType }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
