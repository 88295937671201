<div class="form-group form-inline mt-0 mb-0 container">
    Viewing
    <a
        href="#"
        class="nav-link allocation-plan-dropdown dropdown-toggle fw-bold"
        role="button"
        data-bs-toggle="dropdown"
    >
        {{ selectedAllocationPlan.DisplayName }}
    </a>
    <div class="dropdown-menu">
        <ng-template ngFor let-allocationPlan [ngForOf]="allocationPlans">
            <a
                class="dropdown-item"
                (click)="selectedAllocationPlan = allocationPlan"
            >
                {{ allocationPlan.DisplayName }}
            </a>
        </ng-template>

        <a
            class="dropdown-item"
            (click)="selectedAllocationPlan = allYearsPlaceholderPlan"
            >All Years</a
        >
    </div>
</div>
