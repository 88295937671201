import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UtilityFunctionsService } from 'src/app/services/utility-functions.service';
import { LinkRendererComponent } from 'src/app/shared/components/ag-grid/link-renderer/link-renderer.component';
import { FieldDefinitionGridHeaderComponent } from 'src/app/shared/components/field-definition-grid-header/field-definition-grid-header.component';
import { forkJoin } from 'rxjs';
import { BeehiveSyncRunDto } from 'src/app/shared/generated/model/beehive-sync-run-dto';
import { BeehiveService } from 'src/app/shared/generated/api/beehive.service';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { PhaseReportService } from 'src/app/shared/generated/api/phase-report.service';
//import { PhaseReportDto } from 'src/app/shared/generated/model/phase-report-dto';
import { CustomDropdownFilterComponent } from 'src/app/shared/components/ag-grid/custom-dropdown-filter/custom-dropdown-filter.component';
import { PhaseReportSimpleDto } from 'src/app/shared/generated/model/phase-report-simple-dto';

@Component({
  selector: 'splash-phase-report-list',
  templateUrl: './phase-report-list.component.html',
  styleUrls: ['./phase-report-list.component.scss'],
})
export class PhaseReportListComponent implements OnInit, OnDestroy {
  @ViewChild('PhaseReportsGrid') PhaseReportsGrid: AgGridAngular;

  private currentUser: UserDto;

  //public richTextTypeID : number = CustomRichTextTypeEnum.PhaseReportList;
  public phaseReports: PhaseReportSimpleDto[];
  public columnDefs: ColDef[];
  public defaultColDef: ColDef;
  public latestBeehiveSyncRun: BeehiveSyncRunDto;

  constructor(
    private cdr: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private utilityFunctionsService: UtilityFunctionsService,
    private phaseReportService: PhaseReportService,
    private beehiveService: BeehiveService,
  ) {}

  ngOnInit() {
    this.authenticationService.getCurrentUser().subscribe((currentUser) => {
      this.currentUser = currentUser;

      forkJoin({
        phaseReports: this.phaseReportService.phaseReportsGet(),
        latestBeehiveSyncRun:
                    this.beehiveService.beehiveLatestSyncRunGet(),
      }).subscribe(({ phaseReports, latestBeehiveSyncRun }) => {
        this.phaseReports = phaseReports;
        this.latestBeehiveSyncRun = latestBeehiveSyncRun;

        this.cdr.detectChanges();
      });

      this.columnDefs = [
        {
          headerName: 'Phase II/III ID',
          valueGetter: (params) => {
            return {
              LinkDisplay: params.data.PhaseReportName,
              LinkValue: params.data.PhaseReportID,
            };
          },
          cellRenderer: LinkRendererComponent,
          cellRendererParams: { inRouterLink: '/phase-reports/' },
          filterValueGetter: (params) => params.data.PhaseReportName,
          comparator:
                        this.utilityFunctionsService.linkRendererComparator,
          width: 120,
        },
        {
          headerName: 'Tract #',
          valueGetter: (params) => {
            return {
              LinkDisplay:
                                params.data.TractNumber === null
                                  ? ''
                                  : params.data.TractNumber,
              LinkValue:
                                params.data.TractID === null
                                  ? ''
                                  : params.data.TractID,
            };
          },
          cellRenderer: LinkRendererComponent,
          cellRendererParams: { inRouterLink: '/tracts/' },
          filterValueGetter: (params) => params.data.TractNumber,
          comparator:
                        this.utilityFunctionsService.linkRendererComparator,
          width: 100,
        },
        {
          headerName: 'Status',
          field: 'PhaseReportStatus',
          width: 100,
          filter: CustomDropdownFilterComponent,
          filterParams: { field: 'PhaseReportStatus' },
        },
        this.utilityFunctionsService.createDateColumnDef(
          'Report Date',
          'ReportDate',
          'M/d/yyyy',
          145,
        ),
        {
          headerName: 'Farm Name',
          field: 'FarmName',
          width: 150,
        },
        {
          field: 'LegalDescription',
          headerComponent: FieldDefinitionGridHeaderComponent,
          headerComponentParams: { fieldDefinitionType: 'Legal' },
          width: 98,
        },
        this.utilityFunctionsService.createDecimalColumnDef(
          'Total Acres',
          'TotalAcres',
          120,
          0,
        ),
        {
          headerName: 'GWMA Cooperator',
          valueGetter: (params) => {
            return {
              LinkDisplay:
                                params.data.GWMACooperatorFullName === null
                                  ? ''
                                  : params.data.GWMACooperatorFullName,
              LinkValue:
                                params.data.GWMACooperatorID === null
                                  ? ''
                                  : params.data.GWMACooperatorID,
            };
          },
          cellRenderer: LinkRendererComponent,
          cellRendererParams: { inRouterLink: '/accounts/' },
          filterValueGetter: (params) => params.data.Pool?.PoolName,
          comparator:
                        this.utilityFunctionsService.linkRendererComparator,
          width: 150,
        },
        {
          headerName: 'Note',
          field: 'Note',
        },
        {
          headerName: 'Entry Comment',
          field: 'EntryComment',
        },
        this.utilityFunctionsService.createDecimalColumnDef(
          '# of Practices',
          'PracticeCount',
          120,
          0,
        ),
      ];

      this.defaultColDef = {
        sortable: true,
        filter: true,
        resizable: true,
      };
    });
  }

  ngOnDestroy() {
    this.cdr.detach();
  }
}
