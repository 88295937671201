<h3>Basic Information</h3>
<div class="row" style="align-items: center">
    <span
        *ngIf="!allocationPlans"
        class="fa fa-spinner loading-spinner text-center mx-auto"
    ></span>
</div>
<form [formGroup]="upsertForm" class="form" *ngIf="allocationPlans">
    <div class="row form-group mt-2">
        <label class="col-sm-4 col-form-label required text-end"
            >Allocation Period</label
        >
        <div class="col">
            <ng-container *ngIf="allocationPlans.length > 1">
                <select
                    class="form-control"
                    style="width: auto"
                    formControlName="allocationPlan"
                >
                    <option
                        *ngFor="let allocationPlan of allocationPlans"
                        [ngValue]="allocationPlan"
                    >
                        {{ allocationPlan.DisplayName }}
                    </option>
                </select>
                <ng-container
                    *ngIf="f.allocationPlan.touched && f.allocationPlan.invalid"
                >
                    <p
                        class="text-danger mt-1 mb-0"
                        *ngIf="f.allocationPlan.errors.required"
                    >
                        Allocation Period is required.
                    </p>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="allocationPlans.length == 1">
                <p class="mt-2">{{ allocationPlans[0].DisplayName }}</p>
            </ng-container>
            <ng-container *ngIf="allocationPlans.length == 0">
                <em class="text-danger"
                    >No Allocation Plans are defined; you must define an
                    Allocation Plan to create a Pooling Agreement.</em
                >
            </ng-container>
        </div>
    </div>
    <div class="row form-group mt-2">
        <label class="col-sm-4 col-form-label required text-end"
            >Sending Pool</label
        >
        <div class="col">
            <ngx-select-dropdown
                [multiple]="false"
                [config]="selectDropdownConfig"
                name="sendingPool"
                formControlName="sendingPool"
                [options]="pools"
            >
            </ngx-select-dropdown>
            <ng-container
                *ngIf="f.sendingPool.touched && f.sendingPool.invalid"
            >
                <p
                    class="text-danger mt-1 mb-0"
                    *ngIf="f.sendingPool.errors.required"
                >
                    Sending Pool is required.
                </p>
            </ng-container>
            <p
                class="text-primary mt-1 mb-0"
                *ngIf="f.sendingPool.touched && poolingAgreement"
            >
                Changing the Sending Pool will delete any Tracts previously
                entered on the Sending Details step.
            </p>
        </div>
    </div>
    <div class="row form-group mt-2">
        <label class="col-sm-4 col-form-label required text-end"
            >Receiving Pool</label
        >
        <div class="col">
            <ngx-select-dropdown
                [multiple]="false"
                [config]="selectDropdownConfig"
                name="receivingPool"
                formControlName="receivingPool"
                [options]="pools"
            >
            </ngx-select-dropdown>
            <ng-container
                *ngIf="f.receivingPool.touched && f.receivingPool.invalid"
            >
                <p
                    class="text-danger mt-1 mb-0"
                    *ngIf="f.receivingPool.errors.required"
                >
                    Receiving Pool is required.
                </p>
            </ng-container>
            <p
                class="text-primary mt-1 mb-0"
                *ngIf="f.receivingPool.touched && poolingAgreement"
            >
                Changing the Receiving Pool will delete any Tracts previously
                entered on the Receiving Details step.
            </p>
        </div>
    </div>
    <div class="row form-group mt-2">
        <label class="col-sm-4 col-form-label required text-end"
            >Transfer Volume</label
        >
        <div class="col">
            <input
                type="number"
                [ngValue]="transferVolume"
                class="form-control d-inline"
                style="width: 50%"
                formControlName="transferVolume"
            />
            ac-in
            <ng-container
                *ngIf="f.transferVolume.touched && f.transferVolume.invalid"
            >
                <p
                    class="text-danger mt-1 mb-0"
                    *ngIf="f.transferVolume.errors.required"
                >
                    Transfer Volume is required.
                </p>
                <p
                    class="text-danger mt-1 mb-0"
                    *ngIf="f.transferVolume.errors.min"
                >
                    Transfer Volume must be a number greater than 0
                </p>
            </ng-container>
        </div>
    </div>
    <div class="row form-group mt-2">
        <label class="col-sm-4 col-form-label text-end">Notes</label>
        <div class="col">
            <div class="row">
                <div class="col-sm-12">
                    <textarea
                        class="form-control w-100"
                        style="resize: none"
                        formControlName="notes"
                        id="notes"
                        maxlength="2000"
                        rows="7"
                    ></textarea>
                    <small
                        style="float: right"
                        [style.color]="notesLength == 2000 ? 'red' : ''"
                        >{{ notesLength }}/2000</small
                    >
                </div>
            </div>
        </div>
    </div>
    <div class="row form-group mt-2">
        <label class="col-sm-4 col-form-label text-end"
            >Establishment Date</label
        >
        <div class="col">
            <div class="input-group">
                <input
                    id="startDate"
                    class="form-control"
                    type="date"
                    formControlName="establishmentDate"
                    [(ngModel)]="establishmentDate"
                    placeholder="mm-dd-yyyy"
                />
            </div>
        </div>
    </div>
    <div class="row form-group mt-2">
        <label class="col-sm-4 col-form-label text-end">Attachments</label>
        <div class="col">
            <div class="row">
                <div class="col">
                    <div class="input-group">
                        <button
                            (click)="fileInput.click()"
                            style="
                                border: none;
                                background: none;
                                padding-left: 0;
                                padding-right: 0;
                                cursor: pointer;
                            "
                        >
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Choose a file"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                style="
                                    background: none;
                                    margin: 0;
                                    cursor: pointer;
                                "
                                disabled="disabled"
                            />
                        </button>
                        <button
                            type="button"
                            class="btn btn-splash"
                            (click)="fileInput.click()"
                        >
                            <i class="fa fa-plus" aria-hidden="true"></i>
                            <input
                                #fileInput
                                type="file"
                                (change)="onFileInput($event.target.files)"
                                style="display: none"
                            />
                        </button>
                    </div>
                    <em class="text-small mt-2"
                        >Allowed extensions: PDF, PNG, JPG, JPEG. Max file size:
                        25 MB.</em
                    >
                    <em class="text-danger mt-2" *ngIf="displayFileTypeMessage"
                        ><br />File must be one of: PDF, PNG, JPG, JPEG</em
                    >
                    <ul class="mt-2 list-group">
                        <li
                            *ngFor="let attachment of getAttachments()"
                            class="list-group-item"
                        >
                            {{ attachment.FileDisplayName }}
                            <button
                                type="button"
                                class="btn btn-sm btn-splash float-end"
                                (click)="deleteAttachment(attachment)"
                            >
                                <i class="fa fa-trash" aria-hidden="true"></i>
                            </button>
                        </li>
                        <li
                            *ngFor="let file of getFilesToUpload()"
                            class="list-group-item"
                        >
                            {{ file.file.name }}
                            <button
                                type="button"
                                class="btn btn-sm btn-splash float-end"
                                (click)="removeStagedFile(file)"
                            >
                                <i class="fa fa-trash" aria-hidden="true"></i>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</form>
