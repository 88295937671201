import {
  Component,
  OnInit,
  Input,
  AfterViewChecked,
  ViewChild,
} from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AlertService } from '../../services/alert.service';
import { Alert } from '../../models/alert';
import { AlertContext } from '../../models/enums/alert-context.enum';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { UserDto } from '../../generated/model/user-dto';
import { CustomRichTextService } from '../../generated/api/custom-rich-text.service';
import { FileResourceService } from '../../generated/api/file-resource.service';
import { CustomRichTextDto } from '../../generated/model/custom-rich-text-dto';

@Component({
  selector: 'custom-rich-text',
  templateUrl: './custom-rich-text.component.html',
  styleUrls: ['./custom-rich-text.component.scss'],
})
export class CustomRichTextComponent implements OnInit {
  public tinyMceConfig: object;
  @Input() customRichTextTypeID: number;
  public customRichTextContent: SafeHtml;
  public isLoading: boolean = true;
  public isEditing: boolean = false;
  public isEmptyContent: boolean = false;

  public editedContent: string;
  public editor;
  public canEdit: boolean;

  currentUser: UserDto;

  constructor(
    private customRichTextService: CustomRichTextService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private sanitizer: DomSanitizer,
    private fileResourceService: FileResourceService,
  ) {}

  ngOnInit() {
    this.authenticationService.getCurrentUser().subscribe((currentUser) => {
      this.currentUser = currentUser;
      this.canEdit = this.authenticationService.isUserAnAdministrator(this.currentUser);
    });

    this.customRichTextService
      .customRichTextCustomRichTextTypeIDGet(this.customRichTextTypeID)
      .subscribe((x) => {
        this.customRichTextContent =
                    this.sanitizer.bypassSecurityTrustHtml(
                      x.CustomRichTextContent,
                    );
        this.editedContent = x.CustomRichTextContent;
        this.isEmptyContent = x.IsEmptyContent;
        this.isLoading = false;
      });
  }

  public enterEdit(): void {
    this.isEditing = true;
  }

  public cancelEdit(): void {
    this.isEditing = false;
  }

  public saveEdit(): void {
    this.isEditing = false;
    this.isLoading = true;
    const updateDto = new CustomRichTextDto({
      CustomRichTextContent: this.editedContent,
    });
    this.customRichTextService
      .customRichTextCustomRichTextTypeIDPut(
        this.customRichTextTypeID,
        updateDto,
      )
      .subscribe(
        (x) => {
          this.customRichTextContent =
                        this.sanitizer.bypassSecurityTrustHtml(
                          x.CustomRichTextContent,
                        );
          this.editedContent = x.CustomRichTextContent;
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          this.alertService.pushAlert(
            new Alert(
              'There was an error updating the rich text content',
              AlertContext.Danger,
              true,
            ),
          );
        },
      );
  }
}
