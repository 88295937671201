import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { PoolDto } from 'src/app/shared/generated/model/pool-dto';
import { PoolService } from 'src/app/shared/generated/api/pool.service';
import { PoolUsageSummaryDto } from 'src/app/shared/generated/model/pool-usage-summary-dto';
import { PoolingAgreementDto } from 'src/app/shared/generated/model/pooling-agreement-dto';
import { PoolingAgreementService } from 'src/app/shared/generated/api/pooling-agreement.service';
import { PoolingAgreementTractService } from 'src/app/shared/generated/api/pooling-agreement-tract.service';
import { PoolingAgreementTractTypeEnum } from 'src/app/shared/generated/model/pooling-agreement-tract-type-enum';
import { PoolingAgreementTractDetailedDto } from 'src/app/shared/generated/model/pooling-agreement-tract-detailed-dto';
import { PoolingAgreementAttachmentUpsertDto } from 'src/app/shared/generated/model/pooling-agreement-attachment-upsert-dto';
import { TractWithAccountDto } from 'src/app/shared/generated/model/tract-with-account-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { FileResourceService } from 'src/app/shared/generated/api/file-resource.service';

@Component({
  selector: 'splash-pooling-agreement-detail',
  templateUrl: './pooling-agreement-detail.component.html',
  styleUrls: ['./pooling-agreement-detail.component.scss'],
})
export class PoolingAgreementDetailComponent implements OnInit {
  private currentUser: UserDto;

  public poolingAgreement: PoolingAgreementDto;
  public poolsInAgreement: PoolDto[];
  public attachments: PoolingAgreementAttachmentUpsertDto[];
  public sendingTracts: PoolingAgreementTractDetailedDto[];
  public receivingTracts: PoolingAgreementTractDetailedDto[];
  public fetchingTracts: boolean = true;
  public sendingPoolUsage: PoolUsageSummaryDto;
  public receivingPoolUsage: PoolUsageSummaryDto;
  public allTracts: PoolingAgreementDetailTractView[];
  public sendingPool: PoolDto;
  public receivingPool: PoolDto;

  constructor(
    private authenticationService: AuthenticationService,
    private poolingAgreementService: PoolingAgreementService,
    private activatedRoute: ActivatedRoute,
    private fileResourceService: FileResourceService,
    private poolingAgreementTractService: PoolingAgreementTractService,
    private poolService: PoolService,
  ) {}

  ngOnInit(): void {
    const poolingAgreementNumber =
            this.activatedRoute.snapshot.paramMap.get('poolingAgreementNumber');
    this.authenticationService.getCurrentUser().subscribe((currentUser) => {
      this.currentUser = currentUser;

      this.poolingAgreementService
        .poolingAgreementsPoolingAgreementNumberGet(
          poolingAgreementNumber,
        )
        .subscribe((poolingAgreement) => {
          this.poolingAgreement = poolingAgreement;
          this.sendingPool = poolingAgreement.SendingPool;
          this.receivingPool = poolingAgreement.ReceivingPool;

          forkJoin({
            sendingTracts:
                            this.poolingAgreementTractService.poolingAgreementsPoolingAgreementIDTractTypesPoolingAgreementTractTypeTractsDetailedGet(
                              this.poolingAgreement.PoolingAgreementID,
                              PoolingAgreementTractTypeEnum.Sending,
                            ),
            receivingTracts:
                            this.poolingAgreementTractService.poolingAgreementsPoolingAgreementIDTractTypesPoolingAgreementTractTypeTractsDetailedGet(
                              this.poolingAgreement.PoolingAgreementID,
                              PoolingAgreementTractTypeEnum.Receiving,
                            ),
            attachments:
                            this.poolingAgreementService.poolingAgreementsPoolingAgreementIDAttachmentsGet(
                              this.poolingAgreement.PoolingAgreementID,
                            ),
            sendingUsage:
                            this.poolService.poolsPoolIDUsageSummaryAllocationPlanIDGet(
                              poolingAgreement.SendingPool.PoolID,
                              this.poolingAgreement.AllocationPlan
                                .AllocationPlanID,
                            ),
            receivingUsage:
                            this.poolService.poolsPoolIDUsageSummaryAllocationPlanIDGet(
                              poolingAgreement.ReceivingPool.PoolID,
                              this.poolingAgreement.AllocationPlan
                                .AllocationPlanID,
                            ),
          }).subscribe(
            ({
              sendingTracts,
              receivingTracts,
              attachments,
              sendingUsage,
              receivingUsage,
            }) => {
              this.sendingTracts = sendingTracts;
              this.receivingTracts = receivingTracts;
              this.sendingPoolUsage = sendingUsage;
              this.receivingPoolUsage = receivingUsage;
              this.allTracts = this.getTractsInDisplayForm(
                sendingTracts.concat(receivingTracts),
              );
              this.fetchingTracts = false;
              this.attachments = attachments;
            },
          );
        });
    });
  }

  public currentUserIsAdmin(): boolean {
    return this.authenticationService.isCurrentUserAnAdministrator();
  }

  public getLinkForFile(guid: string) {
    return this.fileResourceService.fileResourceFileResourceGuidAsStringDownloadGet(
      guid,
    );
  }

  public getTractIDs(
    tracts: Array<PoolingAgreementTractDetailedDto>,
  ): Array<number> {
    return tracts != null && tracts.length > 0
      ? tracts
        .filter((x) => x.TransferVolume != null)
        .map((x) => x.TractWithAccountDto.TractID)
      : [];
  }

  public getTractsInDisplayForm(
    tracts: PoolingAgreementTractDetailedDto[],
  ): PoolingAgreementDetailTractView[] {
    return tracts
      .filter((x) => x.TransferVolume != null)
      .map((x) => {
        const isSendingPool =
                    x.TractWithAccountDto.Pool.PoolID ==
                    this.sendingPool.PoolID;
        const waterSent = isSendingPool ? x.TransferVolume : 0;
        const waterReceived =
                    x.TractWithAccountDto.Pool.PoolID ==
                    this.receivingPool.PoolID
                      ? x.TransferVolume
                      : 0;

        return new PoolingAgreementDetailTractView({
          TractID: x.TractWithAccountDto.TractID,
          TractName: x.TractWithAccountDto.TractNumber,
          PoolID: x.TractWithAccountDto.Pool.PoolID,
          PoolName: x.TractWithAccountDto.Pool.PoolName,
          FinalAcres: x.TractWithAccountDto.FinalAcres,
          Allocation: this.getTractAllocationForAllocationPeriod(
            x.TractWithAccountDto,
          ),
          WaterSent: waterSent != 0 ? waterSent : null,
          WaterReceived: waterReceived != 0 ? waterReceived : null,
          CurrentAvailable:
                        this.getTractRemainingWaterForAllocationPeriod(
                          x.TractWithAccountDto,
                          isSendingPool
                            ? this.sendingPoolUsage
                            : this.receivingPoolUsage,
                        ) +
                        x.AllocationPlanOverallTractBalanceFromPoolingAgreements +
                        waterReceived -
                        waterSent,
        });
      });
  }

  public getTractAllocationForAllocationPeriod(
    tract: TractWithAccountDto,
  ): number {
    return (
      (tract.FinalAcres ?? 0) *
            this.poolingAgreement.AllocationPlan.AllocationVolume
    );
  }

  public getTractRemainingWaterForAllocationPeriod(
    tract: TractWithAccountDto,
    poolUsageSummary: PoolUsageSummaryDto,
  ): number {
    const weight = this.getTractWeight(tract, poolUsageSummary);
    const tractUsage =
            poolUsageSummary.TotalUsageForAllocationPlan * weight;
    return this.getTractAllocationForAllocationPeriod(tract) - tractUsage;
  }

  public getTractWeight(
    tract: TractWithAccountDto,
    poolUsageSummary: PoolUsageSummaryDto,
  ): number {
    return tract.FinalAcres / poolUsageSummary.PoolAcres;
  }
}

export class PoolingAgreementDetailTractView {
  TractID: number;
  TractName: string;
  PoolID: number;
  PoolName: string;
  FinalAcres: number;
  Allocation: number;
  WaterSent?: number;
  WaterReceived?: number;
  CurrentAvailable: number;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}
