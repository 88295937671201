import { AllocationPlanDto } from '../../generated/model/allocation-plan-dto';

// StartYear and Duration chosen to make logic on the water budget overview panel as consistent as possible
export const AllYearsPlaceholderPlan: AllocationPlanDto = {
  AllocationPlanID: -1,
  IsActive: true,
  StartYear: 0,
  AllocationVolume: Infinity,
  Duration: Infinity,
  LastUpdatedByUser: null,
  LastUpdatedDate: null,
  DisplayName: 'All Years',
  Notes: null,
  NumPoolingAgreements: 0,
};
