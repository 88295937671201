import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'splash-tract-detail-popup',
  templateUrl: './tract-detail-popup.component.html',
  styleUrls: ['./tract-detail-popup.component.scss'],
})
export class TractDetailPopupComponent implements OnInit {
  constructor(private cdr: ChangeDetectorRef) {}

  public feature: any;

  ngOnInit() {}

  public detectChanges(): void {
    this.cdr.detectChanges();
  }
}
