<dl class="row mb-0">
    <dt class="col-5 text-end">Name</dt>
    <dd class="col-7">
        <a
            routerLink="/wells/{{ feature.properties.WellID }}"
            (click)="detectChanges()"
        >
            {{ feature.properties.WellName }}
        </a>
    </dd>
</dl>
<dl class="row mb-0">
    <dt class="col-5 text-end">Type</dt>
    <dd class="col-7">
        {{ feature.properties.WellUse }}
    </dd>
</dl>
<dl class="row mb-0">
    <dt class="col-5 text-end">NRD ID</dt>
    <dd class="col-7">
        {{ feature.properties.NRDID }}
    </dd>
</dl>
<dl class="row mb-0">
    <dt class="col-5 text-end">Reg #</dt>
    <dd class="col-7">
        {{ feature.properties.RegistrationNumber }}
    </dd>
</dl>
