/**
 * Splash.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';

import { Observable } from 'rxjs';

import { BoundingBoxDto } from '../model/bounding-box-dto';
import { CropGrownDto } from '../model/crop-grown-dto';
import { PhaseReportSimpleDto } from '../model/phase-report-simple-dto';
import { TractAccountDto } from '../model/tract-account-dto';
import { TractGroundwaterTransferDto } from '../model/tract-groundwater-transfer-dto';
import { TractWellDto } from '../model/tract-well-dto';
import { TractWithAccountDto } from '../model/tract-with-account-dto';

import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class TractService {
  protected basePath = 'http://localhost';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    private apiService: ApiService,
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
     *
     *
     * @param requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public tractsBoundingBoxPut(
    requestBody?: Array<number>,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<BoundingBoxDto>;
  public tractsBoundingBoxPut(
    requestBody?: Array<number>,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<BoundingBoxDto>>;
  public tractsBoundingBoxPut(
    requestBody?: Array<number>,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<BoundingBoxDto>>;
  public tractsBoundingBoxPut(
    requestBody?: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    const httpContentTypeSelected: string | undefined =
            this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient
      .put<BoundingBoxDto>(
      `${this.basePath}/tracts/boundingBox`,
      requestBody,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    )
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }

  /**
     *
     *
     * @param requestBody
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public tractsBoundingBoxWithWellsPost(
    requestBody?: Array<number>,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<BoundingBoxDto>;
  public tractsBoundingBoxWithWellsPost(
    requestBody?: Array<number>,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<BoundingBoxDto>>;
  public tractsBoundingBoxWithWellsPost(
    requestBody?: Array<number>,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<BoundingBoxDto>>;
  public tractsBoundingBoxWithWellsPost(
    requestBody?: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    const httpContentTypeSelected: string | undefined =
            this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient
      .post<BoundingBoxDto>(
      `${this.basePath}/tracts/boundingBoxWithWells`,
      requestBody,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    )
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }

  /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public tractsGet(
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<Array<TractWithAccountDto>>;
  public tractsGet(
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Array<TractWithAccountDto>>>;
  public tractsGet(
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Array<TractWithAccountDto>>>;
  public tractsGet(
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient
      .get<Array<TractWithAccountDto>>(`${this.basePath}/tracts`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    })
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }

  /**
     *
     *
     * @param tractID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public tractsTractIDAccountsGet(
    tractID: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<Array<TractAccountDto>>;
  public tractsTractIDAccountsGet(
    tractID: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Array<TractAccountDto>>>;
  public tractsTractIDAccountsGet(
    tractID: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Array<TractAccountDto>>>;
  public tractsTractIDAccountsGet(
    tractID: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (tractID === null || tractID === undefined) {
      throw new Error(
        'Required parameter tractID was null or undefined when calling tractsTractIDAccountsGet.',
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient
      .get<Array<TractAccountDto>>(
      `${this.basePath}/tracts/${encodeURIComponent(String(tractID))}/accounts`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    )
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }

  /**
     *
     *
     * @param tractID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public tractsTractIDCropsGrownGet(
    tractID: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<Array<CropGrownDto>>;
  public tractsTractIDCropsGrownGet(
    tractID: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Array<CropGrownDto>>>;
  public tractsTractIDCropsGrownGet(
    tractID: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Array<CropGrownDto>>>;
  public tractsTractIDCropsGrownGet(
    tractID: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (tractID === null || tractID === undefined) {
      throw new Error(
        'Required parameter tractID was null or undefined when calling tractsTractIDCropsGrownGet.',
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient
      .get<Array<CropGrownDto>>(
      `${this.basePath}/tracts/${encodeURIComponent(String(tractID))}/cropsGrown`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    )
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }

  /**
     *
     *
     * @param tractID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public tractsTractIDGet(
    tractID: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<TractWithAccountDto>;
  public tractsTractIDGet(
    tractID: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<TractWithAccountDto>>;
  public tractsTractIDGet(
    tractID: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<TractWithAccountDto>>;
  public tractsTractIDGet(
    tractID: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (tractID === null || tractID === undefined) {
      throw new Error(
        'Required parameter tractID was null or undefined when calling tractsTractIDGet.',
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient
      .get<TractWithAccountDto>(
      `${this.basePath}/tracts/${encodeURIComponent(String(tractID))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    )
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }

  /**
     *
     *
     * @param tractID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public tractsTractIDGroundwaterTransfersGet(
    tractID: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<Array<TractGroundwaterTransferDto>>;
  public tractsTractIDGroundwaterTransfersGet(
    tractID: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Array<TractGroundwaterTransferDto>>>;
  public tractsTractIDGroundwaterTransfersGet(
    tractID: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Array<TractGroundwaterTransferDto>>>;
  public tractsTractIDGroundwaterTransfersGet(
    tractID: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (tractID === null || tractID === undefined) {
      throw new Error(
        'Required parameter tractID was null or undefined when calling tractsTractIDGroundwaterTransfersGet.',
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient
      .get<Array<TractGroundwaterTransferDto>>(
      `${this.basePath}/tracts/${encodeURIComponent(String(tractID))}/groundwaterTransfers`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    )
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }

  /**
     *
     *
     * @param tractID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public tractsTractIDPhaseReportsGet(
    tractID: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<Array<PhaseReportSimpleDto>>;
  public tractsTractIDPhaseReportsGet(
    tractID: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Array<PhaseReportSimpleDto>>>;
  public tractsTractIDPhaseReportsGet(
    tractID: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Array<PhaseReportSimpleDto>>>;
  public tractsTractIDPhaseReportsGet(
    tractID: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (tractID === null || tractID === undefined) {
      throw new Error(
        'Required parameter tractID was null or undefined when calling tractsTractIDPhaseReportsGet.',
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient
      .get<Array<PhaseReportSimpleDto>>(
      `${this.basePath}/tracts/${encodeURIComponent(String(tractID))}/phaseReports`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    )
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }

  /**
     *
     *
     * @param tractID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public tractsTractIDWellsGet(
    tractID: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<Array<TractWellDto>>;
  public tractsTractIDWellsGet(
    tractID: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Array<TractWellDto>>>;
  public tractsTractIDWellsGet(
    tractID: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Array<TractWellDto>>>;
  public tractsTractIDWellsGet(
    tractID: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (tractID === null || tractID === undefined) {
      throw new Error(
        'Required parameter tractID was null or undefined when calling tractsTractIDWellsGet.',
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient
      .get<Array<TractWellDto>>(
      `${this.basePath}/tracts/${encodeURIComponent(String(tractID))}/wells`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    )
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }
}
