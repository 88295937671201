//  IMPORTANT:
//  This file is generated. Your changes will be lost.
//  Source Table: [dbo].[FieldDefinitionType]

export enum FieldDefinitionTypeEnum {
  Type = 1,
  Category = 2,
  IrrigationMethod = 3,
  FinalAcres = 4,
  AssesorAcres = 5,
  ReportedAcres = 6,
  ProvisionalAcres = 7,
  GWMANumber2 = 8,
  NRDAdjustment = 9,
  Legal = 10,
  Phase = 11,
  PrimaryUse = 12,
  SecondaryUse = 13,
  LifeCycle = 14,
  Multiplier = 15,
  InsideDiameter = 16,
  OutsideDiameter = 17,
  Status = 18,
  ReportType = 19,
  GPM = 20,
  UsageToDate = 21,
  Allocation = 22,
  Carryover = 23,
  PoolingAgreements = 24,
  TotalAvailableWater = 25,
  RemainingAvailableWater = 26
}
