import {
  NgModule,
  ModuleWithProviders,
  SkipSelf,
  Optional,
} from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AccountService } from './api/account.service';
import { AllocationPlanService } from './api/allocation-plan.service';
import { BeehiveService } from './api/beehive.service';
import { CustomRichTextService } from './api/custom-rich-text.service';
import { DownloadService } from './api/download.service';
import { FieldDefinitionService } from './api/field-definition.service';
import { FileResourceService } from './api/file-resource.service';
import { FlowMeterService } from './api/flow-meter.service';
import { NitrogenReportService } from './api/nitrogen-report.service';
import { PhaseReportService } from './api/phase-report.service';
import { PoolService } from './api/pool.service';
import { PoolingAgreementService } from './api/pooling-agreement.service';
import { PoolingAgreementTractService } from './api/pooling-agreement-tract.service';
import { PoolingReportService } from './api/pooling-report.service';
import { PracticeService } from './api/practice.service';
import { ReportTemplateService } from './api/report-template.service';
import { RoleService } from './api/role.service';
import { TractService } from './api/tract.service';
import { UserService } from './api/user.service';
import { WellService } from './api/well.service';
import { WithdrawalService } from './api/withdrawal.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    AccountService,
    AllocationPlanService,
    BeehiveService,
    CustomRichTextService,
    DownloadService,
    FieldDefinitionService,
    FileResourceService,
    FlowMeterService,
    NitrogenReportService,
    PhaseReportService,
    PoolService,
    PoolingAgreementService,
    PoolingAgreementTractService,
    PoolingReportService,
    PracticeService,
    ReportTemplateService,
    RoleService,
    TractService,
    UserService,
    WellService,
    WithdrawalService,
  ],
})
export class ApiModule {
  public static forRoot(
    configurationFactory: () => Configuration,
  ): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        { provide: Configuration, useFactory: configurationFactory },
      ],
    };
  }

  constructor(
  @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient,
  ) {
    if (parentModule) {
      throw new Error(
        'ApiModule is already loaded. Import in your base AppModule only.',
      );
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
                    'See also https://github.com/angular/angular/issues/20575',
      );
    }
  }
}
