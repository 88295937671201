import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ChangeDetectionStrategy,
  ApplicationRef,
} from '@angular/core';
import { Feature, FeatureCollection, Polygon } from 'geojson';
import { environment } from 'src/environments/environment';
import { WfsService } from '../../services/wfs.service';
import * as L from 'leaflet';
import '../../../../../node_modules/leaflet.fullscreen/Control.FullScreen.js';
import { TractDetailPopupComponent } from '../tract-detail-popup/tract-detail-popup.component';
import { CustomCompileService } from '../../services/custom-compile.service';
import { TractService } from '../../generated/api/tract.service';
import { BoundingBoxDto } from '../../generated/model/bounding-box-dto';

declare let $: any;

@Component({
  selector: 'tract-map',
  templateUrl: './tract-map.component.html',
  styleUrls: ['./tract-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TractMapComponent implements OnInit, AfterViewInit {
  @Input() public mapID: string = '';

  @Input() public visibleTractStyle: string = 'tract_purple_outline_only';

  @Input() public visibleTractIDs: Array<number> = [];

  @Input() public selectedTractTransparencyStyle: string = 'tract_purple';

  @Input() public selectedTractLabelStyle: string = 'tract_name_only';

  @Input() public selectedTractIDs: Array<number> = [];

  @Input() public displayWells: boolean = false;

  @Input() public visibleWellIDs: Array<number> = [];

  @Input() public onEachFeatureCallback?: (feature, layer) => void;

  @Input() public zoomMapToDefaultExtent: boolean = true;

  @Input() public disableDefaultClick: boolean = false;

  @Input() public displaytractsLayerOnLoad: boolean = true;

  @Input() public mapHeight: string = '300px';

  @Input() public defaultFitBoundsOptions?: L.FitBoundsOptions = null;

  @Input() public selectedTractLayerName: string =
    '<img src=\'./assets/main/map-legend-images/tract.png\' style=\'height:16px; margin-bottom:3px\'> Selected Tracts';

  @Output() public afterSetControl: EventEmitter<L.Control.Layers> =
    new EventEmitter();

  @Output() public afterLoadMap: EventEmitter<L.LeafletEvent> =
    new EventEmitter();

  @Output() public onMapMoveEnd: EventEmitter<L.LeafletEvent> =
    new EventEmitter();

  public component: any;

  public map: L.Map;
  public featureLayer: any;
  public layerControl: L.Control.Layers;
  public tileLayers: { [key: string]: any } = {};
  public overlayLayers: { [key: string]: any } = {};
  public boundingBox: BoundingBoxDto;
  private selectedTractTransparencyLayer: any;
  private selectedTractLabelLayer: any;
  private selectedTractLayerGroup: L.LayerGroup = new L.LayerGroup();
  private defaultTractsWMSOptions: L.WMSOptions;
  public tractsLayer: any;
  public tractsWMSOptions: any;

  constructor(
    private wfsService: WfsService,
    private tractService: TractService,
    private appRef: ApplicationRef,
    private compileService: CustomCompileService,
  ) {}

  public ngOnInit(): void {
    // Default bounding box
    this.boundingBox = new BoundingBoxDto();
    this.boundingBox.Left = -98.49706346987776;
    this.boundingBox.Bottom = 40.4303080924072;
    this.boundingBox.Right = -96.95153112621863;
    this.boundingBox.Top = 41.269115170389895;

    this.tileLayers = Object.assign(
      {},
      {
        Aerial: L.tileLayer(
          'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          {
            attribution: 'Aerial',
          },
        ),
        Street: L.tileLayer(
          'https://services.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
          {
            attribution: 'Aerial',
          },
        ),
        Terrain: L.tileLayer(
          'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
          {
            attribution: 'Terrain',
          },
        ),
      },
      this.tileLayers,
    );

    this.defaultTractsWMSOptions = {
      layers: 'Splash:Tracts',
      transparent: true,
      format: 'image/png',
      tiled: true,
    } as L.WMSOptions;

    this.prepareVisibleTractsLayer();

    const wellsWMSOptions = {
      layers: 'Splash:Wells',
      transparent: true,
      format: 'image/png',
      tiled: true,
      cql_filter: this.createWellMapFilter(this.visibleWellIDs),
    } as L.WMSOptions;

    if (this.displayWells) {
      this.overlayLayers = Object.assign(
        {
          '<img src=\'./assets/main/map-legend-images/tract_outline_only.png\' style=\'height:16px; margin-bottom:3px\'> Related Tracts':
                        L.tileLayer.wms(
                          environment.geoserverMapServiceUrl + '/wms?',
                          this.tractsWMSOptions,
                        ),
          '<img src=\'./assets/main/map-legend-images/well.png\' style=\'height:16px; margin-bottom:3px\'> Related Wells':
                        L.tileLayer.wms(
                          environment.geoserverMapServiceUrl + '/wms?',
                          wellsWMSOptions,
                        ),
        },
        this.overlayLayers,
      );
    } else {
      this.overlayLayers = Object.assign(
        {
          '<img src=\'./assets/main/map-legend-images/tract_outline_only.png\' style=\'height:16px; margin-bottom:3px\'> Tracts':
                        this.tractsLayer,
        },
        this.overlayLayers,
      );
    }

    this.compileService.configure(this.appRef);
  }

  public prepareVisibleTractsLayer() {
    this.tractsWMSOptions = Object.assign(
      { styles: this.visibleTractStyle },
      this.defaultTractsWMSOptions,
    );
    if (!this.tractsLayer) {
      this.tractsLayer = L.tileLayer.wms(
        environment.geoserverMapServiceUrl + '/wms?',
        this.tractsWMSOptions,
      );
    }
    if (this.visibleTractIDs.length > 0) {
      this.tractsWMSOptions.cql_filter = this.createTractMapFilter(
        this.visibleTractIDs,
      );
      this.fitBoundsToVisibleTracts();
      this.tractsLayer.setParams(this.tractsWMSOptions);
    }
  }

  public updateSelectedTractsOverlayLayer(tractIDs: Array<number>) {
    if (
      this.selectedTractTransparencyLayer &&
            this.selectedTractLabelLayer
    ) {
      this.layerControl.removeLayer(this.selectedTractLayerGroup);
      this.map.removeLayer(this.selectedTractTransparencyLayer);
      this.map.removeLayer(this.selectedTractLabelLayer);
    }

    const wmsParameters = Object.assign(
      {
        styles: this.selectedTractTransparencyStyle,
        cql_filter: this.createTractMapFilter(tractIDs),
      },
      this.defaultTractsWMSOptions,
    );
    this.selectedTractTransparencyLayer = L.tileLayer.wms(
      environment.geoserverMapServiceUrl + '/wms?',
      wmsParameters,
    );
    wmsParameters.styles = this.selectedTractLabelStyle;
    this.selectedTractLabelLayer = L.tileLayer.wms(
      environment.geoserverMapServiceUrl + '/wms?',
      wmsParameters,
    );
    this.selectedTractLayerGroup.addLayer(
      this.selectedTractTransparencyLayer,
    );
    this.selectedTractLayerGroup.addLayer(this.selectedTractLabelLayer);
    this.layerControl.addOverlay(
      this.selectedTractLayerGroup,
      this.selectedTractLayerName,
    );

    this.selectedTractLayerGroup.addTo(this.map);

    //We don't want wells to hide under transparency, but also want labels for tracts to be visible
    if (this.displayWells) {
      this.overlayLayers[
        '<img src=\'./assets/main/map-legend-images/well.png\' style=\'height:16px; margin-bottom:3px\'> Related Wells'
      ].bringToFront();
      this.selectedTractLabelLayer.bringToFront();
    }
  }

  public fitBoundsToVisibleTracts() {
    const tractIDs = this.visibleTractIDs;
    this.tractService
      .tractsBoundingBoxPut(tractIDs)
      .subscribe((boundingBox) => {
        this.boundingBox = boundingBox;
        this.map.fitBounds(
          [
            [this.boundingBox.Bottom, this.boundingBox.Left],
            [this.boundingBox.Top, this.boundingBox.Right],
          ],
          this.defaultFitBoundsOptions,
        );
      });
  }

  public fitBoundsToSelectedTracts() {
    const tractIDs = this.selectedTractIDs;
    this.tractService
      .tractsBoundingBoxWithWellsPost(tractIDs)
      .subscribe((boundingBox) => {
        this.boundingBox = boundingBox;
        this.map.fitBounds(
          [
            [this.boundingBox.Bottom, this.boundingBox.Left],
            [this.boundingBox.Top, this.boundingBox.Right],
          ],
          this.defaultFitBoundsOptions,
        );
      });
  }

  private createTractMapFilter(tractIDs: Array<number>): any {
    return `TractID in (${tractIDs.join(',')})`;
  }

  private createWellMapFilter(wellIDs: Array<number>): any {
    return `WellID in (${wellIDs.join(',')})`;
  }

  public ngAfterViewInit(): void {
    const mapOptions: L.MapOptions = {
      // center: [46.8797, -110],
      // zoom: 6,
      minZoom: 9,
      maxZoom: 17,
      layers: [this.tileLayers.Aerial],
      fullscreenControl: true,
    } as L.MapOptions;
    this.map = L.map(this.mapID, mapOptions);

    this.map.on('load', (event: L.LeafletEvent) => {
      this.afterLoadMap.emit(event);
    });
    this.map.on('moveend', (event: L.LeafletEvent) => {
      this.onMapMoveEnd.emit(event);
    });
    this.map.fitBounds(
      [
        [this.boundingBox.Bottom, this.boundingBox.Left],
        [this.boundingBox.Top, this.boundingBox.Right],
      ],
      this.defaultFitBoundsOptions,
    );

    this.setControl();
    this.registerClickEvents();

    if (this.selectedTractIDs.length > 0) {
      this.updateSelectedTractsOverlayLayer(this.selectedTractIDs);
      this.fitBoundsToSelectedTracts();
    }

    if (!this.disableDefaultClick) {
      const wfsService = this.wfsService;
      const self = this;
      this.map.on('click', (event: L.LeafletMouseEvent): void => {
        wfsService
          .getTractByCoordinate(event.latlng.lng, event.latlng.lat)
          .subscribe((tractFeatureCollection: FeatureCollection) => {
            tractFeatureCollection.features.forEach(
              (feature: Feature) => {
                // Flip the coordinates
                switch (feature.geometry.type) {
                case 'Polygon':
                  const polygon: Polygon =
                                            feature.geometry as Polygon;
                  polygon.coordinates =
                                            polygon.coordinates.map(
                                              (coordinate) =>
                                                coordinate.map((point) => [
                                                  point[1],
                                                  point[0],
                                                ]),
                                            );
                  break;
                }
                new L.Popup({
                  minWidth: 200,
                })
                  .setLatLng(event.latlng)
                  .setContent(
                    this.compileService.compile(
                      TractDetailPopupComponent,
                      (c) => {
                        c.instance.feature = feature;
                      },
                    ),
                  )
                  .openOn(self.map);
              },
            );
          });
      });
    }
  }

  public setControl(): void {
    this.layerControl = new L.Control.Layers(
      this.tileLayers,
      this.overlayLayers,
      { collapsed: false },
    ).addTo(this.map);
    if (this.displaytractsLayerOnLoad) {
      this.overlayLayers[
        this.displayWells
          ? '<img src=\'./assets/main/map-legend-images/tract_outline_only.png\' style=\'height:16px; margin-bottom:3px\'> Related Tracts'
          : '<img src=\'./assets/main/map-legend-images/tract_outline_only.png\' style=\'height:16px; margin-bottom:3px\'> Tracts'
      ].addTo(this.map);
    }
    if (this.displayWells) {
      this.overlayLayers[
        '<img src=\'./assets/main/map-legend-images/well.png\' style=\'height:16px; margin-bottom:3px\'> Related Wells'
      ].addTo(this.map);
    }
    this.afterSetControl.emit(this.layerControl);
  }

  public registerClickEvents(): void {
    const leafletControlLayersSelector = '.leaflet-control-layers';
    const closeButtonClass = 'leaflet-control-layers-close';

    const closem = L.DomUtil.create('a', closeButtonClass);
    closem.innerHTML = 'Close';
    L.DomEvent.on(closem, 'click', function () {
      $(leafletControlLayersSelector).removeClass(
        'leaflet-control-layers-expanded',
      );
    });

    $(leafletControlLayersSelector).append(closem);
  }
}
