<nav class="navbar navbar-splash navbar-expand-lg navbar-dark stroke">
    <a
        href="https://www.upperbigblue.org/"
        class="navbar-brand"
        target="_blank"
    >
        <img src="assets/main/logos/ubbnrd_logo_191_191.png" class="navbarLogo" />
    </a>
    <a routerLink="/" routerLinkActive="active" class="navbar-brand nav-link">
        Water Accounting Platform
    </a>
    <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
    >
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav me-auto mt-2 mt-lg-0">
            <li
                class="nav-item dropdown"
                routerLinkActive="active"
                *ngIf="isAdministrator() || isReadOnly()"
            >
                <a
                    href="#"
                    class="nav-link dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    Pools
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a routerLink="/pools" class="dropdown-item">
                        View all Pools
                    </a>
                    <a routerLink="/allocation-plans" class="dropdown-item">
                        Allocation Plans
                    </a>
                    <a routerLink="/pooling-agreements" class="dropdown-item">
                        Pooling Agreements
                    </a>
                </div>
            </li>
            <li
                class="nav-item dropdown"
                routerLinkActive="active"
                *ngIf="isAdministrator() || isReadOnly()"
            >
                <a
                    href="#"
                    class="nav-link dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    Beehive Data
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a routerLink="/accounts" class="dropdown-item">
                        Accounts
                    </a>
                    <a routerLink="/tracts" class="dropdown-item"> Tracts </a>
                    <a routerLink="/wells" class="dropdown-item"> Wells </a>
                    <a routerLink="/flow-meters" class="dropdown-item">
                        Flow Meters
                    </a>
                    <a routerLink="/withdrawals" class="dropdown-item">
                        Withdrawals
                    </a>
                    <hr />
                    <a routerLink="/phase-reports" class="dropdown-item">
                        Phase Reports
                    </a>
                </div>
            </li>
            <li
                class="nav-item dropdown"
                routerLinkActive="active"
                *ngIf="isAdministrator()"
            >
                <a
                    href="#"
                    class="nav-link dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    Manage
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a
                        routerLink="/manage-beehive-integration"
                        class="dropdown-item"
                    >
                        Beehive Integration
                    </a>
                    <a
                        routerLink="/labels-and-definitions"
                        class="dropdown-item"
                    >
                        Labels and Definitions
                    </a>
                    <a routerLink="/users" class="dropdown-item"> Users </a>
                </div>
            </li>
            <li
                class="nav-item dropdown"
                routerLinkActive="active"
                *ngIf="isAdministrator() || isReadOnly()"
            >
                <a
                    href="#"
                    class="nav-link dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    Reports
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a routerLink="/pooling-reports" class="dropdown-item">
                        Pooling Reports
                    </a>
                    <a routerLink="/nitrogen-reports" class="dropdown-item">
                        Nitrogen Reports
                    </a>
                    <a routerLink="/reports" class="dropdown-item">
                        Manage Report Templates
                    </a>
                    <hr />
                    <a routerLink="/nednr-annual-report" class="dropdown-item">
                        NeDNR Annual Report
                    </a>
                </div>
            </li>
            <li class="nav-item dropdown" routerLinkActive="active">
                <a
                    href="#"
                    class="nav-link dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    Learn More
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a routerLink="/platform-overview" class="dropdown-item">
                        Platform Overview
                    </a>
                    <a routerLink="/training" class="dropdown-item">
                        Training
                    </a>
                    <div class="dropdown-divider"></div>
                    <a routerLink="/disclaimer/false" class="dropdown-item">
                        Disclaimer
                    </a>
                </div>
            </li>
        </ul>
        <ul class="navbar-nav flex-column mt-2 mt-lg-0 me-sm-2">
            <li
                class="nav-item"
                routerLinkActive="active"
                *ngIf="!isAuthenticated()"
            >
                <a
                    href="javascript:void(0);"
                    (click)="login()"
                    routerLinkActive="active"
                    class="nav-link"
                    >Sign In</a
                >
            </li>
            <li
                class="nav-item dropdown welcomeUser"
                routerLinkActive="active"
                *ngIf="isAuthenticated()"
            >
                <a
                    href="#"
                    class="nav-link dropdown-toggle pe-3"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    Welcome {{ getUserName() }}
                </a>
                <div
                    class="dropdown-menu dropdown-menu-right"
                    aria-labelledby="navbarDropdown"
                >
                    <a routerLink="/help" class="dropdown-item"> Get Help </a>
                    <a
                        href="javascript:void(0);"
                        (click)="logout()"
                        class="dropdown-item"
                        >Sign Out</a
                    >
                </div>
            </li>
        </ul>
    </div>
</nav>
