import { Component, OnInit, Input } from '@angular/core';
import { AllocationPlanDto } from 'src/app/shared/generated/model/allocation-plan-dto';
import { PoolDto } from 'src/app/shared/generated/model/pool-dto';
import { PoolingAgreementSummaryDto } from 'src/app/shared/generated/model/pooling-agreement-summary-dto';
import { TractWithAccountDto } from 'src/app/shared/generated/model/tract-with-account-dto';
import { WellDto } from 'src/app/shared/generated/model/well-dto';
import { WithdrawalSimpleDto } from 'src/app/shared/generated/model/withdrawal-simple-dto';

@Component({
  selector: 'splash-water-budget-overview',
  templateUrl: './water-budget-overview.component.html',
  styleUrls: ['./water-budget-overview.component.scss'],
})
export class WaterBudgetOverviewComponent implements OnInit {
  @Input() tracts: Array<TractWithAccountDto>;
  @Input() public wells: Array<WellDto>;
  @Input() public unitsShown: string = 'ac-in';
  @Input() public withdrawals: WithdrawalSimpleDto[];
  @Input() public pool: PoolDto;

  @Input()
  public allocationPlanToDisplay: AllocationPlanDto;
  @Input()
  public poolingAgreements: PoolingAgreementSummaryDto[];
  public showAllocationDetails: boolean;
  public showAcresManagedDetails: boolean;
  public showPoolingAgreementDetails: boolean;

  constructor() {}

  ngOnInit(): void {}

  public getTotalTractAcreage(): number {
    return this.tracts.map((x) => x.FinalAcres).reduce((x, y) => x + y, 0);
  }

  public getWaterUsageToDate(): number {
    return (
      this.withdrawals
      // since the placeholder "All Years" plan has StartYear = 0 and Duration = Infinity, this works.
        .filter(
          (x) =>
            x.WithdrawalYear >=
                            this.allocationPlanToDisplay.StartYear &&
                        x.WithdrawalYear <
                            this.allocationPlanToDisplay.StartYear +
                                this.allocationPlanToDisplay.Duration,
        )
        .map((x) => x.TotalAcreInches)
        .reduce((x, y) => x + y, 0)
    );
  }

  public getAnnualUsage(): number {
    // have to check if real allocation plan because the placeholder object for "All Years" has an Infinity Duration
    const numberOfYears = this.isRealAllocationPlan()
      ? this.allocationPlanToDisplay.Duration
      : new Set(this.withdrawals.map((x) => x.WithdrawalYear)).size;
    return this.getWaterUsageToDate() / numberOfYears;
  }

  public getResultInUnitsShown(result): number {
    if (this.unitsShown === 'ac-in / ac') {
      return result / this.getTotalTractAcreage();
    } else {
      return result;
    }
  }

  public getTractAllocationInUnitsShown(tract: TractWithAccountDto): number {
    if (this.unitsShown === 'ac-in / ac') {
      return this.allocationPlanToDisplay.AllocationVolume;
    } else {
      return (
        tract.FinalAcres * this.allocationPlanToDisplay.AllocationVolume
      );
    }
  }

  public isRealAllocationPlan(): boolean {
    return this.allocationPlanToDisplay.AllocationPlanID != -1;
  }

  public getAllocation(): number {
    return (
      this.getTotalTractAcreage() *
            this.allocationPlanToDisplay.AllocationVolume
    );
  }

  public getTotalAvailable(): number {
    return (
      this.getAllocation() - this.getSentWater() + this.getReceivedWater()
    );
  }

  getReceivedWater(): number {
    return this.poolingAgreements
      .filter(
        (x) =>
          x.ReceivingPoolID == this.pool.PoolID &&
                    x.AllocationPlanID ==
                        this.allocationPlanToDisplay.AllocationPlanID,
      )
      .map((x) => x.TransferVolume)
      .reduce((x, y) => x + y, 0);
  }

  getSentWater(): number {
    return this.poolingAgreements
      .filter(
        (x) =>
          x.SendingPoolID == this.pool.PoolID &&
                    x.AllocationPlanID ==
                        this.allocationPlanToDisplay.AllocationPlanID,
      )
      .map((x) => x.TransferVolume)
      .reduce((x, y) => x + y, 0);
  }

  public getFilteredPoolingAgreements(): PoolingAgreementSummaryDto[] {
    if (this.allocationPlanToDisplay.AllocationPlanID === -1) {
      return this.poolingAgreements;
    }
    return this.poolingAgreements.filter(
      (x) =>
        x.AllocationPlanID ==
                this.allocationPlanToDisplay.AllocationPlanID,
    );
  }

  public getAllocationContributionFromPoolingAgreement(
    poolingAgreement: PoolingAgreementSummaryDto,
  ): number {
    if (poolingAgreement.SendingPoolID === this.pool.PoolID) {
      return 0 - poolingAgreement.TransferVolume;
    }
    return poolingAgreement.TransferVolume;
  }

  public getPoolingAgreementWater(): number {
    return this.getFilteredPoolingAgreements()
      .map((x) => this.getAllocationContributionFromPoolingAgreement(x))
      .reduce((x, y) => x + y, 0);
  }
}
