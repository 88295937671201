/**
 * Splash.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';

import { Observable } from 'rxjs';

import { AccountDto } from '../model/account-dto';
import { AccountUpdateDto } from '../model/account-update-dto';
import { PoolSimpleDto } from '../model/pool-simple-dto';
import { TractAccountDto } from '../model/tract-account-dto';

import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  protected basePath = 'http://localhost';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    private apiService: ApiService,
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
     *
     *
     * @param accountID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public accountsAccountIDGet(
    accountID: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<AccountDto>;
  public accountsAccountIDGet(
    accountID: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<AccountDto>>;
  public accountsAccountIDGet(
    accountID: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<AccountDto>>;
  public accountsAccountIDGet(
    accountID: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (accountID === null || accountID === undefined) {
      throw new Error(
        'Required parameter accountID was null or undefined when calling accountsAccountIDGet.',
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient
      .get<AccountDto>(
      `${this.basePath}/accounts/${encodeURIComponent(String(accountID))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    )
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }

  /**
     *
     *
     * @param accountID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public accountsAccountIDPoolsGet(
    accountID: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<Array<PoolSimpleDto>>;
  public accountsAccountIDPoolsGet(
    accountID: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Array<PoolSimpleDto>>>;
  public accountsAccountIDPoolsGet(
    accountID: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Array<PoolSimpleDto>>>;
  public accountsAccountIDPoolsGet(
    accountID: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (accountID === null || accountID === undefined) {
      throw new Error(
        'Required parameter accountID was null or undefined when calling accountsAccountIDPoolsGet.',
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient
      .get<Array<PoolSimpleDto>>(
      `${this.basePath}/accounts/${encodeURIComponent(String(accountID))}/pools`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    )
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }

  /**
     *
     *
     * @param accountID
     * @param accountUpdateDto
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public accountsAccountIDPut(
    accountID: number,
    accountUpdateDto?: AccountUpdateDto,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<AccountDto>;
  public accountsAccountIDPut(
    accountID: number,
    accountUpdateDto?: AccountUpdateDto,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<AccountDto>>;
  public accountsAccountIDPut(
    accountID: number,
    accountUpdateDto?: AccountUpdateDto,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<AccountDto>>;
  public accountsAccountIDPut(
    accountID: number,
    accountUpdateDto?: AccountUpdateDto,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (accountID === null || accountID === undefined) {
      throw new Error(
        'Required parameter accountID was null or undefined when calling accountsAccountIDPut.',
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    const httpContentTypeSelected: string | undefined =
            this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient
      .put<AccountDto>(
      `${this.basePath}/accounts/${encodeURIComponent(String(accountID))}`,
      accountUpdateDto,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    )
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }

  /**
     *
     *
     * @param accountID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public accountsAccountIDTractsGet(
    accountID: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<Array<TractAccountDto>>;
  public accountsAccountIDTractsGet(
    accountID: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Array<TractAccountDto>>>;
  public accountsAccountIDTractsGet(
    accountID: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Array<TractAccountDto>>>;
  public accountsAccountIDTractsGet(
    accountID: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (accountID === null || accountID === undefined) {
      throw new Error(
        'Required parameter accountID was null or undefined when calling accountsAccountIDTractsGet.',
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient
      .get<Array<TractAccountDto>>(
      `${this.basePath}/accounts/${encodeURIComponent(String(accountID))}/tracts`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    )
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }

  /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public accountsGet(
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<Array<AccountDto>>;
  public accountsGet(
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Array<AccountDto>>>;
  public accountsGet(
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Array<AccountDto>>>;
  public accountsGet(
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient
      .get<Array<AccountDto>>(`${this.basePath}/accounts`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    })
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }

  /**
     *
     *
     * @param accountUpdateDto
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public accountsPost(
    accountUpdateDto?: AccountUpdateDto,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<AccountDto>;
  public accountsPost(
    accountUpdateDto?: AccountUpdateDto,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<AccountDto>>;
  public accountsPost(
    accountUpdateDto?: AccountUpdateDto,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<AccountDto>>;
  public accountsPost(
    accountUpdateDto?: AccountUpdateDto,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    const httpContentTypeSelected: string | undefined =
            this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient
      .post<AccountDto>(`${this.basePath}/accounts`, accountUpdateDto, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    })
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }
}
