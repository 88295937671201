import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FontAwesomeIconLinkRendererComponent } from 'src/app/shared/components/ag-grid/fontawesome-icon-link-renderer/fontawesome-icon-link-renderer.component';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { environment } from 'src/environments/environment';
import { AllocationPlanService } from 'src/app/shared/generated/api/allocation-plan.service';
import { AllocationPlanDto } from 'src/app/shared/generated/model/allocation-plan-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { ColDef } from 'ag-grid-community';
import { UtilityFunctionsService } from 'src/app/services/utility-functions.service';

@Component({
  selector: 'splash-allocation-plan-list',
  templateUrl: './allocation-plan-list.component.html',
  styleUrls: ['./allocation-plan-list.component.scss'],
})
export class AllocationPlanListComponent implements OnInit {
  @ViewChild('allocationPlansGrid') allocationPlansGrid: AgGridAngular;
  @ViewChild('upsertAllocationPlan') upsertEntity: any;
  @ViewChild('deleteAllocationPlan') deleteEntity: any;
  @ViewChild('inactivateAllocationPlan') inactivateEntity: any;

  private currentUser: UserDto;

  public richTextTypeID: number = CustomRichTextTypeEnum.AllocationPlan;
  public allocationPlans: AllocationPlanDto[];
  public activeAllocationPlans: AllocationPlanDto[];
  public columnDefs: ColDef[];
  public defaultColDef: ColDef;

  public isPerformingAction: boolean = false;
  public modalReference: NgbModalRef;
  public closeResult: string;
  public allocationPlanID: number = -1;

  public upsertForm = new UntypedFormGroup({
    startYear: new UntypedFormControl('', [Validators.required]),
    allocationVolume: new UntypedFormControl('', [
      Validators.required,
      Validators.min(0),
    ]),
    duration: new UntypedFormControl('', [
      Validators.required,
      Validators.min(1),
    ]),
    notes: new UntypedFormControl(''),
    activatePlan: new UntypedFormControl(''),
  });

  public currentYear: number = new Date().getUTCFullYear();
  public startYear: number = this.currentYear;
  public allocationVolume: any;
  public duration: any;
  public activatePlan: boolean = false;
  public availableYears: Array<number>;
  public notes: any;
  public errorMessage: string = null;
  public remainingText: number = 0;
  public numPoolingAgreements: any;

  constructor(
    private cdr: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private allocationPlanService: AllocationPlanService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private utilityFunctionsService: UtilityFunctionsService,
  ) {}

  ngOnInit() {
    this.authenticationService.getCurrentUser().subscribe((currentUser) => {
      this.currentUser = currentUser;

      this.updateGridData();
      this.availableYears = this.populateYears();

      this.columnDefs = [
        {
          cellRenderer: FontAwesomeIconLinkRendererComponent,
          cellRendererParams: {
            isSpan: true,
            fontawesomeIconName: 'trash',
          },
          sortable: false,
          filter: false,
          width: 35,
          hide: !this.isCurrentUserAdministrator(),
        },
        {
          cellRenderer: FontAwesomeIconLinkRendererComponent,
          cellRendererParams: {
            isSpan: true,
            fontawesomeIconName: 'pencil-square-o',
          },
          sortable: false,
          filter: false,
          width: 38,
          hide: !this.isCurrentUserAdministrator(),
        },
        { headerName: 'Start Year', field: 'StartYear', width: 95 },
        {
          headerName: 'Allocation Volume (ac-in)',
          field: 'AllocationVolume',
          width: 180,
        },
        {
          headerName: 'Duration (years)',
          field: 'Duration',
          width: 130,
        },
        {
          headerName: 'Last Updated By',
          field: 'LastUpdatedByUser',
          valueGetter: (params) =>
            params.data.LastUpdatedByUser.FullName,
          width: 140,
        },
        this.utilityFunctionsService.createDateColumnDef(
          'Last Updated Date',
          'LastUpdatedDate',
          'M/d/yyyy',
          145,
        ),
        {
          headerName: 'Notes',
          field: 'Notes',
          sortable: true,
          filter: true,
          width: 300,
        },
      ];

      this.defaultColDef = {
        sortable: true,
        filter: true,
        resizable: true,
      };
    });
  }

  get f() {
    return this.upsertForm.controls;
  }

  public populateYears(): Array<number> {
    const currentYear = new Date().getUTCFullYear();
    const yearArray = new Array<number>();
    for (let i = -5; i < 11; i++) {
      yearArray.push(currentYear + i);
    }
    return yearArray;
  }

  ngOnDestroy() {
    this.cdr.detach();
  }

  public updateGridData() {
    this.allocationPlanService.allocationPlansGet().subscribe((result) => {
      this.allocationPlans = result.filter((x) => x.IsActive == false);
      this.activeAllocationPlans = result.filter(
        (x) => x.IsActive == true,
      );

      this.allocationPlansGrid.api.setRowData(this.allocationPlans);
      this.cdr.detectChanges();
    });
  }

  public launchModal(modalContent: any, modalTitle: string) {
    this.modalReference = this.modalService.open(modalContent, {
      windowClass: 'allocation-plan-modal',
      ariaLabelledBy: modalTitle,
      beforeDismiss: () => this.checkIfSubmitting(),
      backdrop: 'static',
      keyboard: false,
    });
    this.modalReference.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed`;
      },
    );
  }

  public openModalForNewAllocationPlan() {
    this.clearUpsertFormAndResetAllocationPlanID();
    this.startYear = new Date().getUTCFullYear();
    this.upsertForm.patchValue({ startYear: this.startYear });
    this.upsertForm.patchValue({ activatePlan: false });
    this.launchModal(this.upsertEntity, 'upsertModalTitle');
  }

  public openModalForInactivateAllocationPlan(id: number) {
    this.allocationPlanID = id;
    this.launchModal(this.inactivateEntity, 'inactivateAllocationPlan');
  }

  public onCellClicked(event: any) {
    if (event.column.colId != '0' && event.column.colId != '1') {
      return null;
    }

    this.clearUpsertFormAndResetAllocationPlanID();
    const data = event.data;
    this.allocationPlanID = data.AllocationPlanID;
    this.startYear = data.StartYear;
    this.allocationVolume = data.AllocationVolume;
    this.duration = data.Duration;
    this.notes = data.Notes;
    this.numPoolingAgreements = data.NumPoolingAgreements;

    if (event.column.colId == '0') {
      this.launchModal(this.deleteEntity, 'deleteAllocationPlan');
    } else {
      this.upsertForm.patchValue({ startYear: this.startYear });
      this.upsertForm.patchValue({
        allocationVolume: this.allocationVolume,
      });
      this.upsertForm.patchValue({ duration: this.duration });
      this.upsertForm.patchValue({ notes: this.notes });
      this.upsertForm.patchValue({ activatePlan: false });
      this.launchModal(this.upsertEntity, 'upsertModalTitle');
    }
  }

  public checkIfSubmitting() {
    return !this.isPerformingAction;
  }

  public clearUpsertFormAndResetAllocationPlanID() {
    this.upsertForm.reset();
    this.upsertForm.enable();
    this.errorMessage = null;
    this.allocationPlanID = environment.primaryKeyForEntityToBeCreated;
    this.remainingText = 0;
    this.upsertForm.get('notes').valueChanges.subscribe((val) => {
      this.remainingText = val?.length ?? 0;
    });
  }

  onSubmit() {
    this.errorMessage = null;
    if (this.upsertForm.valid) {
      const upsertDto = new AllocationPlanDto({
        AllocationPlanID: this.allocationPlanID,
        StartYear: this.upsertForm.get('startYear').value,
        AllocationVolume: this.upsertForm.get('allocationVolume').value,
        Duration: this.upsertForm.get('duration').value,
        Notes: this.upsertForm.get('notes').value,
        IsActive: this.upsertForm.get('activatePlan').value,
      });
      this.isPerformingAction = true;
      this.allocationPlanService.allocationPlansPost(upsertDto).subscribe(
        (result) => {
          this.modalReference.close();
          this.isPerformingAction = false;
          this.alertService.pushAlert(
            new Alert(
              `Successfully ${this.allocationPlanID != environment.primaryKeyForEntityToBeCreated ? 'updated' : 'created'} allocation plan`,
              AlertContext.Success,
              true,
            ),
          );
          this.updateGridData();
        },
        (error) => {
          this.errorMessage =
                        error.error['Modal Error'] ??
                        `There was an error while trying to ${this.allocationPlanID != environment.primaryKeyForEntityToBeCreated ? 'update' : 'create'} the allocation plan. Please try again.`;
          this.isPerformingAction = false;
        },
      );
    } else {
      Object.keys(this.upsertForm.controls).forEach((field) => {
        const control = this.upsertForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  public onDelete() {
    this.isPerformingAction = true;
    this.allocationPlanService
      .allocationPlanAllocationPlanIDDelete(this.allocationPlanID)
      .subscribe(
        (result) => {
          this.modalReference.close();
          this.isPerformingAction = false;
          this.alertService.pushAlert(
            new Alert(
              `Successfully deleted Allocation Plan and all associated Pooling Agreements.`,
              AlertContext.Success,
              true,
            ),
          );
          this.updateGridData();
        },
        (error) => {
          this.modalReference.close();
          this.isPerformingAction = false;
          this.alertService.pushAlert(
            new Alert(
              `There was an error deleting the Allocation Plan. Please try again`,
              AlertContext.Danger,
              true,
            ),
          );
        },
      );
  }

  public onInactivate() {
    this.isPerformingAction = true;
    this.allocationPlanService
      .allocationPlanAllocationPlanIDDeactivatePut(this.allocationPlanID)
      .subscribe(
        (result) => {
          this.modalReference.close();
          this.isPerformingAction = false;
          this.alertService.pushAlert(
            new Alert(
              `Successfully inactivated Allocation Plan`,
              AlertContext.Success,
              true,
            ),
          );
          this.updateGridData();
        },
        (error) => {
          this.modalReference.close();
          this.isPerformingAction = false;
          this.alertService.pushAlert(
            new Alert(
              `There was an error inactivating the Allocation Plan. Please try again`,
              AlertContext.Danger,
              true,
            ),
          );
        },
      );
  }

  public catchExtraSymbols(event: KeyboardEvent): void {
    if (
      event.code === 'KeyE' ||
            event.code === 'Equal' ||
            event.code === 'Minus' ||
            event.code === 'Plus'
    ) {
      event.preventDefault();
    }
  }

  public catchPastedSymbols(event: any): void {
    let val = event.clipboardData.getData('text/plain');
    if (
      val &&
            (val.includes('+') ||
                val.includes('-') ||
                val.includes('e') ||
                val.includes('E'))
    ) {
      val = val.replace(/\+|\-|e|E/g, '');
      event.preventDefault();
    }
  }

  public isCurrentUserAdministrator(): boolean {
    return this.authenticationService.isUserAnAdministrator(
      this.currentUser,
    );
  }

  public getNumPoolingAgreementsStatement(): string {
    if (this.numPoolingAgreements == 0) {
      return 'There are no Pooling Agreements associated with this Allocation Plan.';
    }

    return `${this.numPoolingAgreements} associated Pooling ${this.numPoolingAgreements == 1 ? 'Agreement' : 'Agreements'} will also be deleted.`;
  }
}
