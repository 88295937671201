import { Component, Input } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';

@Component({
  selector: 'clear-grid-filters-button',
  templateUrl: './clear-grid-filters-button.component.html',
  styleUrls: ['./clear-grid-filters-button.component.scss'],
})
export class ClearGridFiltersButtonComponent {
  @Input() grid: AgGridAngular;

  public clearFilters() {
    this.grid.api.setFilterModel(null);
  }

  public isFilterActive(): boolean {
    if (!this.grid || !this.grid.api) return false;

    return this.grid.api.isAnyFilterPresent();
  }
}
