import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { PoolingAgreementWorkflowStep } from '../pooling-agreement-workflow-step.interface';
import { TractMapComponent } from 'src/app/shared/components/tract-map/tract-map.component';
import { WfsService } from 'src/app/shared/services/wfs.service';
import { FeatureCollection, Feature } from 'geojson';
import { LeafletMouseEvent } from 'leaflet';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PoolService } from 'src/app/shared/generated/api/pool.service';
import { TractWithAccountDto } from 'src/app/shared/generated/model/tract-with-account-dto';
import { PoolingAgreementDto } from 'src/app/shared/generated/model/pooling-agreement-dto';
import { PoolUsageSummaryDto } from 'src/app/shared/generated/model/pool-usage-summary-dto';
import { PoolingAgreementTractService } from 'src/app/shared/generated/api/pooling-agreement-tract.service';
import { PoolingAgreementTractTypeEnum } from 'src/app/shared/generated/model/pooling-agreement-tract-type-enum';
import { TractAvailableWaterDto } from 'src/app/shared/generated/model/tract-available-water-dto';
import { PoolingAgreementTractUpsertDto } from 'src/app/shared/generated/model/pooling-agreement-tract-upsert-dto';

@Component({
  selector: 'splash-pooling-agreement-workflow-tracts',
  templateUrl: './pooling-agreement-workflow-tracts.component.html',
  styleUrls: ['./pooling-agreement-workflow-tracts.component.scss'],
})
export class PoolingAgreementWorkflowTractsComponent
implements OnInit, OnDestroy, PoolingAgreementWorkflowStep
{
  @Input()
    poolingAgreementObservable: Observable<PoolingAgreementDto>;
  private poolingAgreementSubscription: Subscription;
  public poolingAgreement: PoolingAgreementDto;

  @Input()
  public poolingAgreementTractType: PoolingAgreementTractTypeEnum;
  public PoolingAgreementTractTypeEnum = PoolingAgreementTractTypeEnum;

  @Input() public defaultExtent: any;
  @ViewChild('tractsMap') public tractMap: TractMapComponent;
  @ViewChild('tractDetailsModal') tractDetailsModal: any;

  public modalReference: NgbModalRef;
  public closeResult: string;

  public selectedTractLayerName: string = 'Selected Tracts';
  public visibleTractIDs: number[] = [];
  public isLoadingSubmit: boolean = false;
  public moreDetailsTract: TractWithAccountDto;

  public visibleTracts: TractWithAccountDto[] = [];
  // This boxing allows us to work with one DTO type throughout for the cost of an additional dereference,
  // which is preferable to creating a new DTO with read-only and writeable properties.
  // These can be converted to PoolingAgreementTractUpsertDtos at the last minute.
  public selectedTracts: SelectedTractDto[] = [];

  public poolUsageSummary: PoolUsageSummaryDto;
  public poolID: number;
  public poolName: string;
  public tractAvailableWaters: TractAvailableWaterDto;

  constructor(
    private wfsService: WfsService,
    private poolService: PoolService,
    private cdr: ChangeDetectorRef,
    private poolingAgreementTractService: PoolingAgreementTractService,
    private modalService: NgbModal,
  ) {}

  public ngOnInit(): void {
    this.visibleTractIDs = this.visibleTracts.map((p) => p.TractID);

    this.poolingAgreementSubscription =
            this.poolingAgreementObservable.subscribe((x) => {
              this.poolingAgreement = x;
              this.poolID =
                    this.poolingAgreementTractType ===
                    PoolingAgreementTractTypeEnum.Sending
                      ? x.SendingPool.PoolID
                      : x.ReceivingPool.PoolID;
              this.poolName =
                    this.poolingAgreementTractType ===
                    PoolingAgreementTractTypeEnum.Sending
                      ? x.SendingPool.PoolName
                      : x.ReceivingPool.PoolName;

              forkJoin({
                tracts: this.poolService.poolsPoolIDTractsGet(this.poolID),
                poolUsageSummary:
                        this.poolService.poolsPoolIDUsageSummaryAllocationPlanIDGet(
                          this.poolID,
                          x.AllocationPlan.AllocationPlanID,
                        ),
                poolingAgreementTracts:
                        this.poolingAgreementTractService.poolingAgreementsPoolingAgreementIDTractTypesPoolingAgreementTractTypeTractsGet(
                          x.PoolingAgreementID,
                          this.poolingAgreementTractType,
                        ),
                tractAvailableWaters:
                        this.poolingAgreementTractService.poolingAgreementsPoolingAgreementIDTractTypePoolingAgreementTractTypeTractsAvailableWaterGet(
                          x.PoolingAgreementID,
                          this.poolingAgreementTractType,
                        ),
              }).subscribe(
                ({
                  tracts,
                  poolUsageSummary,
                  poolingAgreementTracts,
                  tractAvailableWaters,
                }) => {
                  this.visibleTracts = tracts;
                  this.visibleTractIDs = tracts.map((x) => x.TractID);
                  this.cdr.detectChanges();
                  this.tractMap.prepareVisibleTractsLayer();
                  this.poolUsageSummary = poolUsageSummary;
                  this.selectedTracts = poolingAgreementTracts.map(
                    (x) => {
                      const visibleTract = this.visibleTracts.find(
                        (y) => y.TractID == x.TractID,
                      );
                      return {
                        tract: visibleTract,
                        AcreInchesTransferred: x.TransferVolume,
                      };
                    },
                  );

                  this.tractAvailableWaters = tractAvailableWaters.reduce(
                    (x, y) => {
                      x[y.TractID] = y.AvailableWater;
                      return x;
                    },
                    {},
                  );

                  this.cdr.detectChanges();
                  this.tractMap.updateSelectedTractsOverlayLayer(
                    this.getSelectedTractIDs(),
                  );
                },
              );
            });
  }

  public ngAfterViewInit(): void {
    const self = this;
    this.tractMap.map.on('click', (event: LeafletMouseEvent): void => {
      self.wfsService
        .getTractByCoordinate(event.latlng.lng, event.latlng.lat)
        .subscribe((tractFeatureCollection: FeatureCollection) => {
          tractFeatureCollection.features.forEach(
            (feature: Feature) => {
              self.toggleTract.bind(self)(feature);
            },
          );
        });
    });
  }

  ngOnDestroy(): void {
    this.poolingAgreementSubscription?.unsubscribe();
  }

  public stepTitle() {
    const poolNameTag = this.poolName ? ` (${this.poolName})` : '';
    if (
      this.poolingAgreementTractType ===
            PoolingAgreementTractTypeEnum.Sending
    ) {
      return `Sending Pool Details${poolNameTag}`;
    } else return `Receiving Pool details${poolNameTag}`;
  }

  public getSelectedTractIDs(): Array<number> {
    return this.selectedTracts !== undefined
      ? this.selectedTracts.map((p) => p.tract.TractID)
      : [];
  }

  public getTotalTractAreaAcres(): number {
    if (this.selectedTracts.length == 0) return 0;

    const result = this.selectedTracts.reduce(function (a, b) {
      const acreFeet = b.tract.FinalAcres ?? 0;
      return a + acreFeet;
    }, 0);

    return result;
  }

  public toggleTract(feature: Feature): void {
    const selectedTractID = feature.properties.TractID;
    if (this.visibleTractIDs.includes(selectedTractID)) {
      if (!this.removeTractIfSelected(selectedTractID)) {
        const tractToAdd = this.visibleTracts.find(
          (x) => x.TractID == feature.properties.TractID,
        );
        const selectedTract = new SelectedTractDto({
          tract: tractToAdd,
          AcreInchesTransferred: 0,
        });
        this.selectedTracts.push(selectedTract);
      }
      this.updateSelectedTractLayer();
    }
  }

  public removeTract(tractIDToRemove: number): void {
    this.removeTractIfSelected(tractIDToRemove);
    this.updateSelectedTractLayer();
  }

  public removeAllTracts(): void {
    this.selectedTracts = [];
    this.updateSelectedTractLayer();
  }

  public selectAllTracts(): void {
    this.selectedTracts = this.visibleTracts.map((x) => {
      let acreInchesTransferred =
                this.getTractWeight(x) * this.poolingAgreement.TransferVolume;
      acreInchesTransferred = parseFloat(
        acreInchesTransferred.toFixed(1),
      );
      return { tract: x, AcreInchesTransferred: acreInchesTransferred };
    });

    // make sure they sum to the transfer volume
    this.selectedTracts[0].AcreInchesTransferred +=
            this.getRemainingTransferVolume();

    this.updateSelectedTractLayer();
  }

  private removeTractIfSelected(tractIDToRemove: number): boolean {
    const selectedTractIndex = this.selectedTracts.findIndex(
      (t) => t.tract.TractID === tractIDToRemove,
    );
    if (selectedTractIndex !== -1) {
      this.selectedTracts.splice(selectedTractIndex, 1);
      return true;
    }
    return false;
  }

  private updateSelectedTractLayer() {
    this.tractMap.updateSelectedTractsOverlayLayer(
      this.getSelectedTractIDs(),
    );
  }

  public getTractAllocationForAllocationPeriod(
    tract: TractWithAccountDto,
  ): number {
    return (
      (tract.FinalAcres ?? 0) *
            this.poolingAgreement.AllocationPlan.AllocationVolume
    );
  }

  public getTractRemainingWaterForAllocationPeriod(
    tract: TractWithAccountDto,
  ): number {
    return this.tractAvailableWaters[tract.TractID];
  }

  public getTractWeight(tract: TractWithAccountDto): number {
    return tract.FinalAcres / this.poolUsageSummary.PoolAcres;
  }

  public getRemainingTransferVolume(): number {
    return (
      this.poolingAgreement.TransferVolume -
            this.selectedTracts
              .map((x) => x.AcreInchesTransferred)
              .reduce((x, y) => x + y, 0)
    );
  }

  //To account for floating point errors we need to check based on a tolerance
  public getRemainingTransferVolumeWithinTolerance(): boolean {
    return this.getRemainingTransferVolume() < 0.0001;
  }

  public trackByFunction(index, item) {
    if (!item) return null;
    return index;
  }

  isTractInvalid(tract: SelectedTractDto): boolean {
    return tract.AcreInchesTransferred <= 0;
  }

  isDirty(): boolean {
    return false;
  }

  isValid(): boolean {
    const areTractsSelected = this.selectedTracts?.length > 0;
    const areAllTransferVolumesPositive =
            this.selectedTracts.filter((x) => x.AcreInchesTransferred <= 0)
              .length === 0;
    const mathChecksOut = this.getRemainingTransferVolume() === 0;
    const allTransferVolumesAreValid =
            this.poolingAgreementTractType !=
                PoolingAgreementTractTypeEnum.Sending ||
            this.allTransferVolumesAreValid();

    return (
      areTractsSelected &&
            areAllTransferVolumesPositive &&
            mathChecksOut &&
            allTransferVolumesAreValid
    );
  }

  allTransferVolumesAreValid(): boolean {
    return (
      this.selectedTracts.filter(
        (x) => !this.tractTransferVolumeIsValid(x),
      ).length == 0
    );
  }

  tractTransferVolumeIsValid(tract: SelectedTractDto): boolean {
    return (
      tract.AcreInchesTransferred <=
            this.tractAvailableWaters[tract.tract.TractID]
    );
  }

  getDto(): PoolingAgreementTractUpsertDto[] {
    return this.selectedTracts.map(
      (x) =>
        new PoolingAgreementTractUpsertDto({
          PoolingAgreementID:
                        this.poolingAgreement.PoolingAgreementID,
          PoolID: this.poolID,
          TractID: x.tract.TractID,
          TransferVolume: x.AcreInchesTransferred,
        }),
    );
  }

  public launchModal(modalContent: any, modalTitle: string) {
    this.modalReference = this.modalService.open(modalContent, {
      windowClass: 'tract-details-modal',
      ariaLabelledBy: modalTitle,
      backdrop: 'static',
      keyboard: false,
    });
    this.modalReference.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed`;
      },
    );
  }

  public openTractDetailsModal(tract: TractWithAccountDto) {
    this.moreDetailsTract = tract;
    this.launchModal(this.tractDetailsModal, 'tractDetailsModalTitle');
  }
}

class SelectedTractDto {
  tract: TractWithAccountDto;
  AcreInchesTransferred?: number;

  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}
