import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { forkJoin } from 'rxjs';
import { PhaseReportService } from 'src/app/shared/generated/api/phase-report.service';
import { PhaseReportSimpleDto } from 'src/app/shared/generated/model/phase-report-simple-dto';
import { PracticeSimpleDto } from 'src/app/shared/generated/model/practice-simple-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';

@Component({
  selector: 'splash-phase-report-detail',
  templateUrl: './phase-report-detail.component.html',
  styleUrls: ['./phase-report-detail.component.scss'],
})
export class PhaseReportDetailComponent implements OnInit, OnDestroy {
  private currentUser: UserDto;

  public phaseReport: PhaseReportSimpleDto;
  public phaseReportPractices: PracticeSimpleDto[];

  public today: Date = new Date();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private phaseReportService: PhaseReportService,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
  ) {
    // force route reload whenever params change;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    this.authenticationService.getCurrentUser().subscribe((currentUser) => {
      this.currentUser = currentUser;

      const id = parseInt(this.route.snapshot.paramMap.get('id'));
      if (id) {
        forkJoin({
          phaseReport:
                        this.phaseReportService.phaseReportsPhaseReportIDGet(
                          id,
                        ),
          phaseReportPractices:
                        this.phaseReportService.phaseReportsPhaseReportIDPracticesGet(
                          id,
                        ),
        }).subscribe(({ phaseReport, phaseReportPractices }) => {
          this.phaseReport = phaseReport;
          this.phaseReportPractices = phaseReportPractices;
        });
      }
    });
  }

  ngOnDestroy() {
    this.cdr.detach();
  }

  public getSelectedPhaseReportIDs(): Array<number> {
    return this.phaseReport !== undefined
      ? [this.phaseReport.PhaseReportID]
      : [];
  }
}
