import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertService } from '../../services/alert.service';
import { Alert } from '../../models/alert';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-alert-display',
  templateUrl: './alert-display.component.html',
  styleUrls: ['./alert-display.component.css'],
})
export class AlertDisplayComponent implements OnInit, OnDestroy {
  public alerts: Alert[] = [];
  alertObservable: Observable<Alert[]>;
  alertSubscription: Subscription;

  constructor(private alertService: AlertService) {}

  public ngOnInit(): void {
    this.alertObservable = this.alertService.alertSubject.asObservable();
    this.alertSubscription = this.alertObservable.subscribe((alerts) => {
      this.alerts = alerts;
    });
  }

  public ngOnDestroy(): void {
    this.alertSubscription.unsubscribe();
    this.alertService.clearAlerts();
  }

  public closeAlert(alert: Alert) {
    this.alertService.removeAlert(alert);
  }
}
