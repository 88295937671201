<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page">Users</li>
    </ol>
</nav>

<div class="container">
    <h2 class="d-inline-block">All Users</h2>
    <p class="lead">A list of users is shown in the grid below.</p>
</div>
<div class="container mt-sm-4">
    <div class="row mb-2">
        <div class="col-auto ms-auto">
            <clear-grid-filters-button
                [grid]="usersGrid"
            ></clear-grid-filters-button>
            <csv-download-button
                [grid]="usersGrid"
                fileName="users"
                class="mx-2"
            ></csv-download-button>
            <a class="btn btn-splash btn-md" routerLink="/invite-user"
                >Invite User</a
            >
        </div>
    </div>

    <div class="table-responsive">
        <ag-grid-angular
            #usersGrid
            style="width: 100%; height: 800px"
            class="ag-theme-balham"
            [rowData]="users"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [pagination]="true"
            [paginationPageSize]="100"
            [suppressMenuHide]="true"
        >
        </ag-grid-angular>
    </div>
</div>

<div class="container">
    <app-alert-display></app-alert-display>
    <h2 class="d-inline-block">Unassigned Users</h2>
    <p class="lead" *ngIf="users && unassignedUsers.length">
        This is a list of all users who are awaiting account configuration.
        Assign the user a role and billing account(s). If the account was
        created in error, set their Role to “Disabled” to remove them from this
        list.
    </p>
    <p class="lead" *ngIf="users && !unassignedUsers.length">
        Congratulations! All users have been assigned a role.
    </p>
</div>
<div class="container mt-sm-4" *ngIf="users && unassignedUsers.length">
    <div class="row mb-2">
        <div class="col-auto ms-auto">
            <clear-grid-filters-button
                [grid]="unassignedUsersGrid"
            ></clear-grid-filters-button>
        </div>
    </div>

    <div class="table-responsive">
        <ag-grid-angular
            #unassignedUsersGrid
            style="width: 100%; height: 300px"
            class="ag-theme-balham"
            [rowData]="unassignedUsers"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [pagination]="true"
            [paginationPageSize]="100"
            [suppressMenuHide]="true"
        >
        </ag-grid-angular>
    </div>
</div>
