<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <a routerLink="/reports">Manage Report Templates</a>
        </li>
        <li
            *ngIf="reportTemplate == undefined"
            class="breadcrumb-item active"
            aria-current="page"
        >
            New Report Template
        </li>
        <li
            *ngIf="reportTemplate !== undefined"
            class="breadcrumb-item active"
            aria-current="page"
        >
            {{ reportTemplate.DisplayName }}
        </li>
    </ol>
</nav>
<div class="container" *ngIf="model">
    <app-alert-display></app-alert-display>
    <form
        (ngSubmit)="onSubmit(newReportTemplateForm)"
        #newReportTemplateForm="ngForm"
    >
        <div class="row">
            <div class="col">
                <div class="form-group mt-4">
                    <div class="col-sm-5 control-label">
                        <label class="required">Display Name</label>
                    </div>
                    <div class="col-sm-7">
                        <input
                            type="text"
                            class="form-control"
                            name="DisplayName"
                            placeholder="Display Name"
                            required
                            [(ngModel)]="model.DisplayName"
                        />
                    </div>
                </div>
                <div class="form-group mt-4">
                    <div class="col-sm-5 control-label">
                        <label>Description</label>
                    </div>
                    <div class="col-sm-7">
                        <input
                            type="text"
                            class="form-control"
                            name="Description"
                            [(ngModel)]="model.Description"
                        />
                    </div>
                </div>
                <div class="form-group mt-4">
                    <div class="col-sm-5 control-label">
                        <label class="required">Report Template Model</label>
                    </div>
                    <div class="col-sm-7">
                        <select
                            name="ReportTemplateModelID"
                            class="form-control"
                            [(ngModel)]="model.ReportTemplateModelID"
                            required
                        >
                            <option
                                *ngFor="
                                    let reportTemplateModel of reportTemplateModels
                                "
                                [value]="
                                    reportTemplateModel.ReportTemplateModelID
                                "
                            >
                                {{
                                    reportTemplateModel.ReportTemplateModelDisplayName
                                }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-group mt-4">
                    <div class="col-sm-5 control-label">
                        <label
                            class="required"
                            *ngIf="reportTemplateID === undefined"
                            >File</label
                        >
                        <label *ngIf="reportTemplateID !== undefined"
                            >File</label
                        >
                    </div>
                    <div class="input-group">
                        <div class="custom-file">
                            <input
                                type="file"
                                class="custom-file-input form-control"
                                id="fileResource"
                                #fileUpload
                                (change)="fileEvent()"
                            />
                            <label
                                class="custom-file-label"
                                for="fileResource"
                                >{{ getFileName() }}</label
                            >
                        </div>
                        <button
                            class="btn btn-splash"
                            type="button"
                            style="height: 50%"
                            (click)="openFileUpload()"
                        >
                            <i class="fas fa-folder-open"></i>
                        </button>
                    </div>
                    <div class="col">
                        <em class="text-small mt-2"
                            >Allowed extensions: DOCX, DOC. Max file size: 25
                            MB.</em
                        >
                        <em class="text-danger mt-2" *ngIf="displayFileErrors"
                            ><br />File must be one of: DOCX, DOC</em
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-sm"><i class="fas fa-bolt"></i> Required field</div>
            <div class="col-sm text-end">
                <button
                    type="submit"
                    class="btn btn-splash"
                    [disabled]="
                        !newReportTemplateForm.form.valid ||
                        isLoadingSubmit ||
                        !requiredFileIsUploaded ||
                        displayFileErrors
                    "
                >
                    <span
                        *ngIf="isLoadingSubmit"
                        class="fa fa-spinner loading-spinner"
                    ></span>
                    Save
                </button>
                <a class="btn btn-secondary ms-1" routerLink="/reports"
                    >Cancel</a
                >
            </div>
        </div>
    </form>
</div>
