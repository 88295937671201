import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UtilityFunctionsService } from 'src/app/services/utility-functions.service';
import { CustomDropdownFilterComponent } from 'src/app/shared/components/ag-grid/custom-dropdown-filter/custom-dropdown-filter.component';
import { LinkRendererComponent } from 'src/app/shared/components/ag-grid/link-renderer/link-renderer.component';
import { ReportTemplateService } from 'src/app/shared/generated/api/report-template.service';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { ReportTemplateDto } from 'src/app/shared/generated/model/report-template-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'zybach-reports-list',
  templateUrl: './reports-list.component.html',
  styleUrls: ['./reports-list.component.scss'],
})
export class ReportsListComponent implements OnInit, OnDestroy {
  @ViewChild('reportTemplatesGrid') reportTemplatesGrid: AgGridAngular;

  private currentUser: UserDto;

  public reportTemplates: ReportTemplateDto[];
  public columnDefs: ColDef[];
  public defaultColDef: ColDef;
  public richTextTypeID: number = CustomRichTextTypeEnum.ReportsList;

  public selectedReportTemplateID: string;
  public isLoadingSubmit: boolean = false;

  constructor(
    private reportTemplateService: ReportTemplateService,
    private authenticationService: AuthenticationService,
    private utilityFunctionsService: UtilityFunctionsService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.authenticationService.getCurrentUser().subscribe((currentUser) => {
      this.currentUser = currentUser;

      this.reportTemplateService
        .reportTemplatesGet()
        .subscribe((reportTemplates) => {
          this.reportTemplates = reportTemplates;
          this.cdr.detectChanges();
        });

      const mainAppApiUrl = environment.mainAppApiUrl;
      this.columnDefs = [
        {
          headerName: 'Name',
          valueGetter: (params) => {
            return {
              LinkValue: params.data.ReportTemplateID,
              LinkDisplay: params.data.DisplayName,
            };
          },
          cellRenderer: LinkRendererComponent,
          cellRendererParams: { inRouterLink: '/reports/' },
          filterValueGetter: (params) => params.data.DisplayName,
          comparator:
                        this.utilityFunctionsService.linkRendererComparator,
          width: 275,
        },
        { headerName: 'Description', field: 'Description', width: 300 },
        {
          headerName: 'Model',
          field: 'ReportTemplateModel.ReportTemplateModelDisplayName',
          width: 175,
          filter: CustomDropdownFilterComponent,
          filterParams: {
            field: 'ReportTemplateModel.ReportTemplateModelDisplayName',
          },
        },
        {
          headerName: 'Template File',
          valueGetter: function (params: any) {
            return {
              LinkValue: `${mainAppApiUrl}/FileResource/${params.data.FileResource.FileResourceGUID}`,
              LinkDisplay:
                                params.data.FileResource.OriginalBaseFilename,
            };
          },
          cellRenderer: LinkRendererComponent,
          cellRendererParams: { isExternalUrl: true },
          filterValueGetter: (params) =>
            params.data.FileResource.OriginalBaseFilename,
          comparator:
                        this.utilityFunctionsService.linkRendererComparator,
          width: 350,
        },
      ];

      this.columnDefs.forEach((x) => {
        x.resizable = true;
      });
    });
  }

  ngOnDestroy(): void {
    this.cdr.detach();
  }
}
