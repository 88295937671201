import { Component } from '@angular/core';

@Component({
  selector: 'unauthenticated',
  templateUrl: './unauthenticated.component.html',
  styleUrls: ['./unauthenticated.component.scss'],
})
export class UnauthenticatedComponent  {
  constructor() {}

  
}
