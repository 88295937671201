import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { forkJoin, BehaviorSubject } from 'rxjs';
import { AllocationPlanService } from 'src/app/shared/generated/api/allocation-plan.service';
import { AllocationPlanDto } from 'src/app/shared/generated/model/allocation-plan-dto';
import { TractWellDto } from 'src/app/shared/generated/model/tract-well-dto';
import { PoolService } from 'src/app/shared/generated/api/pool.service';
import { AllYearsPlaceholderPlan } from 'src/app/shared/models/allocationPlan/all-years-placeholder-plan';
import { PoolDto } from 'src/app/shared/generated/model/pool-dto';
import { WellDto } from 'src/app/shared/generated/model/well-dto';
import { TractWithAccountDto } from 'src/app/shared/generated/model/tract-with-account-dto';
import { WithdrawalSimpleDto } from 'src/app/shared/generated/model/withdrawal-simple-dto';
import { PoolingAgreementSummaryDto } from 'src/app/shared/generated/model/pooling-agreement-summary-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';

@Component({
  selector: 'splash-pool-detail',
  templateUrl: './pool-detail.component.html',
  styleUrls: ['./pool-detail.component.scss'],
})
export class PoolDetailComponent implements OnInit, OnDestroy {
  private currentUser: UserDto;

  public pool: PoolDto;
  public tracts: TractWithAccountDto[];
  public wells: WellDto[];
  public tractWells: Array<TractWellDto>;
  public unitsShown: string = 'ac-in / ac';
  public unitsShownText: string = 'acre-inches / acre';
  public withdrawals: WithdrawalSimpleDto[];
  public waterUsageByWellViewType: string = 'chart';
  public years: number[];

  public allocationPlans: AllocationPlanDto[];
  public allocationPlanToDisplay: AllocationPlanDto;
  poolingAgreements: PoolingAgreementSummaryDto[];

  public agreementTractsSubject: BehaviorSubject<number[]> =
    new BehaviorSubject<number[]>(null);
  poolID: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private poolService: PoolService,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private allocationPlanService: AllocationPlanService,
  ) {
    // force route reload whenever params change;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    this.authenticationService.getCurrentUser().subscribe((currentUser) => {
      this.currentUser = currentUser;
      const id = parseInt(this.route.snapshot.paramMap.get('id'));
      if (id) {
        this.poolID = id;
        this.allocationPlanService
          .allocationPlansActiveGet()
          .subscribe((allocationPlans) => {
            this.allocationPlans = allocationPlans;
            this.allocationPlanToDisplay =
                            allocationPlans.length > 0
                              ? allocationPlans[0]
                              : AllYearsPlaceholderPlan;
            this.updateAnnualData();
            this.getPoolData(id);
          });
      }
    });
  }

  getPoolData(poolID: number): void {
    forkJoin({
      pool: this.poolService.poolsPoolIDGet(poolID),
      tracts: this.poolService.poolsPoolIDTractsGet(poolID),
      wells: this.poolService.poolsPoolIDWellsGet(poolID),
      tractWells: this.poolService.poolsPoolIDTractWellsGet(poolID),
      withdrawals: this.poolService.poolsPoolIDWithdrawalsGet(poolID),
      poolingAgreements:
                this.poolService.poolsPoolIDPoolingAgreementsGet(poolID),
    }).subscribe(
      ({
        pool,
        tracts,
        wells,
        tractWells,
        withdrawals,
        poolingAgreements,
      }) => {
        this.pool = pool;
        this.tracts = tracts;
        this.wells = wells;
        this.tractWells = tractWells;
        this.withdrawals = withdrawals;
        this.poolingAgreements = poolingAgreements;
      },
    );
  }

  updateAnnualData(): void {
    this.cdr.detectChanges();
    this.poolService
      .poolsPoolIDPoolingAgreementTractIdsAllocationPlanIDGet(
        this.poolID,
        this.allocationPlanToDisplay.AllocationPlanID,
      )
      .subscribe((tractIDs) => {
        this.agreementTractsSubject.next(tractIDs);
      });
  }

  ngOnDestroy() {
    this.cdr.detach();
  }

  public currentUserIsAdmin(): boolean {
    return this.authenticationService.isUserAnAdministrator(
      this.currentUser,
    );
  }

  public getVisibleTracts(): Array<TractWithAccountDto> {
    return this.tracts !== undefined ? this.tracts : [];
  }

  public getVisibleWells(): Array<WellDto> {
    return this.wells !== undefined ? this.wells : [];
  }

  public getFilteredPoolingAgreements(): PoolingAgreementSummaryDto[] {
    if (this.allocationPlanToDisplay.AllocationPlanID === -1) {
      return this.poolingAgreements;
    }
    return this.poolingAgreements.filter(
      (x) =>
        x.AllocationPlanID ==
                this.allocationPlanToDisplay.AllocationPlanID,
    );
  }

  public getWaterUsageToDate(): number {
    return this.withdrawals
      .filter(
        (x) =>
          x.WithdrawalYear >=
                        this.allocationPlanToDisplay.StartYear &&
                    x.WithdrawalYear <
                        this.allocationPlanToDisplay.StartYear +
                            this.allocationPlanToDisplay.Duration,
      )
      .map((x) => this.getWaterUsageInUnitsShown(x))
      .reduce((x, y) => x + y, 0);
  }

  public getTotalWaterUsageForYear(year: number): number {
    return this.withdrawals
      .filter((x) => x.WithdrawalYear === year)
      .map((x) => this.getWaterUsageInUnitsShown(x))
      .reduce((x, y) => x + y, 0);
  }

  public getTotalWaterUsageForWell(well: WellDto): number {
    return this.withdrawals
      .filter(
        (x) =>
          x.WellID === well.WellID &&
                    x.WithdrawalYear >=
                        this.allocationPlanToDisplay.StartYear &&
                    x.WithdrawalYear <
                        this.allocationPlanToDisplay.StartYear +
                            this.allocationPlanToDisplay.Duration,
      )
      .map((x) => this.getWaterUsageInUnitsShown(x))
      .reduce((x, y) => x + y, 0);
  }

  public getWaterUsageForWellAndYear(well: WellDto, year: number): number {
    return this.withdrawals
      .filter(
        (x) => x.WithdrawalYear === year && x.WellID === well.WellID,
      )
      .map((x) => this.getWaterUsageInUnitsShown(x))
      .reduce((x, y) => x + y, 0);
  }

  public getWaterUsageInUnitsShown(withdrawal: WithdrawalSimpleDto) {
    if (this.unitsShown == 'ac-in') {
      return withdrawal.TotalAcreInches;
    } else {
      const totalAcreage = this.getTotalTractAcreage();
      return totalAcreage > 0
        ? withdrawal.TotalAcreInches / totalAcreage
        : 0;
    }
  }

  public getTotalTractAcreage(): number {
    return this.tracts.map((x) => x.FinalAcres).reduce((x, y) => x + y, 0);
  }

  public getAvailableYears(): number[] {
    if (this.isRealAllocationPlan()) {
      const years = [];
      for (let i = 0; i < this.allocationPlanToDisplay.Duration; i++) {
        years.push(this.allocationPlanToDisplay.StartYear + i);
      }
      return years;
    } else {
      return Array.from(
        new Set(this.withdrawals.map((x) => x.WithdrawalYear)),
      ).sort();
    }
  }

  public isRealAllocationPlan(): boolean {
    return this.allocationPlanToDisplay.AllocationPlanID != -1;
  }

  public toggleWaterUsageByWellView(): void {
    if (this.waterUsageByWellViewType === 'chart') {
      this.waterUsageByWellViewType = 'table';
    } else {
      this.waterUsageByWellViewType = 'chart';
    }
  }

  public toggleUnitsShown(units: string): void {
    this.unitsShown = units;
    this.unitsShownText =
            units == 'ac-in' ? 'acre-inches' : 'acre-inches / acre';
  }
}
