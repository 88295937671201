<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page">Accounts</li>
    </ol>
</nav>
<div class="container">
    <app-alert-display></app-alert-display>

    <div class="row">
        <div class="col">
            <div style="margin: 1rem 0 1rem 0" class="float-end">
                <span *ngIf="latestBeehiveSyncRun" class="small">
                    <em>
                        Last Synced:
                        {{ latestBeehiveSyncRun.RunDate | date: "short" }},
                        Beehive Last Updated:
                        {{
                            latestBeehiveSyncRun.BeehiveLastUpdateDate
                                | date: "short"
                        }}
                    </em>
                </span>
            </div>
            <h2 class="d-inline-block">Accounts</h2>
        </div>
    </div>
    <div class="row mt-1 mb-2">
        <div class="col">
            <custom-rich-text
                [customRichTextTypeID]="richTextTypeID"
            ></custom-rich-text>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-auto ms-auto">
            <clear-grid-filters-button
                [grid]="accountsGrid"
            ></clear-grid-filters-button>
            <csv-download-button
                [grid]="accountsGrid"
                fileName="accounts"
                class="ms-2"
            ></csv-download-button>
        </div>
    </div>
</div>
<div class="container">
    <div class="table-responsive">
        <ag-grid-angular
            #accountsGrid
            style="width: 100%; height: 800px"
            class="ag-theme-balham"
            [rowData]="accounts"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [pagination]="true"
            [paginationPageSize]="100"
            [suppressMenuHide]="true"
        >
        </ag-grid-angular>
    </div>
</div>
