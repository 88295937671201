import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FeatureCollection } from 'geojson';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WfsService {
  private gettractIDsIntersectingUnsubscribe: Subject<void> =
    new Subject<void>();

  constructor(private http: HttpClient) {}

  public getTractByCoordinate(
    longitude: number,
    latitude: number,
  ): Observable<FeatureCollection> {
    const url: string = `${environment.geoserverMapServiceUrl}/wms`;
    return this.http.get<FeatureCollection>(url, {
      params: {
        service: 'WFS',
        version: '2.0',
        request: 'GetFeature',
        outputFormat: 'application/json',
        SrsName: 'EPSG:4326',
        typeName: 'Splash:Tracts',
        cql_filter: `intersects(TractGeometry4326, POINT(${latitude} ${longitude}))`,
      },
    });
  }

  public getWellByCoordinate(
    longitude: number,
    latitude: number,
  ): Observable<FeatureCollection> {
    const url: string = `${environment.geoserverMapServiceUrl}/wms`;
    return this.http.get<FeatureCollection>(url, {
      params: {
        service: 'WFS',
        version: '2.0',
        request: 'GetFeature',
        outputFormat: 'application/json',
        SrsName: 'EPSG:4326',
        typeName: 'Splash:Wells',
        cql_filter: `intersects(WellGeometry4326, POINT(${latitude} ${longitude}))`,
      },
    });
  }

  public getWellOrTractByCoordinate(
    longitude: number,
    latitude: number,
  ): Observable<FeatureCollection> {
    const url: string = `${environment.geoserverMapServiceUrl}/wms`;
    return this.http.get<FeatureCollection>(url, {
      params: {
        service: 'WFS',
        version: '2.0',
        request: 'GetFeature',
        outputFormat: 'application/json',
        SrsName: 'EPSG:4326',
        typeName: 'Splash:Wells,Splash:Tracts',
        cql_filter: `DWITHIN(WellGeometry4326, POINT(${latitude} ${longitude}), 0.0001, meters);intersects(TractGeometry4326, POINT(${latitude} ${longitude}))`,
      },
    });
  }

  public getTractIdsIntersecting(
    lon1: number,
    lon2: number,
    lat1: number,
    lat2: number,
  ): Observable<number[]> {
    this.gettractIDsIntersectingUnsubscribe.next();

    const url: string = `${environment.geoserverMapServiceUrl}/wms`;
    return this.http
      .get(url, {
        responseType: 'text',
        params: {
          service: 'wfs',
          version: '2.0',
          request: 'GetPropertyValue',
          SrsName: 'EPSG:4326',
          typeName: 'Splash:Tracts',
          valueReference: 'TractID',
          cql_filter: `bbox(TractGeometry4326,${lat1},${lon1},${lat2},${lon2})`,
        },
      })
      .pipe(
        takeUntil(this.gettractIDsIntersectingUnsubscribe),
        map((rawData: string) => {
          // Parse XML to retrieve nodes
          const tractIDNodes: HTMLCollection = new DOMParser()
            .parseFromString(rawData, 'text/xml')
            .getElementsByTagName('heartwood:tractId');

          const tractIDs: number[] = [];
          for (let i = 0; i < tractIDNodes.length; i++) {
            tractIDs.push(parseInt(tractIDNodes[i].innerHTML));
          }
          return tractIDs;
        }),
      );
  }
}
