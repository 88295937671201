<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">
            <a routerLink="/pooling-agreements" *ngIf="currentUserIsAdmin()"
                >Pooling Agreements</a
            >
            <span *ngIf="!currentUserIsAdmin()">Pooling Agreements</span>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
            <span *ngIf="poolingAgreement"
                >Editing Pooling Agreement
                {{ poolingAgreement.PoolingAgreementNumber }}</span
            >
            <span *ngIf="!poolingAgreement">New Pooling Agreement</span>
        </li>
    </ol>
</nav>
<div class="container mt-sm-4">
    <app-alert-display></app-alert-display>
    <div class="row">
        <div class="col">
            <h2 class="d-inline-block">
                Pooling Agreement
                <span *ngIf="poolingAgreement">{{
                    poolingAgreement.PoolingAgreementNumber
                }}</span>
            </h2>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-lg-3 col-md-12">
            <div class="row mb-3">
                <div class="col">
                    <ul class="list-group">
                        <li
                            class="list-group-item"
                            [class.selected]="
                                activeWorkflowStep ===
                                PoolingAgreementWorkflowStepEnum.BasicInformation
                            "
                            (click)="
                                changeStep(
                                    PoolingAgreementWorkflowStepEnum.BasicInformation
                                )
                            "
                        >
                            Basic Information
                        </li>
                        <li
                            class="list-group-item"
                            [class.selected]="
                                activeWorkflowStep ===
                                PoolingAgreementWorkflowStepEnum.SendingDetails
                            "
                            [class.notAllowed]="!poolingAgreement"
                            (click)="
                                changeStep(
                                    PoolingAgreementWorkflowStepEnum.SendingDetails
                                )
                            "
                        >
                            Sending Details
                        </li>
                        <li
                            class="list-group-item"
                            [class.selected]="
                                activeWorkflowStep ===
                                PoolingAgreementWorkflowStepEnum.ReceivingDetails
                            "
                            [class.notAllowed]="!poolingAgreement"
                            (click)="
                                changeStep(
                                    PoolingAgreementWorkflowStepEnum.ReceivingDetails
                                )
                            "
                        >
                            Receiving Details
                        </li>
                        <!--Todo: add class directive for notAllowed here-->
                        <li
                            class="list-group-item notAllowed"
                            [class.selected]="
                                activeWorkflowStep ===
                                PoolingAgreementWorkflowStepEnum.ReviewAndPublish
                            "
                            [class.notAllowed]="!poolingAgreement"
                            (click)="
                                changeStep(
                                    PoolingAgreementWorkflowStepEnum.ReviewAndPublish
                                )
                            "
                        >
                            Review & Publish
                        </li>
                    </ul>
                </div>
            </div>
            <div
                class="row mb-3"
                *ngIf="
                    activeWorkflowStep !==
                    PoolingAgreementWorkflowStepEnum.BasicInformation
                "
            >
                <div class="col">
                    <div class="card">
                        <div class="card-header">Overview</div>
                        <div class="card-body">
                            <strong> Allocation Period </strong>
                            <p>
                                {{
                                    poolingAgreement.AllocationPlan.DisplayName
                                }}
                            </p>

                            <strong> Transfer Volume </strong>
                            <p>
                                {{
                                    poolingAgreement.TransferVolume
                                        | number: "1.1-2"
                                }}
                            </p>

                            <strong> Sending Pool </strong>
                            <p>
                                {{ poolingAgreement.SendingPool.PoolName }}
                            </p>

                            <strong> Receiving Pool </strong>
                            <p>
                                {{ poolingAgreement.ReceivingPool.PoolName }}
                            </p>

                            <ng-container
                                *ngIf="poolingAgreement.EstablishmentDate"
                            >
                                <strong> Establishment Date </strong>
                                <p>
                                    {{
                                        poolingAgreement.EstablishmentDate
                                            | date
                                    }}
                                </p>
                            </ng-container>
                            <ng-container *ngIf="poolingAgreement.Notes">
                                <strong>Notes</strong>
                                <p>{{ poolingAgreement.Notes }}</p>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-9 col-md-12">
            <div class="card">
                <div class="card-body">
                    <splash-pooling-agreement-workflow-basics
                        [poolingAgreementObservable]="
                            poolingAgreementSubject.asObservable()
                        "
                        #basicsStep
                        *ngIf="
                            activeWorkflowStep ==
                            PoolingAgreementWorkflowStepEnum.BasicInformation
                        "
                    >
                    </splash-pooling-agreement-workflow-basics>
                    <splash-pooling-agreement-workflow-tracts
                        [poolingAgreementTractType]="
                            PoolingAgreementTractTypeEnum.Sending
                        "
                        [poolingAgreementObservable]="
                            poolingAgreementSubject.asObservable()
                        "
                        #sendingDetailsStep
                        *ngIf="
                            activeWorkflowStep ===
                            PoolingAgreementWorkflowStepEnum.SendingDetails
                        "
                    >
                    </splash-pooling-agreement-workflow-tracts>
                    <splash-pooling-agreement-workflow-tracts
                        [poolingAgreementTractType]="
                            PoolingAgreementTractTypeEnum.Receiving
                        "
                        [poolingAgreementObservable]="
                            poolingAgreementSubject.asObservable()
                        "
                        #receivingDetailsStep
                        *ngIf="
                            activeWorkflowStep ===
                            PoolingAgreementWorkflowStepEnum.ReceivingDetails
                        "
                    >
                    </splash-pooling-agreement-workflow-tracts>
                    <splash-pooling-agreement-workflow-review-and-publish
                        [poolingAgreementObservable]="
                            poolingAgreementSubject.asObservable()
                        "
                        #reviewAndPublishStep
                        *ngIf="
                            activeWorkflowStep ===
                            PoolingAgreementWorkflowStepEnum.ReviewAndPublish
                        "
                    >
                    </splash-pooling-agreement-workflow-review-and-publish>
                </div>
            </div>
        </div>
    </div>
    <div
        class="row mt-2"
        *ngIf="
            activeWorkflowStep !==
            PoolingAgreementWorkflowStepEnum.ReviewAndPublish
        "
    >
        <div class="col text-end">
            <button
                type="button"
                class="btn btn-splash"
                (click)="onSubmit(false)"
                [disabled]="isPerformingAction || areSaveButtonsDisabled()"
            >
                <span
                    *ngIf="isPerformingAction"
                    class="fa fa-spinner loading-spinner"
                ></span>
                Save
            </button>
            <button
                type="button"
                class="btn btn-splash ms-2"
                (click)="onSubmit(true)"
                [disabled]="isPerformingAction || areSaveButtonsDisabled()"
            >
                <span
                    *ngIf="isPerformingAction"
                    class="fa fa-spinner loading-spinner"
                ></span>
                Save and Continue
            </button>
        </div>
    </div>
    <div
        class="row mt-2"
        *ngIf="
            activeWorkflowStep ===
            PoolingAgreementWorkflowStepEnum.ReviewAndPublish
        "
    >
        <div class="col text-end" *ngIf="!isPublished()">
            <button
                type="button"
                class="btn btn-splash"
                (click)="openPublishModal()"
                [disabled]="isPerformingAction"
            >
                <span
                    *ngIf="isPerformingAction"
                    class="fa fa-spinner loading-spinner"
                ></span>
                Publish
            </button>
            <button
                type="button"
                class="btn btn-splash ms-2"
                (click)="openCancelModal()"
                [disabled]="isPerformingAction"
            >
                <span
                    *ngIf="isPerformingAction"
                    class="fa fa-spinner loading-spinner"
                ></span>
                Cancel
            </button>
        </div>
        <div class="col text-end" *ngIf="isPublished()">
            <a class="btn btn-splash" routerLink="/pooling-agreements/">
                Return to list
            </a>
        </div>
    </div>
</div>

<ng-template #changeStepModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="deletePoolingAgreement">Switch sections</h4>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.close('Cross click')"
        >
            <span aria-hipen="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        The form has unsaved data. Switching to another section of the workflow
        will erase unsaved changes of the current section. Are you sure you want
        to proceed?
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-splash btn-sm"
            (click)="advanceToStep()"
        >
            Proceed
        </button>
        <button
            type="button"
            class="btn btn-secondary btn-sm"
            (click)="modal.close('Cancel click')"
        >
            Cancel
        </button>
    </div>
</ng-template>

<ng-template #publishModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="confirmReviewAndPublish">
            Publish Draft Pooling Agreement
        </h4>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.close('Cross click')"
        >
            <span aria-hipen="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        Are you sure you would like to publish Pooling Agreement
        {{ poolingAgreement.PoolingAgreementID }} between Pool
        {{ poolingAgreement.SendingPool.PoolName }} and Pool
        {{ poolingAgreement.ReceivingPool.PoolName }}?
        <br />
        <br />
        This action cannot be undone
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-splash btn-sm"
            (click)="publishPoolingAgreement()"
        >
            Confirm
        </button>
        <button
            type="button"
            class="btn btn-secondary btn-sm"
            (click)="modal.close('Cancel click')"
        >
            Cancel
        </button>
    </div>
</ng-template>

<ng-template #cancelModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="cancelReviewAndPublish">
            Cancel Draft Pooling Agreement
        </h4>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.close('Cross click')"
        >
            <span aria-hipen="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        Would you like to delete Pooling Agreement
        {{ poolingAgreement.PoolingAgreementID }} between Pool
        {{ poolingAgreement.SendingPool.PoolName }} and Pool
        {{ poolingAgreement.ReceivingPool.PoolName }}?
        <br />
        <br />
        This action cannot be undone
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-splash btn-sm"
            (click)="deletePoolingAgreement()"
        >
            Confirm
        </button>
        <button
            type="button"
            class="btn btn-secondary btn-sm"
            (click)="modal.close('Cancel click')"
        >
            Cancel
        </button>
    </div>
</ng-template>
