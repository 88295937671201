<div *ngIf="isLoading" class="text-center">
    <span class="fa fa-spinner loading-spinner"></span>
</div>

<div *ngIf="!isLoading" class="d-inline">
    <ng-template #popTitle>
        <div class="row">
            <div class="col-auto me-auto">
                <div class="mt-1">
                    {{
                        fieldDefinition.FieldDefinitionType
                            .FieldDefinitionTypeDisplayName
                    }}
                </div>
            </div>
            <div class="col-auto" *ngIf="!isEditing">
                <div class="row">
                    <div class="col-sm-2 ps-0 pe-0">
                        <button
                            type="button"
                            class="btn btn-link"
                            (click)="enterEdit()"
                        >
                            <i
                                class="fa fa-pencil-square-o"
                                attr.aria-label="Edit the definition for {{
                                    fieldDefinition.FieldDefinitionType
                                        .FieldDefinitionTypeDisplayName
                                }}"
                            >
                            </i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #popContent>
        <ng-container *ngIf="!isEditing">
            <div
                *ngIf="canEdit"
                style="position: relative; min-height: 30px"
            >
                <div
                    *ngIf="!emptyContent"
                    [innerHtml]="fieldDefinition.FieldDefinitionValue"
                ></div>
                <div *ngIf="emptyContent" class="hoverEmptySpace"></div>
            </div>
            <div
                *ngIf="!canEdit"
                [innerHtml]="fieldDefinition.FieldDefinitionValue"
            ></div>
        </ng-container>
        <div *ngIf="isEditing">
            <editor #tinyMceEditor
                [init]="tinyMceEditor | tinyMceConfig"
                [(ngModel)]="editedContent"
            ></editor> 
            <div style="float: right">
                <button
                    type="button"
                    class="btn btn-sm btn-splash mt-1 me-1 mb-1"
                    (click)="saveEdit()"
                >
                    Save
                </button>
                <button
                    type="button"
                    class="btn btn-sm btn-secondary mt-1 mb-1"
                    (click)="cancelEdit(p)"
                >
                    Cancel
                </button>
            </div>
        </div>
    </ng-template>
    <ng-container *ngIf="canEdit">
        <div
            class="d-inline-block"
            (mouseenter)="notEditingMouseEnter()"
            (mouseleave)="notEditingMouseLeave()"
        >
            <span style="overflow: hidden; text-overflow: ellipsis">
                {{ getLabelText() }}
                <i
                    class="fas fa-question-circle small"
                    style="cursor: help"
                    #p="ngbPopover"
                    [ngbPopover]="popContent"
                    [placement]="['bottom', 'right', 'left', 'auto']"
                    [popoverTitle]="popTitle"
                    triggers="manual"
                    [autoClose]="false"
                    popoverClass="popover-override"
                    container="body"
                >
                </i>
            </span>
        </div>
    </ng-container>
    <ng-container *ngIf="!canEdit">
        <span>
            {{ getLabelText() }}
            <i
                class="fas fa-question-circle small"
                style="cursor: help"
                [ngbPopover]="popContent"
                popoverClass="popover-override"
                [popoverTitle]="
                    fieldDefinition.FieldDefinitionType
                        .FieldDefinitionTypeDisplayName
                "
                triggers="mouseenter:mouseleave"
                container="body"
            >
            </i>
        </span>
    </ng-container>
</div>
