/**
 * Splash.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';

import { Observable } from 'rxjs';

import { PoolCalculationRunDto } from '../model/pool-calculation-run-dto';
import { PoolDto } from '../model/pool-dto';
import { PoolUsageSummaryDto } from '../model/pool-usage-summary-dto';
import { PoolWithAllocationPlanValuesDto } from '../model/pool-with-allocation-plan-values-dto';
import { PoolingAgreementSummaryDto } from '../model/pooling-agreement-summary-dto';
import { TractWellDto } from '../model/tract-well-dto';
import { TractWithAccountDto } from '../model/tract-with-account-dto';
import { WellDto } from '../model/well-dto';
import { WithdrawalSimpleDto } from '../model/withdrawal-simple-dto';

import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class PoolService {
  protected basePath = 'http://localhost';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    private apiService: ApiService,
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public poolsGet(
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<Array<PoolWithAllocationPlanValuesDto>>;
  public poolsGet(
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Array<PoolWithAllocationPlanValuesDto>>>;
  public poolsGet(
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Array<PoolWithAllocationPlanValuesDto>>>;
  public poolsGet(
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient
      .get<Array<PoolWithAllocationPlanValuesDto>>(
      `${this.basePath}/pools`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    )
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }

  /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public poolsLatestCalculationRunGet(
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<PoolCalculationRunDto>;
  public poolsLatestCalculationRunGet(
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<PoolCalculationRunDto>>;
  public poolsLatestCalculationRunGet(
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<PoolCalculationRunDto>>;
  public poolsLatestCalculationRunGet(
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient
      .get<PoolCalculationRunDto>(
      `${this.basePath}/pools/latestCalculationRun`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    )
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }

  /**
     *
     *
     * @param poolID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public poolsPoolIDGet(
    poolID: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<PoolDto>;
  public poolsPoolIDGet(
    poolID: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<PoolDto>>;
  public poolsPoolIDGet(
    poolID: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<PoolDto>>;
  public poolsPoolIDGet(
    poolID: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (poolID === null || poolID === undefined) {
      throw new Error(
        'Required parameter poolID was null or undefined when calling poolsPoolIDGet.',
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient
      .get<PoolDto>(
      `${this.basePath}/pools/${encodeURIComponent(String(poolID))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    )
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }

  /**
     *
     *
     * @param poolID
     * @param allocationPlanID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public poolsPoolIDPoolingAgreementTractIdsAllocationPlanIDGet(
    poolID: number,
    allocationPlanID: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<Array<number>>;
  public poolsPoolIDPoolingAgreementTractIdsAllocationPlanIDGet(
    poolID: number,
    allocationPlanID: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Array<number>>>;
  public poolsPoolIDPoolingAgreementTractIdsAllocationPlanIDGet(
    poolID: number,
    allocationPlanID: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Array<number>>>;
  public poolsPoolIDPoolingAgreementTractIdsAllocationPlanIDGet(
    poolID: number,
    allocationPlanID: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (poolID === null || poolID === undefined) {
      throw new Error(
        'Required parameter poolID was null or undefined when calling poolsPoolIDPoolingAgreementTractIdsAllocationPlanIDGet.',
      );
    }

    if (allocationPlanID === null || allocationPlanID === undefined) {
      throw new Error(
        'Required parameter allocationPlanID was null or undefined when calling poolsPoolIDPoolingAgreementTractIdsAllocationPlanIDGet.',
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient
      .get<Array<number>>(
      `${this.basePath}/pools/${encodeURIComponent(String(poolID))}/pooling-agreement-tract-ids/${encodeURIComponent(String(allocationPlanID))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    )
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }

  /**
     *
     *
     * @param poolID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public poolsPoolIDPoolingAgreementsGet(
    poolID: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<Array<PoolingAgreementSummaryDto>>;
  public poolsPoolIDPoolingAgreementsGet(
    poolID: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Array<PoolingAgreementSummaryDto>>>;
  public poolsPoolIDPoolingAgreementsGet(
    poolID: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Array<PoolingAgreementSummaryDto>>>;
  public poolsPoolIDPoolingAgreementsGet(
    poolID: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (poolID === null || poolID === undefined) {
      throw new Error(
        'Required parameter poolID was null or undefined when calling poolsPoolIDPoolingAgreementsGet.',
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient
      .get<Array<PoolingAgreementSummaryDto>>(
      `${this.basePath}/pools/${encodeURIComponent(String(poolID))}/pooling-agreements`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    )
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }

  /**
     *
     *
     * @param poolID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public poolsPoolIDTractWellsGet(
    poolID: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<Array<TractWellDto>>;
  public poolsPoolIDTractWellsGet(
    poolID: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Array<TractWellDto>>>;
  public poolsPoolIDTractWellsGet(
    poolID: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Array<TractWellDto>>>;
  public poolsPoolIDTractWellsGet(
    poolID: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (poolID === null || poolID === undefined) {
      throw new Error(
        'Required parameter poolID was null or undefined when calling poolsPoolIDTractWellsGet.',
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient
      .get<Array<TractWellDto>>(
      `${this.basePath}/pools/${encodeURIComponent(String(poolID))}/tract-wells`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    )
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }

  /**
     *
     *
     * @param poolID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public poolsPoolIDTractsGet(
    poolID: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<Array<TractWithAccountDto>>;
  public poolsPoolIDTractsGet(
    poolID: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Array<TractWithAccountDto>>>;
  public poolsPoolIDTractsGet(
    poolID: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Array<TractWithAccountDto>>>;
  public poolsPoolIDTractsGet(
    poolID: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (poolID === null || poolID === undefined) {
      throw new Error(
        'Required parameter poolID was null or undefined when calling poolsPoolIDTractsGet.',
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient
      .get<Array<TractWithAccountDto>>(
      `${this.basePath}/pools/${encodeURIComponent(String(poolID))}/tracts`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    )
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }

  /**
     *
     *
     * @param poolID
     * @param allocationPlanID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public poolsPoolIDUsageSummaryAllocationPlanIDGet(
    poolID: number,
    allocationPlanID: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<PoolUsageSummaryDto>;
  public poolsPoolIDUsageSummaryAllocationPlanIDGet(
    poolID: number,
    allocationPlanID: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<PoolUsageSummaryDto>>;
  public poolsPoolIDUsageSummaryAllocationPlanIDGet(
    poolID: number,
    allocationPlanID: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<PoolUsageSummaryDto>>;
  public poolsPoolIDUsageSummaryAllocationPlanIDGet(
    poolID: number,
    allocationPlanID: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (poolID === null || poolID === undefined) {
      throw new Error(
        'Required parameter poolID was null or undefined when calling poolsPoolIDUsageSummaryAllocationPlanIDGet.',
      );
    }

    if (allocationPlanID === null || allocationPlanID === undefined) {
      throw new Error(
        'Required parameter allocationPlanID was null or undefined when calling poolsPoolIDUsageSummaryAllocationPlanIDGet.',
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient
      .get<PoolUsageSummaryDto>(
      `${this.basePath}/pools/${encodeURIComponent(String(poolID))}/usage-summary/${encodeURIComponent(String(allocationPlanID))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    )
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }

  /**
     *
     *
     * @param poolID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public poolsPoolIDWellsGet(
    poolID: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<Array<WellDto>>;
  public poolsPoolIDWellsGet(
    poolID: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Array<WellDto>>>;
  public poolsPoolIDWellsGet(
    poolID: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Array<WellDto>>>;
  public poolsPoolIDWellsGet(
    poolID: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (poolID === null || poolID === undefined) {
      throw new Error(
        'Required parameter poolID was null or undefined when calling poolsPoolIDWellsGet.',
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient
      .get<Array<WellDto>>(
      `${this.basePath}/pools/${encodeURIComponent(String(poolID))}/wells`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    )
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }

  /**
     *
     *
     * @param poolID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public poolsPoolIDWithdrawalsGet(
    poolID: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<Array<WithdrawalSimpleDto>>;
  public poolsPoolIDWithdrawalsGet(
    poolID: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Array<WithdrawalSimpleDto>>>;
  public poolsPoolIDWithdrawalsGet(
    poolID: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Array<WithdrawalSimpleDto>>>;
  public poolsPoolIDWithdrawalsGet(
    poolID: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (poolID === null || poolID === undefined) {
      throw new Error(
        'Required parameter poolID was null or undefined when calling poolsPoolIDWithdrawalsGet.',
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient
      .get<Array<WithdrawalSimpleDto>>(
      `${this.basePath}/pools/${encodeURIComponent(String(poolID))}/withdrawals`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    )
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }

  /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public poolsRecalculatePost(
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<PoolCalculationRunDto>;
  public poolsRecalculatePost(
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<PoolCalculationRunDto>>;
  public poolsRecalculatePost(
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<PoolCalculationRunDto>>;
  public poolsRecalculatePost(
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient
      .post<PoolCalculationRunDto>(
      `${this.basePath}/pools/recalculate`,
      null,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    )
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }
}
