import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UtilityFunctionsService } from 'src/app/services/utility-functions.service';
import { DecimalPipe } from '@angular/common';
import { LinkRendererComponent } from 'src/app/shared/components/ag-grid/link-renderer/link-renderer.component';
import { FieldDefinitionGridHeaderComponent } from 'src/app/shared/components/field-definition-grid-header/field-definition-grid-header.component';
import { forkJoin } from 'rxjs';
import { BeehiveSyncRunDto } from 'src/app/shared/generated/model/beehive-sync-run-dto';
import { BeehiveService } from 'src/app/shared/generated/api/beehive.service';
import { FlowMeterService } from 'src/app/shared/generated/api/flow-meter.service';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { FlowMeterDto } from 'src/app/shared/generated/model/flow-meter-dto';

@Component({
  selector: 'splash-flow-meter-list',
  templateUrl: './flow-meter-list.component.html',
  styleUrls: ['./flow-meter-list.component.scss'],
})
export class FlowMeterListComponent implements OnInit, OnDestroy {
  @ViewChild('flowMetersGrid') flowMetersGrid: AgGridAngular;

  private currentUser: UserDto;

  public richTextTypeID: number = CustomRichTextTypeEnum.FlowMeterList;
  public flowMeters: FlowMeterDto[];
  public columnDefs: ColDef[];
  public defaultColDef: ColDef;
  public latestBeehiveSyncRun: BeehiveSyncRunDto;

  constructor(
    private cdr: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private utilityFunctionsService: UtilityFunctionsService,
    private flowMeterService: FlowMeterService,
    private beehiveService: BeehiveService,
    private decimalPipe: DecimalPipe,
  ) {}

  ngOnInit() {
    this.authenticationService.getCurrentUser().subscribe((currentUser) => {
      this.currentUser = currentUser;

      forkJoin({
        flowMeters: this.flowMeterService.flowMetersGet(),
        latestBeehiveSyncRun:
                    this.beehiveService.beehiveLatestSyncRunGet(),
      }).subscribe(({ flowMeters, latestBeehiveSyncRun }) => {
        this.flowMeters = flowMeters;
        this.latestBeehiveSyncRun = latestBeehiveSyncRun;
        this.cdr.detectChanges();
      });

      const _decimalPipe = this.decimalPipe;
      this.columnDefs = [
        {
          headerName: '#',
          valueGetter: function (params: any) {
            return {
              LinkDisplay: params.data.FlowMeterName,
              LinkValue: params.data.FlowMeterID,
            };
          },
          cellRenderer: LinkRendererComponent,
          cellRendererParams: { inRouterLink: '/flow-meters/' },
          filterValueGetter: (params) => params.data.FlowMeterName,
          comparator:
                        this.utilityFunctionsService.linkRendererComparator,
        },
        {
          field: 'FlowMeterUse',
          headerComponent: FieldDefinitionGridHeaderComponent,
          headerComponentParams: {
            fieldDefinitionType: 'PrimaryUse',
          },
          width: 145,
        },
        {
          field: 'FlowMeterLifeCycle',
          headerComponent: FieldDefinitionGridHeaderComponent,
          headerComponentParams: { fieldDefinitionType: 'LifeCycle' },
        },
        { headerName: 'Manufacturer', field: 'FlowMeterManufacturer' },
        { headerName: 'Model', field: 'FlowMeterModel', width: 100 },
        {
          headerName: 'Units',
          field: 'FlowMeterMeasurementUnit',
          width: 100,
        },
        {
          field: 'Multiplier',
          headerComponent: FieldDefinitionGridHeaderComponent,
          headerComponentParams: {
            fieldDefinitionType: 'Multiplier',
          },
          valueFormatter: (params) =>
            _decimalPipe.transform(params.value, '1.1-5'),
        },
        {
          field: 'InsideDiameter',
          headerComponent: FieldDefinitionGridHeaderComponent,
          headerComponentParams: {
            fieldDefinitionType: 'InsideDiameter',
          },
          valueFormatter: (params) =>
            _decimalPipe.transform(params.value, '1.1-2'),
          width: 155,
        },
        {
          field: 'OutsideDiameter',
          headerComponent: FieldDefinitionGridHeaderComponent,
          headerComponentParams: {
            fieldDefinitionType: 'OutsideDiameter',
          },
          valueFormatter: (params) =>
            _decimalPipe.transform(params.value, '1.1-2'),
          width: 165,
        },
        { headerName: 'Field Notes', field: 'FieldNotes', width: 200 },
      ];

      this.defaultColDef = {
        sortable: true,
        filter: true,
        resizable: true,
        width: 120,
      };
    });
  }

  ngOnDestroy() {
    this.cdr.detach();
  }

  public updateGridData() {
    this.flowMeterService.flowMetersGet().subscribe((result) => {
      this.flowMetersGrid.api.setRowData(result);
    });
  }
}
