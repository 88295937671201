import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NotFoundComponent } from './pages';
import { HeaderNavComponent } from './components';
import { UnauthenticatedComponent } from './pages/unauthenticated/unauthenticated.component';
import { SubscriptionInsufficientComponent } from './pages/subscription-insufficient/subscription-insufficient.component';
import { NgProgressModule } from 'ngx-progressbar';
import { RouterModule } from '@angular/router';
import { TractDetailPopupComponent } from './components/tract-detail-popup/tract-detail-popup.component';
import { LinkRendererComponent } from './components/ag-grid/link-renderer/link-renderer.component';
import { FontAwesomeIconLinkRendererComponent } from './components/ag-grid/fontawesome-icon-link-renderer/fontawesome-icon-link-renderer.component';
import { TractMapComponent } from './components/tract-map/tract-map.component';
import { MultiLinkRendererComponent } from './components/ag-grid/multi-link-renderer/multi-link-renderer.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { WaterYearSelectComponent } from './components/water-year-select/water-year-select/water-year-select.component';
import { WellDetailPopupComponent } from './components/well-detail-popup/well-detail-popup.component';
import { WellMapComponent } from './components/well-map/well-map.component';
import { CustomRichTextComponent } from './components/custom-rich-text/custom-rich-text.component';
import { FieldDefinitionComponent } from './components/field-definition/field-definition.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertDisplayComponent } from './components/alert-display/alert-display.component';
import { FieldDefinitionGridHeaderComponent } from './components/field-definition-grid-header/field-definition-grid-header.component';
import { AllocationPlanSelectComponent } from './components/allocation-plan-select/allocation-plan-select.component';
import { ClearGridFiltersButtonComponent } from './components/ag-grid/clear-grid-filters-button/clear-grid-filters-button.component';
import { CustomDropdownFilterComponent } from './components/ag-grid/custom-dropdown-filter/custom-dropdown-filter.component';
import { CsvDownloadButtonComponent } from './components/ag-grid/csv-download-button/csv-download-button.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { ModalComponent } from './components/modal/modal.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { TinyMceConfigPipe } from './pipes/tiny-mce-config.pipe';

@NgModule({
  declarations: [
    AlertDisplayComponent,
    HeaderNavComponent,
    NotFoundComponent,
    UnauthenticatedComponent,
    SubscriptionInsufficientComponent,
    TractMapComponent,
    TractDetailPopupComponent,
    LinkRendererComponent,
    FontAwesomeIconLinkRendererComponent,
    MultiLinkRendererComponent,
    WaterYearSelectComponent,
    WellMapComponent,
    WellDetailPopupComponent,
    CustomRichTextComponent,
    FieldDefinitionComponent,
    FieldDefinitionGridHeaderComponent,
    AllocationPlanSelectComponent,
    ClearGridFiltersButtonComponent,
    CustomDropdownFilterComponent,
    CsvDownloadButtonComponent,
    ConfirmModalComponent,
    ModalComponent,
    TinyMceConfigPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    NgProgressModule,
    RouterModule,
    SelectDropDownModule,
    NgbModule,
    EditorModule,
  ],
  exports: [
    AlertDisplayComponent,
    CommonModule,
    FormsModule,
    NotFoundComponent,
    TractMapComponent,
    WellMapComponent,
    HeaderNavComponent,
    WaterYearSelectComponent,
    CustomRichTextComponent,
    FieldDefinitionComponent,
    FieldDefinitionGridHeaderComponent,
    AllocationPlanSelectComponent,
    ClearGridFiltersButtonComponent,
    CustomDropdownFilterComponent,
    CsvDownloadButtonComponent,
    ConfirmModalComponent,
    ModalComponent,
    EditorModule,
    TinyMceConfigPipe,
  ],
  providers: [
    {
      provide: TINYMCE_SCRIPT_SRC,
      useValue: 'assets/tinymce/tinymce.min.js',
    },
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }

  static forChild(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
