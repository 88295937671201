/**
 * Splash.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { UserDto } from '././user-dto';

export class AllocationPlanDto {
  DisplayName?: string;
  NumPoolingAgreements?: number;
  AllocationPlanID?: number;
  IsActive?: boolean;
  StartYear?: number;
  AllocationVolume?: number;
  Duration?: number;
  LastUpdatedByUser?: UserDto;
  LastUpdatedDate?: string;
  Notes?: string;
  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}
