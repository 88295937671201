import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import * as palette from 'google-palette';
import { AllocationPlanDto } from 'src/app/shared/generated/model/allocation-plan-dto';
import { WithdrawalSimpleDto } from 'src/app/shared/generated/model/withdrawal-simple-dto';

declare let vegaEmbed: any;

@Component({
  selector: 'splash-water-use-by-well-chart',
  templateUrl: './water-use-by-well-chart.component.html',
  styleUrls: ['./water-use-by-well-chart.component.scss'],
})
export class WaterUseByWellChartComponent implements OnInit, OnChanges {
  public chartID: string = 'waterUseByWellChart';
  @Input() withdrawals: WithdrawalSimpleDto[];
  @Input() acreage: number;
  @Input() allocationPlanToDisplay: AllocationPlanDto;
  @Input() unitsShown: string = 'ac-in / ac';
  colorScheme: string[];
  vegaView: any;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.buildChart();
  }

  buildChart(): void {
    const self = this;
    vegaEmbed(`#${this.chartID}`, this.chartSpec(), {
      actions: false,
      tooltip: {
        theme: 'custom',
      },
      renderer: 'svg',
    }).then(function (res) {
      self.vegaView = res.view;
    });

    this.colorScheme = this.buildColorScheme();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    this.buildChart();
  }

  public buildColorScheme(): string[] {
    let colorList: string[];
    return palette('mpn65', this.numberOfWells()).map((x) => `#${x}`);
  }

  public numberOfWells() {
    return new Set(this.withdrawals.map((x) => x.WellID)).size;
  }

  public chartSpec() {
    return {
      $schema: 'https://vega.github.io/schema/vega-lite/v4.json',
      description: 'A simple bar chart with embedded data.',
      width: 'container',
      height: 'container',
      padding: {
        left: 30,
        top: 0,
        bottom: 0,
        right: 0,
      },
      data: {
        name: 'data',
        values: this.makeVegaData(),
      },
      mark: {
        type: 'bar',
        cornerRadiusEnd: 4,
      },
      transform: [
        { calculate: 'datum.WellName', as: 'Well Name' },
        {
          calculate: `datum.TotalAcreInches + ' ${this.unitsShown}'`,
          as: 'Usage',
        },
      ],
      encoding: {
        x: {
          field: 'Year',
          type: 'nominal',
          axis: {
            title: null,
            labelFont: 'Helvetica',
            labelFontSize: 14,
            labelAngle: -75,
          },
          sort: null,
        },
        y: {
          field: 'TotalAcreInches',
          type: 'quantitative',
          axis: {
            title: `Usage (${this.unitsShown})`,
            titleFont: 'Helvetica',
            labelFont: 'Helvetica',
            titleFontSize: 18,
            labelFontSize: 14,
            titlePadding: 20,
          },
        },
        color: {
          field: 'Well Name',
          type: 'nominal',
          scale: {
            range: this.colorScheme,
          },
          legend: {
            labelFontSize: 12,
          },
        },
        tooltip: [
          { field: 'Year', type: 'nominal' },
          { field: 'Well Name', type: 'nominal' },
          { field: 'Usage', type: 'ordinal' },
        ],
      },
    };
  }

  makeVegaData(): any {
    return this.filteredWithdrawals()
      .map((x) => {
        return {
          Year: x.WithdrawalYear,
          WellName: x.WellName,
          TotalAcreInches: this.getResultsInUnitsShown(x).toFixed(3),
        };
      })
      .sort((x, y) => x.Year - y.Year);
  }

  getResultsInUnitsShown(withdrawal: WithdrawalSimpleDto): number {
    if (this.unitsShown == 'ac-in') {
      return withdrawal.TotalAcreInches;
    } else {
      return this.acreage > 0
        ? withdrawal.TotalAcreInches / this.acreage
        : 0;
    }
  }

  isRealAllocationPlan(): boolean {
    return this.allocationPlanToDisplay.AllocationPlanID != -1;
  }

  filteredWithdrawals(): WithdrawalSimpleDto[] {
    return this.augmentedWithdrawals().filter(
      (x) =>
        x.WithdrawalYear >= this.allocationPlanToDisplay.StartYear &&
                x.WithdrawalYear <
                    this.allocationPlanToDisplay.StartYear +
                        this.allocationPlanToDisplay.Duration &&
                x.TotalAcreInches > 0,
    );
  }

  augmentedWithdrawals(): WithdrawalSimpleDto[] {
    const augmented = [...this.withdrawals];
    // add dummies to the list so that all years show even if there's nothing to show for a year.
    if (this.isRealAllocationPlan()) {
      const dummyName = this.withdrawals[0].WellName;
      const dummyID = this.withdrawals[0].WellID;
      for (let i = 0; i < this.allocationPlanToDisplay.Duration; i++) {
        augmented.push({
          WithdrawalYear: this.allocationPlanToDisplay.StartYear + i,
          TotalAcreInches: 0,
          WellID: dummyID,
          WellName: dummyName,
        });
      }
      return augmented;
    }
    return this.withdrawals;
  }
}
