<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page">
            Manage Beehive Integration
        </li>
    </ol>
</nav>
<div class="container">
    <app-alert-display></app-alert-display>

    <div class="row">
        <div class="col">
            <div style="margin: 1rem 0 1rem 0" class="float-end">
                <span *ngIf="latestBeehiveSyncRun" class="small">
                    <em>
                        Last Synced:
                        {{ latestBeehiveSyncRun.RunDate | date: "short" }},
                        Beehive Last Updated:
                        {{
                            latestBeehiveSyncRun.BeehiveLastUpdateDate
                                | date: "short"
                        }}
                    </em>
                </span>
                <button
                    class="btn btn-splash btn-sm ms-3"
                    (click)="launchModal(syncDataModalContent)"
                >
                    Sync Data from Beehive
                </button>
            </div>
            <h2 class="d-inline-block">Manage Beehive Integration</h2>
        </div>
    </div>
    <div class="row mt-1 mb-2">
        <div class="col">
            <custom-rich-text
                [customRichTextTypeID]="richTextTypeID"
            ></custom-rich-text>
        </div>
    </div>
</div>
<div class="container">
    <div class="row mb-2">
        <div class="col-auto ms-auto">
            <clear-grid-filters-button
                [grid]="syncsGrid"
            ></clear-grid-filters-button>
            <csv-download-button
                [grid]="syncsGrid"
                fileName="syncs"
                class="ms-2"
            ></csv-download-button>
        </div>
    </div>

    <div class="table-responsive">
        <ag-grid-angular
            #syncsGrid
            style="width: 100%; height: 800px"
            class="ag-theme-balham"
            [rowData]="beehiveSyncRuns"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [pagination]="true"
            [paginationPageSize]="100"
            [suppressMenuHide]="true"
        >
        </ag-grid-angular>
    </div>
</div>

<ng-template #syncDataModalContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="calculatePoolsModalTitle">
            Sync Data from Beehive
        </h4>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        This will grab the any data that has changed from Beehive since this
        date:
        <input type="date" [(ngModel)]="sinceDate" />
        <p>Are you sure you want to proceed?</p>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-splash btn-sm"
            (click)="calculatePools()"
            [disabled]="isLoadingSubmit"
        >
            <span
                *ngIf="isLoadingSubmit"
                class="fa fa-spinner loading-spinner"
            ></span>
            Continue
        </button>
        <button
            type="button"
            class="btn btn-secondary btn-sm"
            (click)="modal.close('Cancel click')"
            [disabled]="isLoadingSubmit"
        >
            Cancel
        </button>
    </div>
</ng-template>
