<nav aria-label="breadcrumb" *ngIf="phaseReport">
    <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">
            <a routerLink="/phase-reports">Phase Reports</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
            Phase Report #{{ phaseReport.PhaseReportName }}
        </li>
    </ol>
</nav>
<div class="container mt-sm-4" *ngIf="phaseReport">
    <h2 class="d-inline-block">
        Phase Report #{{ phaseReport.PhaseReportName }}
    </h2>
    <app-alert-display></app-alert-display>
    <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
            <div class="card">
                <div class="card-header">Basics</div>
                <div class="card-body">
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            Phase II/III ID
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ phaseReport.PhaseReportName }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">Tract ID</dt>
                        <dd class="col-sm-6 col-xs-12">
                            <a routerLink="/tracts/{{ phaseReport.TractID }}">{{
                                phaseReport.TractNumber
                            }}</a>
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">Status</dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ phaseReport.PhaseReportStatus }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            Report Date
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ phaseReport.ReportDate | date: "M/d/yyyy" }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">Note</dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ phaseReport.Note }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            Entry Comment
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ phaseReport.EntryComment }}
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6">
            <div class="card">
                <div class="card-header">Cooperator and Farm</div>
                <div class="card-body">
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            GWMA Cooperator
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            <a
                                routerLink="/accounts/{{
                                    phaseReport.GWMACooperatorID
                                }}"
                                >{{ phaseReport.GWMACooperatorFullName }}</a
                            >
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            Farm Name
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ phaseReport.FarmName }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            <field-definition
                                [fieldDefinitionType]="'Legal'"
                            ></field-definition>
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ phaseReport.LegalDescription }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            Total Acres
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ phaseReport.TotalAcres }}
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">Practices</div>
                <div
                    class="card-body table-responsive"
                    style="max-height: 600px; overflow-y: auto"
                >
                    <ng-template #noPhaseReportPractices>
                        <p>No practices related to this phase report.</p>
                    </ng-template>
                    <table
                        class="table table-condensed table-striped"
                        *ngIf="
                            phaseReportPractices.length > 0;
                            else noPhaseReportPractices
                        "
                    >
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Note</th>
                                <th>Status</th>
                                <th>Irrigated?</th>
                                <th>Expected Yield</th>
                                <th>Crop Grown</th>
                                <th>Actual Yield</th>
                                <th>Acres</th>
                                <th>Sample Depth</th>
                                <th>Organic Matter</th>
                                <th>Pre-Plant N</th>
                                <th>Post-Plant N</th>
                                <th>Inhibitor Used?</th>
                                <th>Current Crop</th>
                                <th>Override UNL Needed?</th>
                                <th>Override UNL Rate</th>
                                <th>UNL Needed?</th>
                                <th>UNL Rate</th>
                                <th>Shallow Sample</th>
                                <th>Deep Sample</th>
                                <th>Soil N</th>
                                <th>Override Residual N?</th>
                                <th>Residual N</th>
                                <th>Previous Crop N</th>
                                <th>Other N</th>
                                <th>Nitrogen Use Effeciency</th>
                                <th>Irrigation Nitrate Credit</th>
                                <th>Bushel Yield Goal</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="
                                    let phaseReportPractice of phaseReportPractices
                                "
                            >
                                <td
                                    style="
                                        border: 0 none;
                                        white-space: nowrap;
                                        text-align: right;
                                    "
                                >
                                    {{
                                        phaseReportPractice.EventDate
                                            | date: "M/d/yyyy" : "UTC"
                                    }}
                                </td>
                                <td style="white-space: nowrap">
                                    {{ phaseReportPractice.Note }}
                                </td>
                                <td>{{ phaseReportPractice.Status }}</td>
                                <td>
                                    {{
                                        phaseReportPractice.Irrigated
                                            ? "Y"
                                            : "N"
                                    }}
                                </td>
                                <td style="white-space: nowrap">
                                    {{ phaseReportPractice.ExpectedYield }}
                                    {{ phaseReportPractice.ExpectedYieldUnit }}
                                </td>
                                <td style="white-space: nowrap">
                                    {{ phaseReportPractice.CropGrown }}
                                </td>
                                <td style="white-space: nowrap">
                                    {{ phaseReportPractice.ActualYield }}
                                    {{ phaseReportPractice.ActualYieldUnit }}
                                </td>
                                <td>{{ phaseReportPractice.AcresField }}</td>
                                <td>{{ phaseReportPractice.SampleDepth }}</td>
                                <td>{{ phaseReportPractice.OrganicMatter }}</td>
                                <td>{{ phaseReportPractice.PrePlantN }}</td>
                                <td>{{ phaseReportPractice.PostPlantN }}</td>
                                <td>
                                    {{
                                        phaseReportPractice.InhibitorUsed
                                            ? "Y"
                                            : "N"
                                    }}
                                </td>
                                <td>{{ phaseReportPractice.CurrentCrop }}</td>
                                <td>
                                    {{
                                        phaseReportPractice.OvrrdUNLNeeded
                                            ? "Y"
                                            : "N"
                                    }}
                                </td>
                                <td>{{ phaseReportPractice.UNLNeeded }}</td>
                                <td>
                                    {{
                                        phaseReportPractice.OvrrdUNLRate
                                            ? "Y"
                                            : "N"
                                    }}
                                </td>
                                <td>{{ phaseReportPractice.UNLRate }}</td>
                                <td>{{ phaseReportPractice.ShallowSample }}</td>
                                <td>{{ phaseReportPractice.DeepSample }}</td>
                                <td>{{ phaseReportPractice.SoilN }}</td>
                                <td>
                                    {{
                                        phaseReportPractice.OvrrdResidualN
                                            ? "Y"
                                            : "N"
                                    }}
                                </td>
                                <td>{{ phaseReportPractice.ResidualN }}</td>
                                <td>{{ phaseReportPractice.PreviousCropN }}</td>
                                <td>{{ phaseReportPractice.OtherN }}</td>
                                <td>
                                    {{
                                        phaseReportPractice.NitrogenUseEffiency
                                    }}
                                </td>
                                <td>
                                    {{
                                        phaseReportPractice.IrrigationNitrateCredit
                                    }}
                                </td>
                                <td>
                                    {{ phaseReportPractice.BushelYieldGoal }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
