import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UtilityFunctionsService } from 'src/app/services/utility-functions.service';
import { LinkRendererComponent } from 'src/app/shared/components/ag-grid/link-renderer/link-renderer.component';
import { FieldDefinitionGridHeaderComponent } from 'src/app/shared/components/field-definition-grid-header/field-definition-grid-header.component';
import { forkJoin } from 'rxjs';
import { BeehiveSyncRunDto } from 'src/app/shared/generated/model/beehive-sync-run-dto';
import { BeehiveService } from 'src/app/shared/generated/api/beehive.service';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { WellService } from 'src/app/shared/generated/api/well.service';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { WellDto } from 'src/app/shared/generated/model/well-dto';
import { CustomDropdownFilterComponent } from 'src/app/shared/components/ag-grid/custom-dropdown-filter/custom-dropdown-filter.component';

@Component({
  selector: 'splash-well-list',
  templateUrl: './well-list.component.html',
  styleUrls: ['./well-list.component.scss'],
})
export class WellListComponent implements OnInit, OnDestroy {
  @ViewChild('wellsGrid') wellsGrid: AgGridAngular;

  private currentUser: UserDto;

  public richTextTypeID: number = CustomRichTextTypeEnum.WellList;
  public waterYearToDisplay: number;
  public wells: WellDto[];
  public columnDefs: ColDef[];
  public defaultColDef: ColDef;
  public latestBeehiveSyncRun: BeehiveSyncRunDto;

  constructor(
    private cdr: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private utilityFunctionsService: UtilityFunctionsService,
    private wellService: WellService,
    private beehiveService: BeehiveService,
  ) {}

  ngOnInit() {
    this.authenticationService.getCurrentUser().subscribe((currentUser) => {
      this.currentUser = currentUser;

      forkJoin({
        wells: this.wellService.wellsGet(),
        latestBeehiveSyncRun:
                    this.beehiveService.beehiveLatestSyncRunGet(),
      }).subscribe(({ wells, latestBeehiveSyncRun }) => {
        this.wells = wells;
        this.latestBeehiveSyncRun = latestBeehiveSyncRun;

        this.cdr.detectChanges();
      });

      this.columnDefs = [
        {
          headerName: 'Name',
          valueGetter: (params) => {
            return {
              LinkDisplay: params.data.WellName,
              LinkValue: params.data.WellID,
            };
          },
          cellRenderer: LinkRendererComponent,
          cellRendererParams: { inRouterLink: '/wells/' },
          filterValueGetter: (params) => params.data.WellName,
          comparator:
                        this.utilityFunctionsService.linkRendererComparator,
          width: 120,
        },
        {
          headerName: 'Pool',
          valueGetter: (params) => {
            return {
              LinkDisplay:
                                params.data.Pool === null
                                  ? ''
                                  : params.data.Pool.PoolName,
              LinkValue:
                                params.data.Pool === null
                                  ? ''
                                  : params.data.Pool.PoolID,
            };
          },
          cellRenderer: LinkRendererComponent,
          cellRendererParams: { inRouterLink: '/pools/' },
          filterValueGetter: (params) => params.data.Pool?.PoolName,
          comparator:
                        this.utilityFunctionsService.linkRendererComparator,
          width: 100,
        },
        {
          headerName: 'Pool Type',
          field: 'Pool.PoolType.PoolTypeDisplayName',
          filter: CustomDropdownFilterComponent,
          filterParams: {
            field: 'Pool.PoolType.PoolTypeDisplayName',
          },
          width: 100,
        },
        {
          field: 'WellUse',
          headerComponent: FieldDefinitionGridHeaderComponent,
          headerComponentParams: {
            fieldDefinitionType: 'PrimaryUse',
          },
          width: 145,
        },
        {
          field: 'SecondaryWellUse',
          headerComponent: FieldDefinitionGridHeaderComponent,
          headerComponentParams: {
            fieldDefinitionType: 'SecondaryUse',
          },
          width: 145,
        },
        {
          field: 'WellLifeCycle',
          headerComponent: FieldDefinitionGridHeaderComponent,
          headerComponentParams: { fieldDefinitionType: 'LifeCycle' },
          width: 145,
          filter: CustomDropdownFilterComponent,
          filterParams: { field: 'WellLifeCycle' },
        },
        {
          headerName: 'Reg #',
          field: 'RegistrationNumber',
          width: 120,
        },
        {
          headerName: 'Rep Oper',
          valueGetter: (params) => {
            return {
              LinkDisplay:
                                params.data.ReportingOperator === null
                                  ? ''
                                  : params.data.ReportingOperator
                                    .AccountNumber,
              LinkValue:
                                params.data.ReportingOperator === null
                                  ? ''
                                  : params.data.ReportingOperator.AccountID,
            };
          },
          cellRenderer: LinkRendererComponent,
          cellRendererParams: { inRouterLink: '/accounts/' },
          filterValueGetter: (params) =>
            params.data.ReportingOperator.AccountNumber,
          comparator:
                        this.utilityFunctionsService.linkRendererComparator,
          width: 100,
        },
        { headerName: 'Sub Quarter', field: 'SubQuarter', width: 120 },
        { headerName: 'Quarter', field: 'Quarter', width: 100 },
        { headerName: 'Section', field: 'Section', width: 90 },
        { headerName: 'Township', field: 'Township', width: 110 },
        { headerName: 'Range', field: 'Range', width: 90 },
        {
          headerName: 'Range Dir',
          field: 'RangeDirection',
          width: 120,
        },
        { headerName: 'County', field: 'County', width: 120 },
        { headerName: 'Comments', field: 'Comments', width: 300 },
      ];

      this.defaultColDef = {
        sortable: true,
        filter: true,
        resizable: true,
      };
    });
  }

  ngOnDestroy() {
    this.cdr.detach();
  }

  public updateGridData() {
    this.wellService.wellsGet().subscribe((result) => {
      this.wellsGrid.api.setRowData(result);
    });
  }
}
