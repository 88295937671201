declare let window: any;

export class DynamicEnvironment {
  private _production: boolean;

  //Global variables
  private readonly ModalErrorString: string = 'Modal Error';
  private readonly PrimaryKeyForEntityToBeCreated: number = -1;

  constructor(_production: boolean) {
    this._production = _production;
  }

  public get production() {
    if (window.config) {
      return window.config.production;
    } else return this._production;
  }

  public get staging() {
    return window.config.staging;
  }

  public get dev() {
    return window.config.dev;
  }

  public get mainAppApiUrl() {
    return window.config.mainAppApiUrl;
  }

  public get geoserverMapServiceUrl() {
    return window.config.geoserverMapServiceUrl;
  }

  public get keystoneAuthConfiguration() {
    return window.config.keystoneAuthConfiguration;
  }

  public get modalErrorString() {
    return this.ModalErrorString;
  }

  public get primaryKeyForEntityToBeCreated() {
    return this.PrimaryKeyForEntityToBeCreated;
  }
}
