import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AllocationPlanDto } from '../../generated/model/allocation-plan-dto';
import { AllYearsPlaceholderPlan } from '../../models/allocationPlan/all-years-placeholder-plan';

@Component({
  selector: 'splash-allocation-plan-select',
  templateUrl: './allocation-plan-select.component.html',
  styleUrls: ['./allocation-plan-select.component.scss'],
})
export class AllocationPlanSelectComponent implements OnInit {
  @Input() allocationPlans: Array<AllocationPlanDto> =
    new Array<AllocationPlanDto>();
  @Input() disabled: boolean;

  public allYearsPlaceholderPlan: AllocationPlanDto = AllYearsPlaceholderPlan;

  @Input()
  get selectedAllocationPlan(): AllocationPlanDto {
    return this.selectedAllocationPlanValue;
  }

  set selectedAllocationPlan(val: AllocationPlanDto) {
    this.selectedAllocationPlanValue = val;
    this.selectedAllocationPlanChange.emit(
      this.selectedAllocationPlanValue,
    );
  }

  selectedAllocationPlanValue: AllocationPlanDto;

  @Output() selectedAllocationPlanChange: EventEmitter<AllocationPlanDto> =
    new EventEmitter<AllocationPlanDto>();

  constructor() {}

  ngOnInit() {}
}
