import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridOptions } from 'ag-grid-community';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UtilityFunctionsService } from 'src/app/services/utility-functions.service';
import { LinkRendererComponent } from 'src/app/shared/components/ag-grid/link-renderer/link-renderer.component';
import { FieldDefinitionGridHeaderComponent } from 'src/app/shared/components/field-definition-grid-header/field-definition-grid-header.component';
import { forkJoin } from 'rxjs';
import { BeehiveSyncRunDto } from 'src/app/shared/generated/model/beehive-sync-run-dto';
import { BeehiveService } from 'src/app/shared/generated/api/beehive.service';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { WithdrawalService } from 'src/app/shared/generated/api/withdrawal.service';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { WithdrawalDto } from 'src/app/shared/generated/model/withdrawal-dto';
import { CustomDropdownFilterComponent } from 'src/app/shared/components/ag-grid/custom-dropdown-filter/custom-dropdown-filter.component';

@Component({
  selector: 'splash-withdrawal-list',
  templateUrl: './withdrawal-list.component.html',
  styleUrls: ['./withdrawal-list.component.scss'],
})
export class WithdrawalListComponent implements OnInit, OnDestroy {
  @ViewChild('withdrawalsGrid') withdrawalsGrid: AgGridAngular;

  private currentUser: UserDto;

  public richTextTypeID: number = CustomRichTextTypeEnum.Withdrawals;
  public gridOptions: GridOptions;
  public withdrawals: WithdrawalDto[];
  public columnDefs: ColDef[];
  public defaultColDef: ColDef;
  public latestBeehiveSyncRun: BeehiveSyncRunDto;

  constructor(
    private cdr: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private utilityFunctionsService: UtilityFunctionsService,
    private withdrawalService: WithdrawalService,
    private beehiveService: BeehiveService,
  ) {}

  ngOnInit() {
    this.authenticationService.getCurrentUser().subscribe((currentUser) => {
      this.gridOptions = <GridOptions>{};
      this.currentUser = currentUser;

      forkJoin({
        withdrawals: this.withdrawalService.withdrawalsGet(),
        latestBeehiveSyncRun:
                    this.beehiveService.beehiveLatestSyncRunGet(),
      }).subscribe(({ withdrawals, latestBeehiveSyncRun }) => {
        this.withdrawals = withdrawals;
        this.latestBeehiveSyncRun = latestBeehiveSyncRun;

        this.cdr.detectChanges();
      });

      this.columnDefs = [
        { headerName: 'Name', field: 'WithdrawalName', width: 150 },
        this.utilityFunctionsService.createDateColumnDef(
          'Withdrawal Date',
          'WithdrawalDate',
          'M/d/yyyy',
          150,
        ),
        this.utilityFunctionsService.createDecimalColumnDef(
          'Total Acre-Inches',
          'TotalAcreInches',
          140,
          3,
        ),
        {
          field: 'WithdrawalStatusName',
          headerComponent: FieldDefinitionGridHeaderComponent,
          headerComponentParams: { fieldDefinitionType: 'Status' },
          width: 120,
          filter: CustomDropdownFilterComponent,
          filterParams: { field: 'WithdrawalStatusName' },
        },
        {
          field: 'WithdrawalReportType',
          headerComponent: FieldDefinitionGridHeaderComponent,
          headerComponentParams: {
            fieldDefinitionType: 'ReportType',
          },
          width: 140,
          filter: CustomDropdownFilterComponent,
          filterParams: { field: 'WithdrawalReportType' },
        },
        {
          headerName: 'Well',
          valueGetter: (params) => {
            return {
              LinkDisplay:
                                params.data.WellID == null
                                  ? ''
                                  : params.data.WellName,
              LinkValue:
                                params.data.WellID == null
                                  ? ''
                                  : params.data.WellID,
            };
          },
          cellRenderer: LinkRendererComponent,
          cellRendererParams: { inRouterLink: '/wells/' },
          filterValueGetter: (params) => params.data.WellName,
          comparator:
                        this.utilityFunctionsService.linkRendererComparator,
          width: 120,
        },
        {
          headerName: 'Flow Meter',
          valueGetter: (params) => {
            return {
              LinkDisplay:
                                params.data.FlowMeterID == null
                                  ? ''
                                  : params.data.FlowMeterName,
              LinkValue:
                                params.data.FlowMeterID == null
                                  ? ''
                                  : params.data.FlowMeterID,
            };
          },
          cellRenderer: LinkRendererComponent,
          cellRendererParams: { inRouterLink: '/flow-meters/' },
          filterValueGetter: (params) => params.data.FlowMeterName,
          comparator:
                        this.utilityFunctionsService.linkRendererComparator,
          width: 120,
        },
        this.utilityFunctionsService.createDecimalColumnDef(
          'Beg Meter Reading',
          'BeginningMeterReading',
          150,
          2,
        ),
        this.utilityFunctionsService.createDecimalColumnDef(
          'End Meter Reading',
          'EndingMeterReading',
          150,
          2,
        ),
        {
          field: 'WithdrawalDetailsGPM',
          headerComponent: FieldDefinitionGridHeaderComponent,
          headerComponentParams: { fieldDefinitionType: 'GPM' },
          sortable: true,
          filter: true,
          width: 100,
        },
        {
          headerName: 'Notes',
          field: 'Notes',
          sortable: true,
          filter: true,
          width: 200,
        },
      ];

      this.defaultColDef = {
        sortable: true,
        filter: true,
        resizable: true,
      };
    });
  }

  ngOnDestroy() {
    this.cdr.detach();
  }

  public updateGridData() {
    this.withdrawalService.withdrawalsGet().subscribe((result) => {
      this.withdrawalsGrid.api.setRowData(result);
    });
  }

  public exportToCsv() {
    this.utilityFunctionsService.exportGridToCsv(
      this.withdrawalsGrid,
      'withdrawals.csv',
      null,
    );
  }
}
