import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ColDef } from 'ag-grid-community';
import { LinkRendererComponent } from 'src/app/shared/components/ag-grid/link-renderer/link-renderer.component';
import { AgGridAngular } from 'ag-grid-angular';
import { UtilityFunctionsService } from 'src/app/services/utility-functions.service';
import { forkJoin } from 'rxjs';
import { AccountDto } from 'src/app/shared/generated/model/account-dto';
import { BeehiveSyncRunDto } from 'src/app/shared/generated/model/beehive-sync-run-dto';
import { BeehiveService } from 'src/app/shared/generated/api/beehive.service';
import { AccountService } from 'src/app/shared/generated/api/account.service';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';

@Component({
  selector: 'splash-account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.scss'],
})
export class AccountListComponent implements OnInit, OnDestroy {
  @ViewChild('accountsGrid') accountsGrid: AgGridAngular;

  private currentUser: UserDto;

  public accounts: AccountDto[];
  public richTextTypeID: number = CustomRichTextTypeEnum.AccountList;

  public columnDefs: ColDef[];
  public defaultColDef: ColDef;
  public showOnlyActiveAccounts: boolean = true;
  public latestBeehiveSyncRun: BeehiveSyncRunDto;

  constructor(
    private accountService: AccountService,
    private authenticationService: AuthenticationService,
    private beehiveService: BeehiveService,
    private cdr: ChangeDetectorRef,
    private utilityFunctionsService: UtilityFunctionsService,
  ) {}

  ngOnInit() {
    this.authenticationService.getCurrentUser().subscribe((currentUser) => {
      this.currentUser = currentUser;

      forkJoin({
        accounts: this.accountService.accountsGet(),
        latestBeehiveSyncRun:
                    this.beehiveService.beehiveLatestSyncRunGet(),
      }).subscribe(({ accounts, latestBeehiveSyncRun }) => {
        this.accounts = accounts;
        this.latestBeehiveSyncRun = latestBeehiveSyncRun;
        this.cdr.detectChanges();
      });

      this.columnDefs = [
        {
          headerName: 'Full Name',
          valueGetter: function (params) {
            return {
              LinkValue: params.data.AccountID,
              LinkDisplay: params.data.FullName,
            };
          },
          cellRenderer: LinkRendererComponent,
          cellRendererParams: { inRouterLink: '/accounts/' },
          filterValueGetter: (params) => params.data.FullName,
          comparator:
                        this.utilityFunctionsService.linkRendererComparator,
          width: 200,
        },
        { headerName: 'Account #', field: 'AccountNumber' },
        { headerName: 'First Name', field: 'FirstName' },
        { headerName: 'Last Name', field: 'LastName' },
        { headerName: 'Email', field: 'Email' },
        { headerName: 'Company', field: 'Company' },
        { headerName: 'Address', field: 'Address' },
        { headerName: 'City', field: 'City' },
        { headerName: 'State', field: 'State', width: 80 },
        { headerName: 'Zip', field: 'Zip', width: 80 },
        { headerName: 'Home Phone', field: 'HomePhone' },
        { headerName: 'Mobile Phone', field: 'MobilePhone' },
        { headerName: 'In Care Of', field: 'InCareOf' },
      ];

      this.defaultColDef = {
        sortable: true,
        resizable: true,
        filter: true,
        width: 120,
      };
    });
  }

  ngOnDestroy(): void {
    this.cdr.detach();
  }
}
