<h3>Review and Publish</h3>
<table class="table table-condensed table-striped">
    <thead>
        <tr class="text-center d-none d-lg-table-row">
            <th class="entity-info" colspan="4">Pool Balance</th>
            <th class="agreement-info" colspan="3">Agreement Summary</th>
        </tr>
        <tr>
            <th class="entity-info">Pool</th>
            <th class="entity-info d-none d-lg-table-cell">
                <field-definition
                    [fieldDefinitionType]="'TotalAvailableWater'"
                ></field-definition>
            </th>
            <th class="entity-info d-none d-lg-table-cell">Total Usage</th>
            <th class="entity-info d-none d-lg-table-cell">
                <field-definition
                    [fieldDefinitionType]="'RemainingAvailableWater'"
                ></field-definition>
            </th>
            <th class="agreement-info">Water Sent</th>
            <th class="agreement-info">Water Received</th>
            <th class="agreement-info">Updated Total Available Water</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let summary of poolBalanceAndAgreementSummaries">
            <td>
                <a routerLink="/pools/{{ summary.PoolID }}">{{
                    summary.PoolName
                }}</a>
            </td>
            <td class="d-none d-lg-table-cell">
                {{ summary.TotalAvailableWater | number: "1.0-2" : "en-US" }}
            </td>
            <td class="d-none d-lg-table-cell">
                {{ summary.TotalUsage | number: "1.0-2" : "en-US" }}
            </td>
            <td class="d-none d-lg-table-cell">
                {{
                    summary.RemainingAvailableWater | number: "1.0-2" : "en-US"
                }}
            </td>
            <td>{{ summary.WaterSent | number: "1.0-2" : "en-US" }}</td>
            <td>{{ summary.WaterReceived | number: "1.0-2" : "en-US" }}</td>
            <td>
                {{
                    summary.UpdatedTotalAvailableWater
                        | number: "1.0-2" : "en-US"
                }}
            </td>
        </tr>
    </tbody>
</table>
<ng-container *ngFor="let summary of poolBalanceAndAgreementSummaries">
    <h4>Pool {{ summary.PoolName }}</h4>

    <table class="table table-condensed table-striped">
        <thead>
            <tr>
                <th class="entity-info">Tract</th>
                <th class="entity-info d-none d-lg-table-cell">
                    Owner (Acc.&nbsp;#)
                </th>
                <th class="entity-info d-none d-xl-table-cell">
                    Operator (Acc.&nbsp;#)
                </th>
                <th class="entity-info d-none d-lg-table-cell">
                    <field-definition
                        [fieldDefinitionType]="'Legal'"
                        class="ps-1"
                    ></field-definition
                    >, Township- Range- Section
                </th>
                <th class="entity-info d-none d-lg-table-cell">
                    <field-definition
                        [fieldDefinitionType]="'TotalAvailableWater'"
                        class="ps-1"
                    ></field-definition>
                </th>
                <th
                    *ngIf="summary.PoolID == sendingPool.PoolID"
                    class="agreement-info"
                >
                    Water Sent (ac&#8209;in)
                </th>
                <th
                    *ngIf="summary.PoolID == receivingPool.PoolID"
                    class="agreement-info"
                >
                    Water Received (ac&#8209;in)
                </th>
                <th class="agreement-info">
                    Revised Available Water After Agreement
                </th>
            </tr>
        </thead>
        <tbody>
            <tr
                *ngFor="
                    let tractSummary of getTractSummariesForPoolID(
                        summary.PoolID
                    )
                "
            >
                <td>
                    <a routerLink="/tracts/{{ tractSummary.Tract.TractID }}">{{
                        tractSummary.Tract.TractNumber
                    }}</a>
                </td>
                <td class="d-none d-lg-table-cell">
                    <a
                        routerLink="/accounts/{{
                            tractSummary.TractOwner.AccountID
                        }}"
                        >{{ tractSummary.TractOwner.AccountNumber }}</a
                    >
                </td>
                <td class="d-none d-xl-table-cell">
                    <a
                        routerLink="/accounts/{{
                            tractSummary.TractOperator.AccountID
                        }}"
                        >{{ tractSummary.TractOperator.AccountNumber }}</a
                    >
                </td>
                <td class="d-none d-lg-table-cell">
                    {{ tractSummary.Tract.Legal }},<br />{{
                        tractSummary.Tract.Township
                    }}-{{ tractSummary.Tract.Range }}-{{
                        tractSummary.Tract.Section
                    }}
                </td>
                <td class="d-none d-lg-table-cell">
                    {{ tractSummary.TotalAvailableWater | number: "1.1-2" }}
                </td>
                <td *ngIf="summary.PoolID == sendingPool.PoolID">
                    {{ tractSummary.WaterSent }}
                </td>
                <td *ngIf="summary.PoolID == receivingPool.PoolID">
                    {{ tractSummary.WaterReceived }}
                </td>
                <td>
                    {{
                        tractSummary.UpdatedTotalAvailableWater
                            | number: "1.1-2"
                    }}
                </td>
            </tr>
        </tbody>
    </table>
</ng-container>
