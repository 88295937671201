import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { ColDef } from 'ag-grid-community';
import {
  NgbModalRef,
  NgbModal,
  ModalDismissReasons,
} from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { AgGridAngular } from 'ag-grid-angular';
import { UtilityFunctionsService } from 'src/app/services/utility-functions.service';
import { BeehiveSyncRunDto } from 'src/app/shared/generated/model/beehive-sync-run-dto';
import { BeehiveService } from 'src/app/shared/generated/api/beehive.service';
import { SyncBeehiveDto } from 'src/app/shared/generated/model/sync-beehive-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';

@Component({
  selector: 'splash-manage-beehive-integration',
  templateUrl: './manage-beehive-integration.component.html',
  styleUrls: ['./manage-beehive-integration.component.scss'],
})
export class ManageBeehiveIntegrationComponent implements OnInit, OnDestroy {
  @ViewChild('syncsGrid') syncsGrid: AgGridAngular;

  private currentUser: UserDto;

  public richTextTypeID: number =
    CustomRichTextTypeEnum.ManageBeehiveIntegration;

  public beehiveSyncRuns: BeehiveSyncRunDto[];
  public columnDefs: ColDef[];
  public defaultColDef: ColDef;
  public showOnlyActivePools: boolean = true;
  public isLoadingSubmit: boolean;
  public closeResult: string;
  public modalReference: NgbModalRef;
  public latestBeehiveSyncRun: BeehiveSyncRunDto;
  public sinceDate: string;

  constructor(
    private beehiveService: BeehiveService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
    private utilityFunctionsService: UtilityFunctionsService,
  ) {}

  ngOnInit() {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);

    this.sinceDate = currentDate.toLocaleDateString('en-CA');
    this.closeResult = '';

    this.authenticationService.getCurrentUser().subscribe((currentUser) => {
      this.currentUser = currentUser;

      this.columnDefs = [
        this.utilityFunctionsService.createDateColumnDef(
          'Run Date',
          'RunDate',
          'short',
          120,
        ),
        this.utilityFunctionsService.createDateColumnDef(
          'Beehive Last Updated',
          'BeehiveLastUpdateDate',
          'short',
          120,
        ),
        {
          headerName: 'Status',
          field: 'BeehiveSyncRunStatus.BeehiveSyncRunStatusName',
          width: 100,
        },
        {
          headerName: '# of Accounts Changed',
          field: 'CountOfAccountsChanged',
          width: 170,
        },
        {
          headerName: '# of Tracts Changed',
          field: 'CountOfTractsChanged',
          width: 150,
        },
        {
          headerName: '# of Wells Changed',
          field: 'CountOfWellsChanged',
          width: 150,
        },
        {
          headerName: '# of Phase Reports Changed',
          field: 'CountOfPhaseReportsChanged',
          width: 150,
        },
        {
          headerName: '# of Practices Changed',
          field: 'CountOfPracticesChanged',
          width: 150,
        },
        {
          headerName: '# of Well Pools',
          field: 'CountOfWellPools',
          width: 150,
        },
        {
          headerName: '# of Owner-Operator Pools',
          field: 'CountOfOwnerOperatorPools',
          width: 190,
        },
      ];

      this.defaultColDef = {
        sortable: true,
        filter: true,
        resizable: true,
      };

      this.refreshData();
    });
  }

  ngOnDestroy(): void {
    this.cdr.detach();
  }

  public calculatePools() {
    this.isLoadingSubmit = true;
    const syncBeehiveDto = new SyncBeehiveDto();
    syncBeehiveDto.SinceDate = this.sinceDate;
    this.beehiveService.beehiveSyncPost(syncBeehiveDto).subscribe(
      (response) => {
        this.isLoadingSubmit = false;
        this.alertService.pushAlert(
          new Alert(
            'Beehive Sync Job has been queued.  Check back in a couple of minutes to view results',
            AlertContext.Success,
          ),
        );
        this.modalReference.close();
      },
      (error) => {
        this.alertService.pushAlert(
          new Alert(
            'Error trying to trigger Beehive Sync job.',
            AlertContext.Danger,
          ),
        );
        this.isLoadingSubmit = false;
        this.cdr.detectChanges();
        this.modalReference.close();
      },
    );
  }

  private refreshData() {
    this.syncsGrid?.api.showLoadingOverlay();

    this.beehiveService.beehiveRunsGet().subscribe((beehiveSyncRuns) => {
      this.beehiveSyncRuns = beehiveSyncRuns;
      this.latestBeehiveSyncRun = beehiveSyncRuns[0];

      this.syncsGrid?.api.hideOverlay();
    });
  }

  public launchModal(modalContent: any) {
    this.modalReference = this.modalService.open(modalContent, {
      ariaLabelledBy: 'calculatePoolsModalTitle',
      backdrop: 'static',
      keyboard: false,
    });
    this.modalReference.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
