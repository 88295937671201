<nav aria-label="breadcrumb" *ngIf="well">
    <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">
            <a routerLink="/wells">Wells</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
            Well: {{ well.WellName }}
        </li>
    </ol>
</nav>
<div class="container mt-sm-4" *ngIf="well">
    <h2 class="d-inline-block">Well: {{ well.WellName }}</h2>
    <app-alert-display></app-alert-display>
    <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
            <div class="card">
                <div class="card-header">Basics</div>
                <div class="card-body">
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">Pool</dt>
                        <dd class="col-sm-6 col-xs-12">
                            <a
                                *ngIf="well.Pool"
                                routerLink="/pools/{{ well.Pool.PoolID }}"
                                >{{ well.Pool.PoolType.PoolTypeDisplayName }}:
                                {{ well.Pool.PoolName }}</a
                            >
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            <field-definition
                                fieldDefinitionType="PrimaryUse"
                            ></field-definition>
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ well.WellUse }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            <field-definition
                                fieldDefinitionType="SecondaryUse"
                            ></field-definition>
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ well.SecondaryWellUse }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            <field-definition
                                fieldDefinitionType="LifeCycle"
                            ></field-definition>
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ well.WellLifeCycle }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">Reg #</dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ well.RegistrationNumber }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            Reporting Operator
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            <a
                                *ngIf="well.ReportingOperator"
                                routerLink="/accounts/{{
                                    well.ReportingOperator.AccountID
                                }}"
                                >{{ well.ReportingOperator.AccountNumber }}
                                <span *ngIf="well.ReportingOperator.FullName">
                                    -
                                    {{ well.ReportingOperator.FullName }}</span
                                ></a
                            >
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            Sub Quarter/Quarter
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ well.SubQuarter }}
                            {{ well.Quarter }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            Section/Township/Range
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ well.Section }}-T{{ well.Township }}-R{{
                                well.Range
                            }}{{ well.RangeDirection }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">County</dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ well.County }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">Comments</dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ well.Comments }}
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6">
            <div class="card">
                <div class="card-header">Location</div>
                <div class="card-body">
                    <div class="row">
                        <div
                            class="col-sm-12"
                            *ngIf="well && visibleWellIDs && visibleTractIDs"
                        >
                            <well-map
                                mapID="wellsMapForWell"
                                [selectedWellIDs]="[well.WellID]"
                                [visibleWellIDs]="visibleWellIDs"
                                [visibleTractIDs]="visibleTractIDs"
                                mapHeight="500px"
                            >
                            </well-map>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">Related Tracts</div>
                <div
                    class="card-body table-responsive"
                    style="max-height: 600px; overflow-y: auto"
                >
                    <ng-template #noTractWells>
                        <p>No tracts related to this well.</p>
                    </ng-template>
                    <table
                        class="table table-condensed table-striped"
                        *ngIf="tractWells.length > 0; else noTractWells"
                    >
                        <thead>
                            <tr>
                                <th>Tract</th>
                                <th>
                                    <field-definition
                                        [fieldDefinitionType]="'Type'"
                                    ></field-definition>
                                </th>
                                <th style="text-align: right">Acres</th>
                                <th>Relationship</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let tractWell of tractWells">
                                <td style="border: 0 none; white-space: nowrap">
                                    <a
                                        routerLink="/tracts/{{
                                            tractWell.Tract.TractID
                                        }}"
                                        >{{ tractWell.Tract.TractNumber }}</a
                                    >
                                </td>
                                <td style="border: 0 none">
                                    {{ tractWell.Tract.TractType }}
                                </td>
                                <td
                                    style="
                                        border: 0 none;
                                        white-space: nowrap;
                                        text-align: right;
                                    "
                                >
                                    {{
                                        tractWell.Tract.FinalAcres
                                            | number: "1.1-1"
                                    }}
                                </td>
                                <td style="border: 0 none">
                                    {{ tractWell.RelationshipType }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">Withdrawals</div>
                <div
                    class="card-body table-responsive"
                    style="max-height: 600px; overflow-y: auto"
                >
                    <ng-template #noWithdrawals>
                        <p>No Withdrawals recorded for this well.</p>
                    </ng-template>
                    <table
                        class="table table-condensed table-striped"
                        *ngIf="withdrawals.length > 0; else noWithdrawals"
                    >
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Name</th>
                                <th style="text-align: right">
                                    Total Acre-Inches
                                </th>
                                <th style="text-align: right">
                                    Beg Meter Reading
                                </th>
                                <th style="text-align: right">
                                    End Meter Reading
                                </th>
                                <th style="text-align: right">GPM</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let withdrawal of withdrawals">
                                <td style="border: 0 none; white-space: nowrap">
                                    {{
                                        withdrawal.WithdrawalDate
                                            | date: "M/dd/yyyy"
                                    }}
                                </td>
                                <td style="border: 0 none">
                                    {{ withdrawal.WithdrawalName }}
                                </td>
                                <td
                                    style="
                                        border: 0 none;
                                        white-space: nowrap;
                                        text-align: right;
                                    "
                                >
                                    {{
                                        withdrawal.TotalAcreInches
                                            | number: "1.1-4"
                                    }}
                                </td>
                                <td
                                    style="
                                        border: 0 none;
                                        white-space: nowrap;
                                        text-align: right;
                                    "
                                >
                                    {{
                                        withdrawal.BeginningMeterReading
                                            | number: "1.1-4"
                                    }}
                                </td>
                                <td
                                    style="
                                        border: 0 none;
                                        white-space: nowrap;
                                        text-align: right;
                                    "
                                >
                                    {{
                                        withdrawal.EndingMeterReading
                                            | number: "1.1-4"
                                    }}
                                </td>
                                <td
                                    style="
                                        border: 0 none;
                                        white-space: nowrap;
                                        text-align: right;
                                    "
                                >
                                    {{
                                        withdrawal.WithdrawalDetailsGPM
                                            | number: "1.1-4"
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
