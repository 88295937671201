/**
 * Splash.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';

import { Observable } from 'rxjs';

import { PoolingAgreementTractDetailedDto } from '../model/pooling-agreement-tract-detailed-dto';
import { PoolingAgreementTractTypeEnum } from '../model/pooling-agreement-tract-type-enum';
import { PoolingAgreementTractUpsertDto } from '../model/pooling-agreement-tract-upsert-dto';
import { TractAvailableWaterDto } from '../model/tract-available-water-dto';

import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class PoolingAgreementTractService {
  protected basePath = 'http://localhost';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    private apiService: ApiService,
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
     *
     *
     * @param poolingAgreementID
     * @param poolingAgreementTractType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public poolingAgreementsPoolingAgreementIDTractTypePoolingAgreementTractTypeTractsAvailableWaterGet(
    poolingAgreementID: number,
    poolingAgreementTractType: PoolingAgreementTractTypeEnum,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<Array<TractAvailableWaterDto>>;
  public poolingAgreementsPoolingAgreementIDTractTypePoolingAgreementTractTypeTractsAvailableWaterGet(
    poolingAgreementID: number,
    poolingAgreementTractType: PoolingAgreementTractTypeEnum,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Array<TractAvailableWaterDto>>>;
  public poolingAgreementsPoolingAgreementIDTractTypePoolingAgreementTractTypeTractsAvailableWaterGet(
    poolingAgreementID: number,
    poolingAgreementTractType: PoolingAgreementTractTypeEnum,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Array<TractAvailableWaterDto>>>;
  public poolingAgreementsPoolingAgreementIDTractTypePoolingAgreementTractTypeTractsAvailableWaterGet(
    poolingAgreementID: number,
    poolingAgreementTractType: PoolingAgreementTractTypeEnum,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (poolingAgreementID === null || poolingAgreementID === undefined) {
      throw new Error(
        'Required parameter poolingAgreementID was null or undefined when calling poolingAgreementsPoolingAgreementIDTractTypePoolingAgreementTractTypeTractsAvailableWaterGet.',
      );
    }

    if (
      poolingAgreementTractType === null ||
            poolingAgreementTractType === undefined
    ) {
      throw new Error(
        'Required parameter poolingAgreementTractType was null or undefined when calling poolingAgreementsPoolingAgreementIDTractTypePoolingAgreementTractTypeTractsAvailableWaterGet.',
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient
      .get<Array<TractAvailableWaterDto>>(
      `${this.basePath}/pooling-agreements/${encodeURIComponent(String(poolingAgreementID))}/tract-type/${encodeURIComponent(String(poolingAgreementTractType))}/tracts/available-water`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    )
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }

  /**
     *
     *
     * @param poolingAgreementID
     * @param poolingAgreementTractType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public poolingAgreementsPoolingAgreementIDTractTypesPoolingAgreementTractTypeTractsDetailedGet(
    poolingAgreementID: number,
    poolingAgreementTractType: PoolingAgreementTractTypeEnum,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<Array<PoolingAgreementTractDetailedDto>>;
  public poolingAgreementsPoolingAgreementIDTractTypesPoolingAgreementTractTypeTractsDetailedGet(
    poolingAgreementID: number,
    poolingAgreementTractType: PoolingAgreementTractTypeEnum,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Array<PoolingAgreementTractDetailedDto>>>;
  public poolingAgreementsPoolingAgreementIDTractTypesPoolingAgreementTractTypeTractsDetailedGet(
    poolingAgreementID: number,
    poolingAgreementTractType: PoolingAgreementTractTypeEnum,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Array<PoolingAgreementTractDetailedDto>>>;
  public poolingAgreementsPoolingAgreementIDTractTypesPoolingAgreementTractTypeTractsDetailedGet(
    poolingAgreementID: number,
    poolingAgreementTractType: PoolingAgreementTractTypeEnum,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (poolingAgreementID === null || poolingAgreementID === undefined) {
      throw new Error(
        'Required parameter poolingAgreementID was null or undefined when calling poolingAgreementsPoolingAgreementIDTractTypesPoolingAgreementTractTypeTractsDetailedGet.',
      );
    }

    if (
      poolingAgreementTractType === null ||
            poolingAgreementTractType === undefined
    ) {
      throw new Error(
        'Required parameter poolingAgreementTractType was null or undefined when calling poolingAgreementsPoolingAgreementIDTractTypesPoolingAgreementTractTypeTractsDetailedGet.',
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient
      .get<Array<PoolingAgreementTractDetailedDto>>(
      `${this.basePath}/pooling-agreements/${encodeURIComponent(String(poolingAgreementID))}/tract-types/${encodeURIComponent(String(poolingAgreementTractType))}/tracts/detailed`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    )
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }

  /**
     *
     *
     * @param poolingAgreementID
     * @param poolingAgreementTractType
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public poolingAgreementsPoolingAgreementIDTractTypesPoolingAgreementTractTypeTractsGet(
    poolingAgreementID: number,
    poolingAgreementTractType: PoolingAgreementTractTypeEnum,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<Array<PoolingAgreementTractUpsertDto>>;
  public poolingAgreementsPoolingAgreementIDTractTypesPoolingAgreementTractTypeTractsGet(
    poolingAgreementID: number,
    poolingAgreementTractType: PoolingAgreementTractTypeEnum,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Array<PoolingAgreementTractUpsertDto>>>;
  public poolingAgreementsPoolingAgreementIDTractTypesPoolingAgreementTractTypeTractsGet(
    poolingAgreementID: number,
    poolingAgreementTractType: PoolingAgreementTractTypeEnum,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Array<PoolingAgreementTractUpsertDto>>>;
  public poolingAgreementsPoolingAgreementIDTractTypesPoolingAgreementTractTypeTractsGet(
    poolingAgreementID: number,
    poolingAgreementTractType: PoolingAgreementTractTypeEnum,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (poolingAgreementID === null || poolingAgreementID === undefined) {
      throw new Error(
        'Required parameter poolingAgreementID was null or undefined when calling poolingAgreementsPoolingAgreementIDTractTypesPoolingAgreementTractTypeTractsGet.',
      );
    }

    if (
      poolingAgreementTractType === null ||
            poolingAgreementTractType === undefined
    ) {
      throw new Error(
        'Required parameter poolingAgreementTractType was null or undefined when calling poolingAgreementsPoolingAgreementIDTractTypesPoolingAgreementTractTypeTractsGet.',
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient
      .get<Array<PoolingAgreementTractUpsertDto>>(
      `${this.basePath}/pooling-agreements/${encodeURIComponent(String(poolingAgreementID))}/tract-types/${encodeURIComponent(String(poolingAgreementTractType))}/tracts`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    )
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }

  /**
     *
     *
     * @param poolingAgreementID
     * @param poolingAgreementTractType
     * @param poolingAgreementTractUpsertDto
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
  public poolingAgreementsPoolingAgreementIDTractTypesPoolingAgreementTractTypeTractsPut(
    poolingAgreementID: number,
    poolingAgreementTractType: PoolingAgreementTractTypeEnum,
    poolingAgreementTractUpsertDto?: Array<PoolingAgreementTractUpsertDto>,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<any>;
  public poolingAgreementsPoolingAgreementIDTractTypesPoolingAgreementTractTypeTractsPut(
    poolingAgreementID: number,
    poolingAgreementTractType: PoolingAgreementTractTypeEnum,
    poolingAgreementTractUpsertDto?: Array<PoolingAgreementTractUpsertDto>,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<any>>;
  public poolingAgreementsPoolingAgreementIDTractTypesPoolingAgreementTractTypeTractsPut(
    poolingAgreementID: number,
    poolingAgreementTractType: PoolingAgreementTractTypeEnum,
    poolingAgreementTractUpsertDto?: Array<PoolingAgreementTractUpsertDto>,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<any>>;
  public poolingAgreementsPoolingAgreementIDTractTypesPoolingAgreementTractTypeTractsPut(
    poolingAgreementID: number,
    poolingAgreementTractType: PoolingAgreementTractTypeEnum,
    poolingAgreementTractUpsertDto?: Array<PoolingAgreementTractUpsertDto>,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (poolingAgreementID === null || poolingAgreementID === undefined) {
      throw new Error(
        'Required parameter poolingAgreementID was null or undefined when calling poolingAgreementsPoolingAgreementIDTractTypesPoolingAgreementTractTypeTractsPut.',
      );
    }

    if (
      poolingAgreementTractType === null ||
            poolingAgreementTractType === undefined
    ) {
      throw new Error(
        'Required parameter poolingAgreementTractType was null or undefined when calling poolingAgreementsPoolingAgreementIDTractTypesPoolingAgreementTractTypeTractsPut.',
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
            this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    const httpContentTypeSelected: string | undefined =
            this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient
      .put<any>(
      `${this.basePath}/pooling-agreements/${encodeURIComponent(String(poolingAgreementID))}/tract-types/${encodeURIComponent(String(poolingAgreementTractType))}/tracts`,
      poolingAgreementTractUpsertDto,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    )
      .pipe(
        catchError((error: any) => {
          return this.apiService.handleError(error);
        }),
      );
  }
}
