<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page">
            Manage Report Templates
        </li>
    </ol>
</nav>
<div class="container">
    <app-alert-display></app-alert-display>

    <div class="row">
        <div class="col">
            <h2 class="d-inline-block">Manage Report Templates</h2>
        </div>
    </div>
    <div class="row mt-1 mb-2">
        <div class="col">
            <custom-rich-text
                [customRichTextTypeID]="richTextTypeID"
            ></custom-rich-text>
        </div>
    </div>
    <div class="row my-2">
        <div class="col-auto ms-auto">
            <clear-grid-filters-button
                [grid]="reportTemplatesGrid"
            ></clear-grid-filters-button>
            <a class="btn btn-splash btn-md ms-2" routerLink="/reports/new"
                ><span class="fas fa-plus"></span> New Report Template</a
            >
        </div>
    </div>
</div>
<div class="container">
    <div class="table-responsive">
        <ag-grid-angular
            #reportTemplatesGrid
            style="width: 100%; height: 800px"
            class="ag-theme-balham"
            [rowData]="reportTemplates"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [pagination]="true"
            [paginationPageSize]="100"
        >
        </ag-grid-angular>
    </div>
</div>
