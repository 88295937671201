import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UtilityFunctionsService } from 'src/app/services/utility-functions.service';
import { LinkRendererComponent } from 'src/app/shared/components/ag-grid/link-renderer/link-renderer.component';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { PoolingReportService } from 'src/app/shared/generated/api/pooling-report.service';
import { ReportTemplateDto } from 'src/app/shared/generated/model/report-template-dto';
import { GenerateReportsDto } from 'src/app/shared/generated/model/generate-reports-dto';
import { ReportTemplateService } from 'src/app/shared/generated/api/report-template.service';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { AccountForPoolingReportDto } from 'src/app/shared/generated/model/account-for-pooling-report-dto';
import { Subscription } from 'rxjs';

@Component({
  selector: 'splash-pooling-reports-list',
  templateUrl: './pooling-reports-list.component.html',
  styleUrls: ['./pooling-reports-list.component.scss'],
})
export class PoolingReportsListComponent implements OnInit, OnDestroy {
  @ViewChild('poolsGrid') poolsGrid: AgGridAngular;

  private currentUser: UserDto;

  public richTextTypeID: number = CustomRichTextTypeEnum.PoolingReportsList;

  private downloadReportSubscription: Subscription = Subscription.EMPTY;
  public poolAccounts: AccountForPoolingReportDto[];
  public columnDefs: (ColDef | ColGroupDef)[];
  public defaultColDef: ColDef;
  public showOnlyActivePools: boolean = true;
  public isLoadingSubmit: boolean;
  public closeResult: string;

  public reportTemplates: ReportTemplateDto[];
  public selectedReportTemplateID: string;

  constructor(
    private poolingReportService: PoolingReportService,
    private reportTemplateService: ReportTemplateService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private cdr: ChangeDetectorRef,
    private utilityFunctionsService: UtilityFunctionsService,
  ) {}

  ngOnInit() {
    this.closeResult = '';
    this.authenticationService.getCurrentUser().subscribe((currentUser) => {
      this.currentUser = currentUser;

      this.reportTemplateService
        .reportTemplatesGet()
        .subscribe((reportTemplates) => {
          this.reportTemplates = reportTemplates;
        });

      this.columnDefs = [
        {
          headerCheckboxSelection: true,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          sortable: false,
          filter: false,
          width: 40,
        },
        {
          headerName: 'Operator Name',
          valueGetter: (params) => {
            return {
              LinkValue: params.data.AccountID,
              LinkDisplay: params.data.OperatorName,
            };
          },
          cellRenderer: LinkRendererComponent,
          cellRendererParams: { inRouterLink: '/accounts/' },
          filterValueGetter: (params) => params.data.OperatorName,
          comparator:
                        this.utilityFunctionsService.linkRendererComparator,
        },
        {
          headerName: 'Operator Address',
          children: [
            {
              headerName: 'Street Address',
              field: 'OperatorAddress',
            },
            {
              headerName: 'City',
              field: 'OperatorCity',
              width: 100,
            },
            {
              headerName: 'State',
              field: 'OperatorState',
              width: 100,
            },
            {
              headerName: 'Zip',
              field: 'OperatorZipCode',
              width: 100,
            },
          ],
        },
        this.utilityFunctionsService.createDecimalColumnDef(
          '# of Pools',
          'NumPools',
          120,
          0,
        ),
        this.utilityFunctionsService.createDecimalColumnDef(
          '# of Tracts',
          'NumTracts',
          120,
          0,
        ),
        this.utilityFunctionsService.createDecimalColumnDef(
          '# of Wells',
          'NumWells',
          120,
          0,
        ),
      ];

      this.defaultColDef = {
        sortable: true,
        filter: true,
        resizable: true,
      };

      this.refreshPoolsData();
    });
  }

  ngOnDestroy(): void {
    this.downloadReportSubscription.unsubscribe();

    this.cdr.detach();
  }

  private refreshPoolsData() {
    this.poolsGrid?.api.showLoadingOverlay();

    this.poolingReportService
      .poolingReportAccountsGet()
      .subscribe((poolAccounts) => {
        this.poolAccounts = poolAccounts;
        this.poolsGrid.api.hideOverlay();
      });
  }

  public getSelectedRows() {
    const selectedFilteredSortedRows = [];
    if (this.poolsGrid) {
      this.poolsGrid.api.forEachNodeAfterFilterAndSort((node) => {
        if (node.isSelected()) {
          selectedFilteredSortedRows.push(node.data.AccountID);
        }
      });
    }
    return selectedFilteredSortedRows;
  }

  public getFilteredRowsCount() {
    let count = 0;
    if (this.poolsGrid) {
      this.poolsGrid.api.forEachNodeAfterFilterAndSort((node) => {
        count++;
      });
    }
    return count;
  }

  public generateReport(): void {
    const selectedFilteredSortedRows = [];
    this.poolsGrid.api.forEachNodeAfterFilterAndSort((node) => {
      if (node.isSelected()) {
        selectedFilteredSortedRows.push(node.data.AccountID);
      }
    });

    if (selectedFilteredSortedRows.length === 0) {
      this.alertService.pushAlert(
        new Alert('No account/pools selected.', AlertContext.Warning),
      );
    } else if (!this.selectedReportTemplateID) {
      this.alertService.pushAlert(
        new Alert('No report template selected.', AlertContext.Warning),
      );
    } else {
      this.isLoadingSubmit = true;
      const reportTemplateID = parseInt(this.selectedReportTemplateID);
      const reportTemplate = this.reportTemplates.find(
        (x) => x.ReportTemplateID === reportTemplateID,
      );
      const generateReportsDto = new GenerateReportsDto();
      generateReportsDto.ReportTemplateID = reportTemplateID;
      // generateReportsDto.AccountIDList = selectedFilteredSortedRows.map(x => x.AccountID)
      generateReportsDto.AccountIDList = selectedFilteredSortedRows;

      this.downloadReportSubscription = this.reportTemplateService
        .reportTemplatesGeneratePut(generateReportsDto)
        .subscribe(
          (response) => {
            this.isLoadingSubmit = false;

            const a = document.createElement('a');
            a.href = URL.createObjectURL(response);
            a.download =
                            reportTemplate.DisplayName + ' Generated Report';
            // start download
            a.click();

            this.alertService.pushAlert(
              new Alert(
                'Report Generated from Report Template \'' +
                                    reportTemplate.DisplayName +
                                    '\' for selected accounts/pools',
                AlertContext.Success,
              ),
            );
          },
          (error) => {
            this.isLoadingSubmit = false;
            this.cdr.detectChanges();
          },
        );
    }
  }
}
