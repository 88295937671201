import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, race, Subject } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { CookieStorageService } from '../shared/services/cookies/cookie-storage.service';
import { Router } from '@angular/router';
import { AlertService } from '../shared/services/alert.service';
import { Alert } from '../shared/models/alert';
import { AlertContext } from '../shared/models/enums/alert-context.enum';
import { environment } from 'src/environments/environment';
import { UserDto } from '../shared/generated/model/user-dto';
import { UserService } from '../shared/generated/api/user.service';
import { UserCreateDto } from '../shared/generated/model/user-create-dto';
import { RoleEnum } from '../shared/generated/enum/role-enum';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private currentUser: UserDto;

  private _currentUserSetSubject = new Subject<UserDto>();
  public currentUserSetObservable =
    this._currentUserSetSubject.asObservable();

  constructor(
    private router: Router,
    private oauthService: OAuthService,
    private cookieStorageService: CookieStorageService,
    private userService: UserService,
    private alertService: AlertService,
  ) {
    this.oauthService.events
      .pipe(filter((e) => ['discovery_document_loaded'].includes(e.type)))
      .subscribe((e) => {
        this.checkAuthentication();
      });

    this.oauthService.events
      .pipe(filter((e) => ['token_received'].includes(e.type)))
      .subscribe((e) => {
        this.checkAuthentication();
        this.oauthService.loadUserProfile();
      });

    this.oauthService.events
      .pipe(
        filter((e) =>
          ['session_terminated', 'session_error'].includes(e.type),
        ),
      )
      .subscribe((e) => this.router.navigateByUrl('/'));

    this.oauthService.setupAutomaticSilentRefresh();
  }

  public initialLoginSequence() {
    this.oauthService
      .loadDiscoveryDocument()
      .then(() => this.oauthService.tryLogin())
      .then(() => Promise.resolve())
      .catch(() => {});
  }

  public checkAuthentication() {
    if (this.isAuthenticated() && !this.currentUser) {
      console.log('Authenticated but no user found...');
      const claims = this.oauthService.getIdentityClaims();
      this.getUser(claims);
    }
  }

  public getUser(claims: any) {
    const globalID = claims.sub;

    this.userService.userClaimsGlobalIDGet(globalID).subscribe(
      (result) => {
        this.updateUser(result);
      },
      (error) => {
        this.onGetUserError(error, claims);
      },
    );
  }

  private onGetUserError(error: any, claims: any) {
    if (error.status !== 404) {
      this.alertService.pushAlert(
        new Alert(
          'There was an error logging into the application.',
          AlertContext.Danger,
        ),
      );
      this.router.navigate(['/']);
    } else {
      this.alertService.clearAlerts();
      const newUser = new UserCreateDto({
        FirstName: claims.given_name,
        LastName: claims.family_name,
        Email: claims.email,
        LoginName: claims.login_name,
        UserGuid: claims.sub,
      });

      this.userService.usersPost(newUser).subscribe((user) => {
        this.updateUser(user);
      });
    }
  }

  private updateUser(user: UserDto) {
    this.currentUser = user;
    this._currentUserSetSubject.next(this.currentUser);
  }

  public refreshUserInfo(user: UserDto) {
    this.updateUser(user);
  }

  public getCurrentUser(): Observable<UserDto> {
    return race(
      new Observable((subscriber) => {
        if (this.currentUser) {
          subscriber.next(this.currentUser);
          subscriber.complete();
        }
      }),
      this.currentUserSetObservable.pipe(first()),
    );
  }

  public getCurrentUserID(): Observable<any> {
    return race(
      new Observable((subscriber) => {
        if (this.currentUser) {
          subscriber.next(this.currentUser.UserID);
          subscriber.complete();
        }
      }),
      this.currentUserSetObservable.pipe(
        first(),
        map((user) => user.UserID),
      ),
    );
  }

  public isAuthenticated(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  public login() {
    this.oauthService.initCodeFlow();
  }

  public forcedLogout() {
    sessionStorage.authRedirectUrl = window.location.href;
    this.logout();
  }

  public logout() {
    this.oauthService.logOut();

    setTimeout(() => {
      this.cookieStorageService.removeAll();
    });
  }

  public isUserAnAdministrator(user: UserDto): boolean {
    const role = user && user.Role ? user.Role.RoleID : null;
    return role === RoleEnum.Admin;
  }

  isUserRoleReadOnly(user: UserDto): boolean {
    const role = user && user.Role ? user.Role.RoleID : null;
    return role === RoleEnum.ReadOnly;
  }

  public isCurrentUserAnAdministrator(): boolean {
    return this.isUserAnAdministrator(this.currentUser);
  }

  public isCurrentUserRoleReadOnly(): boolean {
    return this.isUserRoleReadOnly(this.currentUser);
  }

  public isUserUnassigned(user: UserDto): boolean {
    const role = user && user.Role ? user.Role.RoleID : null;
    return role === RoleEnum.Unassigned;
  }

  public isUserRoleDisabled(user: UserDto): boolean {
    const role = user && user.Role ? user.Role.RoleID : null;
    return role === RoleEnum.Disabled;
  }

  public isCurrentUserNullOrUndefined(): boolean {
    return !this.currentUser;
  }

  public hasCurrentUserAcknowledgedDisclaimer(): boolean {
    return (
      this.currentUser != null &&
            this.currentUser.DisclaimerAcknowledgedDate != null
    );
  }

  public getAuthRedirectUrl() {
    return sessionStorage.authRedirectUrl;
  }

  public setAuthRedirectUrl(url: string) {
    sessionStorage.authRedirectUrl = url;
  }

  public clearAuthRedirectUrl() {
    this.setAuthRedirectUrl('');
  }
}
