import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FontAwesomeIconLinkRendererComponent } from 'src/app/shared/components/ag-grid/fontawesome-icon-link-renderer/fontawesome-icon-link-renderer.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { LinkRendererComponent } from 'src/app/shared/components/ag-grid/link-renderer/link-renderer.component';
import { PoolingAgreementService } from 'src/app/shared/generated/api/pooling-agreement.service';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { PoolingAgreementStatusEnum } from 'src/app/shared/generated/enum/pooling-agreement-status-enum';
import { UtilityFunctionsService } from 'src/app/services/utility-functions.service';
import { ColDef } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { PoolingAgreementDto } from 'src/app/shared/generated/model/pooling-agreement-dto';
import { CustomDropdownFilterComponent } from 'src/app/shared/components/ag-grid/custom-dropdown-filter/custom-dropdown-filter.component';

@Component({
  selector: 'splash-pooling-agreement-list',
  templateUrl: './pooling-agreement-list.component.html',
  styleUrls: ['./pooling-agreement-list.component.scss'],
})
export class PoolingAgreementListComponent implements OnInit {
  @ViewChild('deletePoolingAgreement') deleteEntity: any;
  @ViewChild('poolingAgreementsGrid') poolingAgreementsGrid: AgGridAngular;

  public richTextTypeID: number = CustomRichTextTypeEnum.PoolingAgreement;

  public curentUser: UserDto;
  public modalReference: NgbModalRef;
  public closeResult: string;

  public columnDefs: ColDef[];
  public defaultColDef: ColDef;
  public poolingAgreements: PoolingAgreementDto[];
  public isPerformingAction: boolean | Promise<boolean>;
  public poolingAgreementToDeleteID: number;
  public poolingAgreementToDeleteNumber: string;

  constructor(
    private authenticationService: AuthenticationService,
    private poolingAgreementService: PoolingAgreementService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private cdr: ChangeDetectorRef,
    private utilityFunctionsService: UtilityFunctionsService,
  ) {}

  ngOnInit(): void {
    this.authenticationService.getCurrentUser().subscribe((currentUser) => {
      this.curentUser = currentUser;

      this.columnDefs = [
        {
          headerCheckboxSelection: true,
          checkboxSelection: true,
          sortable: false,
          filter: false,
          width: 40,
          hide: !this.isCurrentUserAnAdministrator(),
        },
        {
          headerName: '',
          valueGetter: (params) =>
            params.data.PoolingAgreementStatus
              .PoolingAgreementStatusID ==
                        PoolingAgreementStatusEnum.Draft
              ? params.data.PoolingAgreementNumber
              : null,
          cellRenderer: FontAwesomeIconLinkRendererComponent,
          cellRendererParams: {
            inRouterLink: '/pooling-agreements/edit/',
            fontawesomeIconName: 'pencil-square-o',
          },
          width: 38,
          hide: !this.isCurrentUserAnAdministrator(),
        },
        {
          headerName: 'Agreement Number',
          valueGetter: (params) => {
            return {
              LinkDisplay: params.data.PoolingAgreementNumber,
              LinkValue: params.data.PoolingAgreementNumber,
            };
          },
          cellRenderer: LinkRendererComponent,
          cellRendererParams: {
            inRouterLink: '/pooling-agreements/',
          },
          filterValueGetter: (params) =>
            params.data.PoolingAgreementNumber,
          comparator:
                        this.utilityFunctionsService.linkRendererComparator,
          width: 95,
        },
        {
          headerName: 'Agreement Status',
          field: 'PoolingAgreementStatus.PoolingAgreementStatusName',
          filter: CustomDropdownFilterComponent,
          filterParams: {
            field: 'PoolingAgreementStatus.PoolingAgreementStatusName',
          },
          width: 95,
        },
        {
          headerName: 'Allocation Period',
          field: 'AllocationPlan',
          valueGetter: (params) =>
            params.data.AllocationPlan.DisplayName,
          width: 95,
        },
        {
          headerName: 'Sending Pool',
          field: 'SendingPool',
          valueGetter: (params) => {
            return {
              LinkDisplay: params.data.SendingPool.PoolName,
              LinkValue: params.data.SendingPool.PoolID,
            };
          },
          cellRenderer: LinkRendererComponent,
          cellRendererParams: { inRouterLink: '/pools/' },
          filterValueGetter: (params) =>
            params.data.SendingPool.PoolName,
          comparator:
                        this.utilityFunctionsService.linkRendererComparator,
          width: 95,
        },
        {
          headerName: 'Receiving Pool',
          field: 'ReceivingPool',
          valueGetter: (params) => {
            return {
              LinkDisplay: params.data.ReceivingPool.PoolName,
              LinkValue: params.data.ReceivingPool.PoolID,
            };
          },
          cellRenderer: LinkRendererComponent,
          cellRendererParams: { inRouterLink: '/pools/' },
          filterValueGetter: (params) =>
            params.data.ReceivingPool.PoolName,
          comparator:
                        this.utilityFunctionsService.linkRendererComparator,
          width: 95,
        },
        {
          headerName: 'Transfer Volume',
          field: 'TransferVolume',
          width: 95,
        },
        this.utilityFunctionsService.createDateColumnDef(
          'Establishment Date',
          'EstablishmentDate',
          'M/d/yyyy',
          145,
        ),
        {
          headerName: 'Created By',
          valueGetter: (params) => params.data.CreatedByUser.FullName,
          width: 140,
        },
        this.utilityFunctionsService.createDateColumnDef(
          'Created Date',
          'CreateDate',
          'M/d/yyyy',
          145,
        ),
        {
          headerName: 'Notes',
          field: 'Notes',
          sortable: true,
          filter: true,
          width: 300,
        },
      ];

      this.defaultColDef = {
        sortable: true,
        filter: true,
        resizable: true,
      };
      this.updateGridData();
    });
  }

  public updateGridData() {
    this.poolingAgreementService
      .poolingAgreementsGet()
      .subscribe((poolingAgreements) => {
        this.poolingAgreements = poolingAgreements;
        this.poolingAgreementsGrid.api.setRowData(
          this.poolingAgreements,
        );
        this.cdr.detectChanges();
      });
  }

  public onDeleteClicked() {
    this.launchModal(this.deleteEntity, 'deletePoolingAgreement');
  }

  public launchModal(modalContent: any, modalAriaLabel: string) {
    this.modalReference = this.modalService.open(modalContent, {
      windowClass: 'pooling-agreement-modal',
      ariaLabelledBy: modalAriaLabel,
      beforeDismiss: () => this.isPerformingAction,
      backdrop: 'static',
      keyboard: false,
    });
    this.modalReference.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed`;
      },
    );
  }

  public isCurrentUserAnAdministrator(): boolean {
    return this.authenticationService.isUserAnAdministrator(
      this.curentUser,
    );
  }

  public selectedRows(): Array<any> {
    if (
      this.poolingAgreementsGrid === null ||
            this.poolingAgreementsGrid == undefined
    ) {
      return [];
    }
    return this.poolingAgreementsGrid.api.getSelectedRows();
  }

  public onDelete() {
    this.isPerformingAction = true;
    this.poolingAgreementService
      .poolingAgreementsDelete(
        this.selectedRows().map((row) => row.PoolingAgreementID),
      )
      .subscribe(
        (result) => {
          this.modalReference.close();
          this.isPerformingAction = false;
          this.alertService.pushAlert(
            new Alert(
              `Successfully deleted Pooling Agreement(s)`,
              AlertContext.Success,
              true,
            ),
          );
          this.updateGridData();
          this.poolingAgreementToDeleteID = null;
          this.poolingAgreementToDeleteNumber = null;
        },
        (error) => {
          this.modalReference.close();
          this.isPerformingAction = false;
          this.alertService.pushAlert(
            new Alert(
              `There was an error deleting the pooling agreement(s). Please try again`,
              AlertContext.Danger,
              true,
            ),
          );
          this.poolingAgreementToDeleteID = null;
          this.poolingAgreementToDeleteNumber = null;
        },
      );
  }
}
