import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { forkJoin } from 'rxjs';
import { AccountDto } from 'src/app/shared/generated/model/account-dto';
import { TractAccountDto } from 'src/app/shared/generated/model/tract-account-dto';
import { PoolSimpleDto } from 'src/app/shared/generated/model/pool-simple-dto';
import { AccountService } from 'src/app/shared/generated/api/account.service';
import { UserDto } from 'src/app/shared/generated/model/user-dto';

@Component({
  selector: 'splash-account-detail',
  templateUrl: './account-detail.component.html',
  styleUrls: ['./account-detail.component.scss'],
})
export class AccountDetailComponent implements OnInit, OnDestroy {
  private currentUser: UserDto;

  public account: AccountDto;
  public tractAccounts: TractAccountDto[];
  public accounts: AccountDto[];
  public pools: PoolSimpleDto[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
  ) {
    // force route reload whenever params change;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    this.authenticationService.getCurrentUser().subscribe((currentUser) => {
      this.currentUser = currentUser;

      const id = parseInt(this.route.snapshot.paramMap.get('id'));
      if (!id) return;

      forkJoin({
        account: this.accountService.accountsAccountIDGet(id),
        tractAccounts:
                    this.accountService.accountsAccountIDTractsGet(id),
        pools: this.accountService.accountsAccountIDPoolsGet(id),
      }).subscribe(({ account, tractAccounts, pools }) => {
        this.account = account;
        this.tractAccounts = tractAccounts;
        this.pools = pools;
      });
    });
  }

  ngOnDestroy() {
    this.cdr.detach();
  }

  public currentUserIsAdmin(): boolean {
    return this.authenticationService.isUserAnAdministrator(
      this.currentUser,
    );
  }

  public getSelectedTractIDs(): Array<number> {
    return this.tractAccounts !== undefined
      ? this.tractAccounts.map((p) => p.Tract.TractID)
      : [];
  }
}
