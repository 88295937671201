import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { AlertService } from 'src/app/shared/services/alert.service';
import { FieldDefinitionDto } from 'src/app/shared/generated/model/field-definition-dto';
import { FieldDefinitionService } from 'src/app/shared/generated/api/field-definition.service';
import { UserDto } from 'src/app/shared/generated/model/user-dto';

@Component({
  selector: 'splash-field-definition-edit',
  templateUrl: './field-definition-edit.component.html',
  styleUrls: ['./field-definition-edit.component.scss'],
})
export class FieldDefinitionEditComponent implements OnInit {
  private currentUser: UserDto;

  public fieldDefinition: FieldDefinitionDto;
  public editor;

  isLoadingSubmit: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private fieldDefinitionService: FieldDefinitionService,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.authenticationService.getCurrentUser().subscribe((currentUser) => {
      this.currentUser = currentUser;
      const id = parseInt(this.route.snapshot.paramMap.get('id'));
      if (id) {
        this.fieldDefinitionService
          .fieldDefinitionsFieldDefinitionTypeIDGet(id)
          .subscribe((fieldDefinition) => {
            this.fieldDefinition = fieldDefinition;
          });
      }
    });
  }

  ngOnDestroy() {
    this.cdr.detach();
  }

  public currentUserIsAdmin(): boolean {
    return this.authenticationService.isUserAnAdministrator(
      this.currentUser,
    );
  }

  saveDefinition(): void {
    this.isLoadingSubmit = true;

    this.fieldDefinitionService
      .fieldDefinitionsFieldDefinitionTypeIDPut(
        this.fieldDefinition.FieldDefinitionType.FieldDefinitionTypeID,
        this.fieldDefinition,
      )
      .subscribe(
        (response) => {
          this.isLoadingSubmit = false;
          this.router
            .navigateByUrl('/labels-and-definitions')
            .then((x) => {
              this.alertService.pushAlert(
                new Alert(
                  `The definition for ${this.fieldDefinition.FieldDefinitionType.FieldDefinitionTypeDisplayName} was successfully updated.`,
                  AlertContext.Success,
                ),
              );
            });
        },
        (error) => {
          this.isLoadingSubmit = false;
          this.cdr.detectChanges();
        },
      );
  }
}
