import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { forkJoin } from 'rxjs';
import { FlowMeterService } from 'src/app/shared/generated/api/flow-meter.service';
import { FlowMeterDto } from 'src/app/shared/generated/model/flow-meter-dto';
import { WellSimpleDto } from 'src/app/shared/generated/model/well-simple-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';

@Component({
  selector: 'splash-flow-meter-detail',
  templateUrl: './flow-meter-detail.component.html',
  styleUrls: ['./flow-meter-detail.component.scss'],
})
export class FlowMeterDetailComponent implements OnInit, OnDestroy {
  private currentUser: UserDto;

  public flowMeter: FlowMeterDto;
  public flowMeterWells: WellSimpleDto[];
  public poolFlowMeters: FlowMeterDto[];

  public today: Date = new Date();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private flowMeterService: FlowMeterService,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
  ) {
    // force route reload whenever params change;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    this.authenticationService.getCurrentUser().subscribe((currentUser) => {
      this.currentUser = currentUser;

      const id = parseInt(this.route.snapshot.paramMap.get('id'));
      if (id) {
        forkJoin({
          flowMeter:
                        this.flowMeterService.flowMetersFlowMeterIDGet(id),
          flowMeterWells:
                        this.flowMeterService.flowMetersFlowMeterIDWellsGet(id),
        }).subscribe(({ flowMeter, flowMeterWells }) => {
          this.flowMeter = flowMeter;
          this.flowMeterWells = flowMeterWells;
        });
      }
    });
  }

  ngOnDestroy() {
    this.cdr.detach();
  }

  public getSelectedFlowMeterIDs(): Array<number> {
    return this.flowMeter !== undefined ? [this.flowMeter.FlowMeterID] : [];
  }
}
