<nav aria-label="breadcrumb" *ngIf="flowMeter">
    <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">
            <a routerLink="/flow-meters">Flow Meters</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
            Flow Meter #{{ flowMeter.FlowMeterName }}
        </li>
    </ol>
</nav>
<div class="container mt-sm-4" *ngIf="flowMeter">
    <h2 class="d-inline-block">Flow Meter #{{ flowMeter.FlowMeterName }}</h2>
    <app-alert-display></app-alert-display>
    <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
            <div class="card">
                <div class="card-header">Basics</div>
                <div class="card-body">
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            <field-definition
                                [fieldDefinitionType]="'PrimaryUse'"
                            ></field-definition>
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ flowMeter.FlowMeterUse }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            <field-definition
                                [fieldDefinitionType]="'LifeCycle'"
                            ></field-definition>
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ flowMeter.FlowMeterLifeCycle }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            Manufacturer
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ flowMeter.FlowMeterManufacturer }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">Model</dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ flowMeter.FlowMeterModel }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">Units</dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ flowMeter.FlowMeterMeasurementUnit }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            <field-definition
                                [fieldDefinitionType]="'Multiplier'"
                            ></field-definition>
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ flowMeter.Multiplier | number: "1.1-5" }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            <field-definition
                                [fieldDefinitionType]="'InsideDiameter'"
                            ></field-definition>
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ flowMeter.InsideDiameter | number: "1.1-2" }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            <field-definition
                                [fieldDefinitionType]="'OutsideDiameter'"
                            ></field-definition>
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ flowMeter.OutsideDiameter | number: "1.1-2" }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            Field Notes
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ flowMeter.FieldNotes }}
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6">
            <div class="card" *ngIf="flowMeterWells">
                <div class="card-header">Related Wells</div>
                <div
                    class="card-body table-responsive"
                    style="max-height: 500px; overflow-y: auto"
                >
                    <ng-template #noFlowMeterWells>
                        <p>No wells related to this Flow Meter.</p>
                    </ng-template>
                    <table
                        class="table table-condensed table-striped"
                        *ngIf="flowMeterWells.length > 0; else noFlowMeterWells"
                    >
                        <thead>
                            <tr>
                                <th>Well</th>
                                <th>
                                    <field-definition
                                        [fieldDefinitionType]="'PrimaryUse'"
                                    ></field-definition>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let flowMeterWell of flowMeterWells">
                                <td style="border: 0 none; white-space: nowrap">
                                    <a
                                        routerLink="/wells/{{
                                            flowMeterWell.WellID
                                        }}"
                                        >{{ flowMeterWell.WellName }}</a
                                    >
                                </td>
                                <td style="border: 0 none">
                                    {{ flowMeterWell.WellUse }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
