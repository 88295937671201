<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page">
            Allocation Plans
        </li>
    </ol>
</nav>
<div class="container">
    <app-alert-display></app-alert-display>

    <div class="row">
        <div class="col">
            <h2 class="d-inline-block">Allocation Plans</h2>
        </div>
    </div>
    <div class="row mt-1 mb-2">
        <div class="col">
            <custom-rich-text
                [customRichTextTypeID]="richTextTypeID"
            ></custom-rich-text>
        </div>
    </div>
    <div class="row">
        <div class="ms-auto my-2">
            <button
                class="btn btn-splash btn-sm float-end"
                (click)="openModalForNewAllocationPlan()"
                *ngIf="isCurrentUserAdministrator()"
            >
                Add Allocation Plan
            </button>
        </div>
    </div>
    <div class="card">
        <div class="card-header">Active Allocation Plans</div>
        <div
            class="card-body table-responsive"
            style="max-height: 500px; overflow-y: auto"
        >
            <ng-template #noActiveAllocationPlan>
                <p>No allocation plan has been made active.</p>
            </ng-template>
            <table
                class="table table-condensed table-striped"
                *ngIf="
                    activeAllocationPlans && activeAllocationPlans.length > 0;
                    else noActiveAllocationPlan
                "
            >
                <thead>
                    <tr>
                        <th *ngIf="isCurrentUserAdministrator()"></th>
                        <th>Start Year</th>
                        <th>Allocation Volume (ac-in)</th>
                        <th>Duration (years)</th>
                        <th>Last Updated By User</th>
                        <th>Last Updated Date</th>
                        <th>Notes</th>
                    </tr>
                </thead>
                <tbody class="active-plan-body">
                    <tr
                        *ngFor="
                            let activeAllocationPlan of activeAllocationPlans
                        "
                    >
                        <td *ngIf="isCurrentUserAdministrator()">
                            <button
                                (click)="
                                    openModalForInactivateAllocationPlan(
                                        activeAllocationPlan.AllocationPlanID
                                    )
                                "
                                class="btn btn-splash btn-sm"
                            >
                                Inactivate
                            </button>
                        </td>
                        <td>{{ activeAllocationPlan.StartYear }}</td>
                        <td>{{ activeAllocationPlan.AllocationVolume }}</td>
                        <td>{{ activeAllocationPlan.Duration }}</td>
                        <td>
                            {{
                                activeAllocationPlan.LastUpdatedByUser.FullName
                            }}
                        </td>
                        <td>
                            {{
                                activeAllocationPlan.LastUpdatedDate
                                    | date: "M/d/yyyy" : "UTC"
                            }}
                        </td>
                        <td>{{ activeAllocationPlan.Notes }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="container">
    <div class="card mt-4">
        <div class="card-header">Inactive Allocation Plans</div>
        <div class="card-body">
            <div class="row mb-2">
                <div class="col-auto ms-auto">
                    <clear-grid-filters-button
                        [grid]="allocationPlansGrid"
                    ></clear-grid-filters-button>
                </div>
            </div>
            <div class="table-responsive">
                <ag-grid-angular
                    #allocationPlansGrid
                    style="width: 100%; height: 400px"
                    class="ag-theme-balham"
                    [rowData]="allocationPlans"
                    [columnDefs]="columnDefs"
                    [defaultColDef]="defaultColDef"
                    [pagination]="true"
                    [paginationPageSize]="100"
                    (cellClicked)="onCellClicked($event)"
                    [suppressMenuHide]="true"
                >
                </ag-grid-angular>
            </div>
        </div>
    </div>
</div>

<ng-template #upsertAllocationPlan let-modal>
    <form [formGroup]="upsertForm" (ngSubmit)="onSubmit()" class="form-inline">
        <div class="modal-header w-100">
            <h4 class="modal-title" id="upsertModalTitle">
                {{ allocationPlanID == -1 ? "Add" : "Edit" }} Allocation Plan
            </h4>
            <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body w-100">
            <div *ngIf="errorMessage" class="alert alert-danger">
                {{ errorMessage }}
            </div>
            <div class="form-group">
                <label
                    for="startYear"
                    class="col-sm-3 col-form-label required justify-content-end"
                    >Start Year</label
                >
                <div class="col">
                    <select
                        formControlName="startYear"
                        id="startYear"
                        class="form-select"
                    >
                        <option
                            *ngFor="let year of availableYears"
                            [value]="year"
                        >
                            {{ year }}
                        </option>
                    </select>
                </div>
            </div>
            <p class="mb-2 mt-2"><strong>Allocation Period</strong></p>
            <div class="form-group mb-1">
                <label
                    for="allocationVolume"
                    class="col-sm-3 col-form-label required justify-content-end"
                    >Allocation Volume
                </label>
                <div class="col">
                    <div class="row">
                        <div class="col-sm-5">
                            <input
                                style="width: 100%"
                                formControlName="allocationVolume"
                                id="allocationVolume"
                                type="number"
                                class="form-control"
                                (keypress)="catchExtraSymbols($event)"
                                (paste)="catchPastedSymbols($event)"
                                required
                            />
                        </div>
                        <div class="col-sm-7 trailing-text">
                            ac-in per certified acre
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="
                        f.allocationVolume.touched && f.allocationVolume.invalid
                    "
                    class="alert alert-danger"
                >
                    <div *ngIf="f.allocationVolume.errors.required">
                        Allocation Volume for Allocation Period is required.
                    </div>
                    <div *ngIf="f.allocationVolume.errors.min">
                        Allocation Volume for Allocation Period must be greater
                        than or equal to 0.
                    </div>
                </div>
            </div>
            <div class="form-group mb-1">
                <label
                    for="duration"
                    class="col-sm-3 col-form-label required justify-content-end"
                    >Duration
                </label>
                <div class="col">
                    <div class="row">
                        <div class="col-sm-5">
                            <input
                                style="width: 100%"
                                formControlName="duration"
                                id="duration"
                                type="number"
                                (keypress)="catchExtraSymbols($event)"
                                (paste)="catchPastedSymbols($event)"
                                class="form-control"
                            />
                        </div>
                        <div class="col-sm-7 trailing-text">year(s)</div>
                    </div>
                </div>
                <div
                    *ngIf="f.duration.touched && f.duration.invalid"
                    class="alert alert-danger"
                >
                    <div *ngIf="f.duration.errors.required">
                        Allocation Duration for Allocation Period is required.
                    </div>
                    <div *ngIf="f.duration.errors.min">
                        Allocation Duration for Allocation Period must be
                        greater than or equal to 1.
                    </div>
                </div>
            </div>
            <br />
            <div class="form-group mb-1">
                <label
                    for="notes"
                    class="col-sm-3 col-form-label justify-content-end"
                    >Notes</label
                >
                <div class="col">
                    <div class="row">
                        <div class="col-sm-12">
                            <textarea
                                class="form-control w-100"
                                style="resize: none"
                                formControlName="notes"
                                id="notes"
                                maxlength="2000"
                                rows="7"
                            ></textarea>
                            <small
                                style="float: right"
                                [style.color]="
                                    remainingText == 2000 ? 'red' : ''
                                "
                                >{{ remainingText }}/2000</small
                            >
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div class="form-group">
                <div class="form-check form-switch">
                    <input
                        type="checkbox"
                        class="form-check-input"
                        id="activatePlan"
                        formControlName="activatePlan"
                    />
                    <label class="custom-control-label" for="activatePlan"
                        >Activate Plan</label
                    >
                </div>
            </div>
            <div *ngIf="f.activatePlan.value == true">
                <small style="color: red"
                    >Warning: Once an Allocation Plan is Activated and Saved it
                    can no longer be edited.</small
                >
            </div>
            <br />

            <i class="fas fa-bolt"></i> Required Field
        </div>
        <div class="modal-footer w-100">
            <button
                type="submit"
                class="btn btn-splash btn-sm"
                [disabled]="isPerformingAction"
            >
                <span
                    *ngIf="isPerformingAction"
                    class="fa fa-spinner loading-spinner"
                ></span>
                Submit
            </button>
            <button
                type="button"
                class="btn btn-secondary btn-sm"
                (click)="modal.close('Cancel click')"
                [disabled]="isPerformingAction"
            >
                Cancel
            </button>
        </div>
    </form>
</ng-template>
<ng-template #deleteAllocationPlan let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="deleteAllocationPlan">
            Delete Allocation Plan
        </h4>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        Are you sure you want to delete this allocation plan?
        {{ getNumPoolingAgreementsStatement() }}
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-splash btn-sm"
            [disabled]="isPerformingAction"
            (click)="onDelete()"
        >
            <span
                *ngIf="isLoadingSubmit"
                class="fa fa-spinner loading-spinner"
            ></span>
            Confirm
        </button>
        <button
            type="button"
            class="btn btn-secondary btn-sm"
            (click)="modal.close('Cancel click')"
            [disabled]="isPerformingAction"
        >
            Cancel
        </button>
    </div>
</ng-template>

<ng-template #inactivateAllocationPlan let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="inactivateAllocationPlan">
            Inactivate Allocation Plan
        </h4>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-container>
            Are you sure you want to inactivate this allocation plan?
        </ng-container>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-splash btn-sm"
            [disabled]="isPerformingAction"
            (click)="onInactivate()"
        >
            <span
                *ngIf="isLoadingSubmit"
                class="fa fa-spinner loading-spinner"
            ></span>
            Confirm
        </button>
        <button
            type="button"
            class="btn btn-secondary btn-sm"
            (click)="modal.close('Cancel click')"
            [disabled]="isPerformingAction"
        >
            Cancel
        </button>
    </div>
</ng-template>
