<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">
            <a routerLink="/pooling-agreements">Pooling Agreements</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
            <span *ngIf="poolingAgreement"
                >Pooling Agreement
                {{ poolingAgreement.PoolingAgreementNumber }}</span
            >
        </li>
    </ol>
</nav>
<div class="container mt-sm-4" *ngIf="poolingAgreement">
    <app-alert-display></app-alert-display>
    <div class="row">
        <div class="col">
            <h2 class="d-inline-block">
                Pooling Agreement
                <span>{{ poolingAgreement.PoolingAgreementNumber }}</span>
            </h2>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-6">
            <div class="card">
                <div class="card-header">Overview</div>
                <div class="card-body">
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">Status</dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{
                                poolingAgreement.PoolingAgreementStatus
                                    .PoolingAgreementStatusName
                            }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            Allocation Period
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ poolingAgreement.AllocationPlan.DisplayName }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            Transfer Volume
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{
                                poolingAgreement.TransferVolume
                                    | number: "1.1-2"
                            }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            Sending Pool
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ poolingAgreement.SendingPool.PoolName }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            Receiving Pool
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ poolingAgreement.ReceivingPool.PoolName }}
                        </dd>
                    </dl>
                    <ng-container *ngIf="poolingAgreement.EstablishmentDate">
                        <dl class="row">
                            <dt class="text-sm-end col-sm-6 col-xs-12">
                                Establishment Date
                            </dt>
                            <dd class="col-sm-6 col-xs-12">
                                {{ poolingAgreement.EstablishmentDate | date }}
                            </dd>
                        </dl>
                    </ng-container>
                    <ng-container *ngIf="poolingAgreement.Notes">
                        <dl class="row">
                            <dt class="text-sm-end col-sm-6 col-xs-12">
                                Notes
                            </dt>
                            <dd class="col-sm-6 col-xs-12">
                                {{ poolingAgreement.Notes }}
                            </dd>
                        </dl>
                    </ng-container>
                    <ng-container *ngIf="attachments?.length">
                        <dl class="row">
                            <dt class="text-sm-end col-sm-6 col-xs-12">
                                Attachments
                            </dt>
                            <dd class="col-sm-6 col-xs-12">
                                <ng-container
                                    *ngFor="
                                        let attachment of attachments;
                                        let i = index
                                    "
                                >
                                    <br *ngIf="i != 0" /><a
                                        href="{{
                                            getLinkForFile(
                                                attachment.FileResourceGuid
                                            )
                                        }}"
                                        >{{ attachment.FileDisplayName }}</a
                                    >
                                </ng-container>
                            </dd>
                        </dl>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="card">
                <div class="card-header">Location</div>
                <div class="card-body">
                    <pooling-agreement-detail-tract-map
                        *ngIf="!fetchingTracts"
                        #tractsMap
                        mapID="tractsMap{{ poolingAgreementTractType }}"
                        [sendingTractIDs]="getTractIDs(sendingTracts)"
                        [receivingTractIDs]="getTractIDs(receivingTracts)"
                    >
                    </pooling-agreement-detail-tract-map>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col">
            <div class="card">
                <div class="card-header">Tracts Involved</div>
                <div class="card-body">
                    <p>
                        The summary below outlines the quantity of water sent
                        and received by Tract. Note that the Pool for a given
                        Tract may update if a Tract moves to a different Pool
                        and the Current Available is the amount of water
                        available for the indicated Allocation Period and will
                        continue to update until the Allocation Period ends.
                    </p>
                    <table class="table table-condensed table-striped">
                        <thead>
                            <tr>
                                <th class="entity-info">Tract</th>
                                <th class="entity-info">Pool</th>
                                <th class="entity-info">
                                    <field-definition
                                        [fieldDefinitionType]="'FinalAcres'"
                                        class="ps-1"
                                    >
                                    </field-definition>
                                </th>
                                <th class="entity-info">
                                    Allocation (ac&#8209;in)
                                </th>
                                <th class="agreement-info">
                                    Water Sent (ac&#8209;in)
                                </th>
                                <th class="agreement-info">
                                    Water Received (ac&#8209;in)
                                </th>
                                <th class="agreement-info">
                                    Current Available
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let tract of allTracts">
                                <td>
                                    <a
                                        routerLink="/tracts/{{ tract.TractID }}"
                                        >{{ tract.TractName }}</a
                                    >
                                </td>
                                <td>
                                    <a routerLink="/pools/{{ tract.PoolID }}">{{
                                        tract.PoolName
                                    }}</a>
                                </td>
                                <td>
                                    {{ tract.FinalAcres | number: "1.1-2" }}
                                </td>
                                <td>
                                    {{ tract.Allocation | number: "1.1-2" }}
                                </td>
                                <td>{{ tract.WaterSent }}</td>
                                <td>{{ tract.WaterReceived }}</td>
                                <td>
                                    {{
                                        tract.CurrentAvailable | number: "1.1-2"
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
