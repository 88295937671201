import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DownloadService } from 'src/app/shared/generated/api/download.service';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { UserDto } from 'src/app/shared/generated/model/user-dto';

@Component({
  selector: 'splash-annual-report',
  templateUrl: './annual-report.component.html',
  styleUrls: ['./annual-report.component.scss'],
})
export class AnnualReportComponent implements OnInit, OnDestroy {
  private currentUser: UserDto;

  public richTextTypeID: number = CustomRichTextTypeEnum.NeDNRAnnualReport;

  public uploadError: boolean = false;
  public uploadErrorMessage: string;
  public isDownloading: boolean = false;
  public reportingYear: number;
  public reportingYears: Array<number> = [];

  constructor(
    private authenticationService: AuthenticationService,
    private downloadService: DownloadService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.authenticationService.getCurrentUser().subscribe((currentUser) => {
      this.currentUser = currentUser;
      // TODO: grab this from api call?
      const startYear = 2007;
      const endYear = new Date().getFullYear();
      this.reportingYear = endYear - 1;
      this.reportingYears = Array.from(
        { length: endYear - startYear + 1 },
        (v, k) => k + startYear,
      ).sort((a, b) => (a > b ? -1 : 1));
    });
  }

  ngOnDestroy(): void {
    this.cdr.detach();
  }

  public currentUserIsAdmin(): boolean {
    return this.authenticationService.isUserAnAdministrator(
      this.currentUser,
    );
  }

  public downloadAnnualReport() {
    this.uploadError = false;
    this.uploadErrorMessage = null;
    this.isDownloading = true;
    this.downloadService
      .downloadNednrannualreportYearGet(this.reportingYear)
      .subscribe(
        (result) => {
          const blob = new Blob([result], {
            type: 'application/zip',
          });

          //Create a fake object to trigger downloading the zip file that was returned
          const a: any = document.createElement('a');
          document.body.appendChild(a);

          a.style = 'display: none';
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = `${this.reportingYear}_nednr_annual_report.zip`;
          a.click();
          window.URL.revokeObjectURL(url);
          this.isDownloading = false;
        },
        (error) => {
          this.uploadError = true;
          //Because our return type is ArrayBuffer, the message will be ugly. Convert it and display
          const decodedString = String.fromCharCode.apply(
            null,
            new Uint8Array(error.error) as any,
          );
          this.uploadErrorMessage = decodedString;
          this.isDownloading = false;
        },
      );
  }
}
