import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DecimalPipe } from '@angular/common';
import { ColDef } from 'ag-grid-community';
import { LinkRendererComponent } from 'src/app/shared/components/ag-grid/link-renderer/link-renderer.component';
import { forkJoin } from 'rxjs';
import { AgGridAngular } from 'ag-grid-angular';
import { UtilityFunctionsService } from 'src/app/services/utility-functions.service';
import { FieldDefinitionGridHeaderComponent } from 'src/app/shared/components/field-definition-grid-header/field-definition-grid-header.component';
import { BeehiveSyncRunDto } from 'src/app/shared/generated/model/beehive-sync-run-dto';
import { BeehiveService } from 'src/app/shared/generated/api/beehive.service';
import { TractService } from 'src/app/shared/generated/api/tract.service';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { TractWithAccountDto } from 'src/app/shared/generated/model/tract-with-account-dto';
import { CustomDropdownFilterComponent } from 'src/app/shared/components/ag-grid/custom-dropdown-filter/custom-dropdown-filter.component';

@Component({
  selector: 'splash-tract-list',
  templateUrl: './tract-list.component.html',
  styleUrls: ['./tract-list.component.scss'],
})
export class TractListComponent implements OnInit, OnDestroy {
  @ViewChild('tractsGrid') tractsGrid: AgGridAngular;

  private currentUser: UserDto;

  public richTextTypeID: number = CustomRichTextTypeEnum.TractList;
  public tracts: TractWithAccountDto[];
  public columnDefs: ColDef[];
  public defaultColDef: ColDef;
  public latestBeehiveSyncRun: BeehiveSyncRunDto;

  constructor(
    private cdr: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private utilityFunctionsService: UtilityFunctionsService,
    private tractService: TractService,
    private beehiveService: BeehiveService,
    private decimalPipe: DecimalPipe,
  ) {}

  ngOnInit() {
    this.authenticationService.getCurrentUser().subscribe((currentUser) => {
      this.currentUser = currentUser;

      forkJoin({
        tracts: this.tractService.tractsGet(),
        latestBeehiveSyncRun:
                    this.beehiveService.beehiveLatestSyncRunGet(),
      }).subscribe(({ tracts, latestBeehiveSyncRun }) => {
        this.tracts = tracts;
        this.latestBeehiveSyncRun = latestBeehiveSyncRun;

        this.cdr.detectChanges();
      });

      const _decimalPipe = this.decimalPipe;
      this.columnDefs = [
        {
          headerName: 'Tract #',
          valueGetter: (params) => {
            return {
              LinkDisplay: params.data.TractNumber,
              LinkValue: params.data.TractID,
            };
          },
          cellRenderer: LinkRendererComponent,
          cellRendererParams: { inRouterLink: '/tracts/' },
          filterValueGetter: (params) => params.data.TractNumber,
          comparator:
                        this.utilityFunctionsService.linkRendererComparator,
          width: 80,
        },
        { headerName: 'APN', field: 'ParcelNumber', width: 90 },
        {
          field: 'TractType',
          headerComponent: FieldDefinitionGridHeaderComponent,
          headerComponentParams: { fieldDefinitionType: 'Type' },
        },
        {
          field: 'TractCategory',
          headerComponent: FieldDefinitionGridHeaderComponent,
          headerComponentParams: { fieldDefinitionType: 'Category' },
          width: 118,
          filter: CustomDropdownFilterComponent,
          filterParams: { field: 'TractCategory' },
        },
        {
          field: 'TractIrrigationMethod',
          headerComponent: FieldDefinitionGridHeaderComponent,
          headerComponentParams: {
            fieldDefinitionType: 'IrrigationMethod',
          },
          width: 165,
        },
        this.utilityFunctionsService.createDecimalColumnDef(
          '# of Wells',
          'NumberOfWells',
          95,
          0,
        ),
        {
          headerName: 'Pool',
          valueGetter: (params) => {
            return {
              LinkDisplay:
                                params.data.Pool === null
                                  ? ''
                                  : params.data.Pool.PoolName,
              LinkValue:
                                params.data.Pool === null
                                  ? ''
                                  : params.data.Pool.PoolID,
            };
          },
          cellRenderer: LinkRendererComponent,
          cellRendererParams: { inRouterLink: '/pools/' },
          filterValueGetter: (params) => params.data.Pool?.PoolName,
          comparator:
                        this.utilityFunctionsService.linkRendererComparator,
          width: 100,
        },
        {
          headerName: 'Pool Type',
          field: 'Pool.PoolTypeName',
          width: 100,
          filter: CustomDropdownFilterComponent,
          filterParams: { field: 'Pool.PoolTypeName' },
        },
        {
          headerName: 'Landowner',
          valueGetter: (params) => {
            return {
              LinkValue:
                                params.data.Landowner === null
                                  ? ''
                                  : params.data.Landowner.AccountID,
              LinkDisplay:
                                params.data.Landowner === null
                                  ? ''
                                  : params.data.Landowner.FullName,
            };
          },
          cellRenderer: LinkRendererComponent,
          cellRendererParams: { inRouterLink: '/accounts/' },
          filterValueGetter: (params) =>
            params.data.Landowner?.FullName,
          comparator:
                        this.utilityFunctionsService.linkRendererComparator,
          width: 120,
        },
        {
          headerName: 'Prim Oper',
          valueGetter: (params) => {
            return {
              LinkValue:
                                params.data.PrimaryOperator === null
                                  ? ''
                                  : params.data.PrimaryOperator.AccountID,
              LinkDisplay:
                                params.data.PrimaryOperator === null
                                  ? ''
                                  : params.data.PrimaryOperator.FullName,
            };
          },
          cellRenderer: LinkRendererComponent,
          cellRendererParams: { inRouterLink: '/accounts/' },
          filterValueGetter: (params) =>
            params.data.PrimaryOperator?.FullName,
          comparator:
                        this.utilityFunctionsService.linkRendererComparator,
          width: 120,
        },
        {
          headerName: 'Sec Oper',
          valueGetter: (params) => {
            return {
              LinkValue:
                                params.data.SecondaryOperator === null
                                  ? ''
                                  : params.data.SecondaryOperator.AccountID,
              LinkDisplay:
                                params.data.SecondaryOperator === null
                                  ? ''
                                  : params.data.SecondaryOperator.FullName,
            };
          },
          cellRenderer: LinkRendererComponent,
          cellRendererParams: { inRouterLink: '/accounts/' },
          filterValueGetter: (params) =>
            params.data.SecondaryOperator?.FullName,
          comparator:
                        this.utilityFunctionsService.linkRendererComparator,
          width: 120,
        },
        {
          headerName: 'Tert Oper',
          valueGetter: (params) => {
            return {
              LinkValue:
                                params.data.TertiaryOperator === null
                                  ? ''
                                  : params.data.TertiaryOperator.AccountID,
              LinkDisplay:
                                params.data.TertiaryOperator === null
                                  ? ''
                                  : params.data.TertiaryOperator.FullName,
            };
          },
          cellRenderer: LinkRendererComponent,
          cellRendererParams: { inRouterLink: '/accounts/' },
          filterValueGetter: (params) =>
            params.data.TertiaryOperator?.FullName,
          comparator:
                        this.utilityFunctionsService.linkRendererComparator,
          width: 120,
        },
        {
          headerName: 'Rep',
          valueGetter: (params) => {
            return {
              LinkValue:
                                params.data.Rep === null
                                  ? ''
                                  : params.data.Rep.AccountID,
              LinkDisplay:
                                params.data.Rep === null
                                  ? ''
                                  : params.data.Rep.FullName,
            };
          },
          cellRenderer: LinkRendererComponent,
          cellRendererParams: { inRouterLink: '/accounts/' },
          filterValueGetter: (params) => params.data.Rep?.FullName,
          comparator:
                        this.utilityFunctionsService.linkRendererComparator,
          width: 120,
        },
        {
          valueGetter: (params) => {
            return {
              LinkValue:
                                params.data.GWMA2 === null
                                  ? ''
                                  : params.data.GWMA2.AccountID,
              LinkDisplay:
                                params.data.GWMA2 === null
                                  ? ''
                                  : params.data.GWMA2.FullName,
            };
          },
          cellRenderer: LinkRendererComponent,
          cellRendererParams: { inRouterLink: '/accounts/' },
          filterValueGetter: (params) => params.data.GWMA2?.FullName,
          comparator:
                        this.utilityFunctionsService.linkRendererComparator,
          headerComponent: FieldDefinitionGridHeaderComponent,
          headerComponentParams: {
            fieldDefinitionType: 'GWMANumber2',
          },
          width: 180,
        },
        { headerName: 'Farm Name', field: 'GWMA2FarmName', width: 170 },
        {
          headerName: 'GW Transfer #',
          field: 'GroundwaterTransferNumbers',
          width: 130,
        },
        {
          field: 'FinalAcres',
          valueFormatter: function (params) {
            return _decimalPipe.transform(params.value, '1.1-1');
          },
          headerComponent: FieldDefinitionGridHeaderComponent,
          headerComponentParams: {
            fieldDefinitionType: 'FinalAcres',
          },
          width: 127,
        },
        {
          field: 'AssessorAcres',
          valueFormatter: function (params) {
            return _decimalPipe.transform(params.value, '1.1-1');
          },
          headerComponent: FieldDefinitionGridHeaderComponent,
          headerComponentParams: {
            fieldDefinitionType: 'AssesorAcres',
          },
          width: 142,
        },
        {
          field: 'ReportedAcres',
          valueFormatter: function (params) {
            return _decimalPipe.transform(params.value, '1.1-1');
          },
          headerComponent: FieldDefinitionGridHeaderComponent,
          headerComponentParams: {
            fieldDefinitionType: 'ReportedAcres',
          },
          width: 150,
        },
        {
          field: 'ProvisionalAcres',
          valueFormatter: function (params) {
            return _decimalPipe.transform(params.value, '1.1-1');
          },
          headerComponent: FieldDefinitionGridHeaderComponent,
          headerComponentParams: {
            fieldDefinitionType: 'ProvisionalAcres',
          },
          width: 155,
        },
        {
          field: 'Legal',
          headerComponent: FieldDefinitionGridHeaderComponent,
          headerComponentParams: { fieldDefinitionType: 'Legal' },
          width: 98,
        },
        { headerName: 'Section', field: 'Section', width: 90 },
        { headerName: 'Township', field: 'Township', width: 110 },
        { headerName: 'Range', field: 'Range', width: 90 },
        {
          headerName: 'Range Dir',
          field: 'RangeDirection',
          width: 120,
        },
        {
          field: 'NRDAdjustment',
          headerComponent: FieldDefinitionGridHeaderComponent,
          headerComponentParams: {
            fieldDefinitionType: 'NRDAdjustment',
          },
          width: 159,
        },
        {
          field: 'TractPhase',
          headerComponent: FieldDefinitionGridHeaderComponent,
          headerComponentParams: { fieldDefinitionType: 'Phase' },
          width: 98,
        },
        {
          headerName: 'Certification Notes',
          field: 'CertificationNotes',
          width: 200,
        },
      ];

      this.defaultColDef = {
        sortable: true,
        filter: true,
        resizable: true,
      };
    });
  }

  ngOnDestroy() {
    this.cdr.detach();
  }

  public updateGridData() {
    this.tractService.tractsGet().subscribe((result) => {
      this.tractsGrid.api.setRowData(result);
    });
  }
}
