import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { forkJoin } from 'rxjs';
import { PoolService } from 'src/app/shared/generated/api/pool.service';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { TractWithAccountDto } from 'src/app/shared/generated/model/tract-with-account-dto';
import { TractWellDto } from 'src/app/shared/generated/model/tract-well-dto';
import { TractGroundwaterTransferDto } from 'src/app/shared/generated/model/tract-groundwater-transfer-dto';
import { PhaseReportSimpleDto } from 'src/app/shared/generated/model/phase-report-simple-dto';
import { CropGrownDto } from 'src/app/shared/generated/model/crop-grown-dto';
import { TractService } from 'src/app/shared/generated/api/tract.service';
import { NitrogenReportService } from 'src/app/shared/generated/api/nitrogen-report.service';

@Component({
  selector: 'template-tract-detail',
  templateUrl: './tract-detail.component.html',
  styleUrls: ['./tract-detail.component.scss'],
})
export class TractDetailComponent implements OnInit, OnDestroy {
  private currentUser: UserDto;

  public tract: TractWithAccountDto;
  public tractWells: TractWellDto[];
  public poolTracts: TractWithAccountDto[];
  public tractGroundwaterTransfers: TractGroundwaterTransferDto[];
  public phaseReports: PhaseReportSimpleDto[];
  public nitrogenReportYears: number[];
  public cropsGrown: CropGrownDto[];

  public today: Date = new Date();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private tractService: TractService,
    private poolService: PoolService,
    private nitrogenReportService: NitrogenReportService,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
  ) {
    // force route reload whenever params change;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    this.authenticationService.getCurrentUser().subscribe((currentUser) => {
      this.currentUser = currentUser;

      const id = parseInt(this.route.snapshot.paramMap.get('id'));
      if (!id) return;

      forkJoin({
        tract: this.tractService.tractsTractIDGet(id),
        tractWells: this.tractService.tractsTractIDWellsGet(id),
        tractGroundwaterTransfers:
                    this.tractService.tractsTractIDGroundwaterTransfersGet(id),
        phaseReports:
                    this.tractService.tractsTractIDPhaseReportsGet(id),
        cropsGrown: this.tractService.tractsTractIDCropsGrownGet(id),
        nitrogenReportYears:
                    this.nitrogenReportService.nitrogenReportYearsGet(),
      }).subscribe(
        ({
          tract,
          tractWells,
          tractGroundwaterTransfers,
          phaseReports,
          cropsGrown,
          nitrogenReportYears,
        }) => {
          this.tract = tract;
          this.tractWells = tractWells;
          this.tractGroundwaterTransfers = tractGroundwaterTransfers;
          this.phaseReports = phaseReports;
          this.cropsGrown = cropsGrown;
          this.nitrogenReportYears = nitrogenReportYears;

          if (this.tract.Pool) {
            this.poolService
              .poolsPoolIDTractsGet(this.tract.Pool.PoolID)
              .subscribe((poolTracts) => {
                this.poolTracts = poolTracts;
              });
          }

          this.cdr.detectChanges();
        },
      );
    });
  }

  ngOnDestroy() {
    this.cdr.detach();
  }

  public getSelectedTractIDs(): Array<number> {
    return this.tract !== undefined ? [this.tract.TractID] : [];
  }

  public getVisibleWellIDs(): Array<number> {
    return this.tractWells !== undefined
      ? this.tractWells.map((x) => x.Well.WellID)
      : [];
  }

  public getVisibleTractIDs(): Array<number> {
    return this.poolTracts !== undefined
      ? this.poolTracts.map((x) => x.TractID)
      : [this.tract.TractID];
  }
}
