import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ColDef } from 'ag-grid-community';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UtilityFunctionsService } from 'src/app/services/utility-functions.service';
import { LinkRendererComponent } from 'src/app/shared/components/ag-grid/link-renderer/link-renderer.component';
import { forkJoin } from 'rxjs';
import { PoolCalculationRunDto } from 'src/app/shared/generated/model/pool-calculation-run-dto';
import { PoolService } from 'src/app/shared/generated/api/pool.service';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { PoolWithAllocationPlanValuesDto } from 'src/app/shared/generated/model/pool-with-allocation-plan-values-dto';
import { CustomDropdownFilterComponent } from 'src/app/shared/components/ag-grid/custom-dropdown-filter/custom-dropdown-filter.component';

@Component({
  selector: 'splash-pool-list',
  templateUrl: './pool-list.component.html',
  styleUrls: ['./pool-list.component.scss'],
})
export class PoolListComponent implements OnInit, OnDestroy {
  @ViewChild('poolsGrid') poolsGrid: AgGridAngular;

  private currentUser: UserDto;

  public richTextTypeID: number = CustomRichTextTypeEnum.PoolList;

  public pools: PoolWithAllocationPlanValuesDto[];
  public columnDefs: ColDef[];
  public defaultColDef: ColDef;
  public showOnlyActivePools: boolean = true;
  public isLoadingSubmit: boolean;
  public closeResult: string;
  public modalReference: NgbModalRef;
  public latestCalculationRun: PoolCalculationRunDto;

  constructor(
    private poolService: PoolService,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private utilityFunctionsService: UtilityFunctionsService,
  ) {}

  ngOnInit() {
    this.closeResult = '';

    this.authenticationService.getCurrentUser().subscribe((currentUser) => {
      this.currentUser = currentUser;

      this.columnDefs = [
        {
          headerName: 'Pool Name',
          valueGetter: function (params: any) {
            return {
              LinkValue: params.data.PoolID,
              LinkDisplay: params.data.PoolName,
            };
          },
          cellRenderer: LinkRendererComponent,
          cellRendererParams: { inRouterLink: '/pools/' },
          filterValueGetter: (params) => params.data.PoolName,
          comparator:
                        this.utilityFunctionsService.linkRendererComparator,
          sortable: true,
          filter: true,
          width: 170,
        },
        {
          headerName: 'Type',
          field: 'PoolTypeDisplayName',
          filter: CustomDropdownFilterComponent,
          filterParams: { field: 'PoolTypeDisplayName' },
        },
        this.utilityFunctionsService.createDecimalColumnDef(
          '# of Wells',
          'NumWells',
          120,
          0,
        ),
        this.utilityFunctionsService.createDecimalColumnDef(
          '# of Tracts',
          'NumTracts',
          120,
          0,
        ),
        this.utilityFunctionsService.createDecimalColumnDef(
          'Total Acres',
          'TotalAcres',
          120,
          2,
        ),
        // { headerName: 'Allocation (ac-in)', field: 'AllocatedAcreInches', width:140},
        // { headerName: 'Water Usage', field: 'WaterUsage', width:120}
      ];

      this.defaultColDef = {
        sortable: true,
        filter: true,
        resizable: true,
      };

      this.refreshPoolsData();
    });
  }

  ngOnDestroy(): void {
    this.cdr.detach();
  }

  private refreshPoolsData() {
    this.poolsGrid?.api.showLoadingOverlay();

    forkJoin({
      pools: this.poolService.poolsGet(),
      latestCalculationRun:
                this.poolService.poolsLatestCalculationRunGet(),
    }).subscribe(({ pools, latestCalculationRun }) => {
      this.pools = pools;
      this.latestCalculationRun = latestCalculationRun;

      this.poolsGrid?.api.hideOverlay();
    });
  }
}
