<nav aria-label="breadcrumb" *ngIf="tract">
    <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">
            <a routerLink="/tracts">Tracts</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
            Tract #{{ tract.TractNumber }}
        </li>
    </ol>
</nav>
<div class="container mt-sm-4" *ngIf="tract">
    <h2 class="d-inline-block">Tract #{{ tract.TractNumber }}</h2>
    <app-alert-display></app-alert-display>
    <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
            <div class="card">
                <div class="card-header">Basics</div>
                <div class="card-body">
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">Pool</dt>
                        <dd class="col-sm-6 col-xs-12">
                            <a
                                *ngIf="tract.Pool"
                                routerLink="/pools/{{ tract.Pool.PoolID }}"
                                >{{ tract.Pool.PoolTypeName }}:
                                {{ tract.Pool.PoolName }}</a
                            >
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">APN</dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ tract.ParcelNumber }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            <field-definition
                                [fieldDefinitionType]="'Type'"
                            ></field-definition>
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ tract.TractType }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            <field-definition
                                [fieldDefinitionType]="'Category'"
                            ></field-definition>
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ tract.TractCategory }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            <field-definition
                                [fieldDefinitionType]="'IrrigationMethod'"
                            ></field-definition>
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ tract.TractIrrigationMethod }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            <field-definition
                                [fieldDefinitionType]="'FinalAcres'"
                            ></field-definition>
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ tract.FinalAcres | number: "1.1-1" }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            <field-definition
                                [fieldDefinitionType]="'AssesorAcres'"
                            ></field-definition>
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ tract.AssessorAcres | number: "1.1-1" }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            <field-definition
                                [fieldDefinitionType]="'ReportedAcres'"
                            ></field-definition>
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ tract.ReportedAcres | number: "1.1-1" }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            <field-definition
                                [fieldDefinitionType]="'ProvisionalAcres'"
                            ></field-definition>
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ tract.ProvisionalAcres | number: "1.1-1" }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            Landowner
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            <a
                                *ngIf="tract.Landowner"
                                routerLink="/accounts/{{
                                    tract.Landowner.AccountID
                                }}"
                                >{{ tract.Landowner.FullName }}</a
                            >
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            Primary Operator
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            <a
                                *ngIf="tract.PrimaryOperator"
                                routerLink="/accounts/{{
                                    tract.PrimaryOperator.AccountID
                                }}"
                                >{{ tract.PrimaryOperator.FullName }}</a
                            >
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            Secondary Operator
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            <a
                                *ngIf="tract.SecondaryOperator"
                                routerLink="/accounts/{{
                                    tract.SecondaryOperator.AccountID
                                }}"
                                >{{ tract.SecondaryOperator.FullName }}</a
                            >
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            Tertiary Operator
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            <a
                                *ngIf="tract.TertiaryOperator"
                                routerLink="/accounts/{{
                                    tract.TertiaryOperator.AccountID
                                }}"
                                >{{ tract.TertiaryOperator.FullName }}</a
                            >
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">Rep</dt>
                        <dd class="col-sm-6 col-xs-12">
                            <a
                                *ngIf="tract.Rep"
                                routerLink="/accounts/{{ tract.Rep.AccountID }}"
                                >{{ tract.Rep.FullName }}</a
                            >
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            <field-definition
                                [fieldDefinitionType]="'GWMANumber2'"
                            ></field-definition>
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            <a
                                *ngIf="tract.GWMA2"
                                routerLink="/accounts/{{
                                    tract.GWMA2.AccountID
                                }}"
                                >{{ tract.GWMA2.FullName }}</a
                            >
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            Farm Name
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ tract.GWMA2FarmName }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            Certification Notes
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ tract.CertificationNotes }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            <field-definition
                                [fieldDefinitionType]="'NRDAdjustment'"
                            ></field-definition>
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ tract.NRDAdjustment | number: "1.1-1" }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            <field-definition
                                [fieldDefinitionType]="'Legal'"
                            ></field-definition>
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ tract.Legal }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            Section/Township/Range
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ tract.Section }}-T{{ tract.Township }}-R{{
                                tract.Range
                            }}{{ tract.RangeDirection }}
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="text-sm-end col-sm-6 col-xs-12">
                            <field-definition
                                [fieldDefinitionType]="'Phase'"
                            ></field-definition>
                        </dt>
                        <dd class="col-sm-6 col-xs-12">
                            {{ tract.TractPhase }}
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-6">
            <div class="card">
                <div class="card-header">Location</div>
                <div class="card-body">
                    <div class="row">
                        <div
                            class="col-sm-12"
                            *ngIf="tract && (!tract.Pool || poolTracts)"
                        >
                            <tract-map
                                mapID="tractsMapForTract"
                                [selectedTractIDs]="getSelectedTractIDs()"
                                [visibleTractIDs]="getVisibleTractIDs()"
                                [visibleWellIDs]="getVisibleWellIDs()"
                                [displayWells]="true"
                                [disableDefaultClick]="true"
                                mapHeight="500px"
                            >
                            </tract-map>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mt-4" *ngIf="tractWells">
                <div class="card-header">Related Wells</div>
                <div
                    class="card-body table-responsive"
                    style="max-height: 500px; overflow-y: auto"
                >
                    <ng-template #noTractWells>
                        <p>No wells related to this tract.</p>
                    </ng-template>
                    <table
                        class="table table-condensed table-striped"
                        *ngIf="tractWells.length > 0; else noTractWells"
                    >
                        <thead>
                            <tr>
                                <th>Well</th>
                                <th>
                                    <field-definition
                                        [fieldDefinitionType]="'PrimaryUse'"
                                    ></field-definition>
                                </th>
                                <th>Relationship</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let tractWell of tractWells">
                                <td style="border: 0 none; white-space: nowrap">
                                    <a
                                        routerLink="/wells/{{
                                            tractWell.Well.WellID
                                        }}"
                                        >{{ tractWell.Well.WellName }}</a
                                    >
                                </td>
                                <td style="border: 0 none">
                                    {{ tractWell.Well.WellUse }}
                                </td>
                                <td style="border: 0 none">
                                    {{ tractWell.RelationshipType }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card mt-4" *ngIf="tractGroundwaterTransfers">
                <div class="card-header">Groundwater Transfers</div>
                <div
                    class="card-body table-responsive"
                    style="max-height: 500px; overflow-y: auto"
                >
                    <ng-template #noTractGroundwaterTransfers>
                        <p>No groundwater transfers related to this tract.</p>
                    </ng-template>
                    <table
                        class="table table-condensed table-striped"
                        *ngIf="
                            tractGroundwaterTransfers.length > 0;
                            else noTractGroundwaterTransfers
                        "
                    >
                        <thead>
                            <tr>
                                <th>Transfer #</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="
                                    let tractGroundwaterTransfer of tractGroundwaterTransfers
                                "
                            >
                                <td style="border: 0 none">
                                    {{
                                        tractGroundwaterTransfer.GroundwaterTransferNumber
                                    }}
                                </td>
                                <td style="border: 0 none">
                                    {{
                                        tractGroundwaterTransfer.GroundwaterTransferStatus
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-sm-12">
            <div class="card mt-4">
                <div class="card-header">
                    Related Nitrogen Report Crops Grown
                </div>
                <div
                    class="card-body table-responsive"
                    style="max-height: 500px; overflow-y: auto"
                >
                    <ng-template #noCropsGrown>
                        <p>
                            No nitrogen report crops grown found for this tract.
                        </p>
                    </ng-template>
                    <ng-template ngFor let-cropGrown [ngForOf]="cropsGrown">
                        <h4>{{ cropGrown.CropName }}</h4>
                        <table
                            class="table table-striped table-condensed"
                            *ngIf="
                                cropsGrown && cropsGrown.length > 0;
                                else noCropsGrown
                            "
                        >
                            <thead>
                                <tr>
                                    <th style="width: 25%">Property</th>
                                    <th
                                        style="width: 15%"
                                        *ngFor="
                                            let nitrogenReportYear of nitrogenReportYears
                                        "
                                    >
                                        {{ nitrogenReportYear }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style="white-space: nowrap">Soil N</td>
                                    <td
                                        *ngFor="
                                            let cropGrownAnnualPractice of cropGrown.CropGrownAnnualPractices
                                        "
                                    >
                                        {{
                                            cropGrownAnnualPractice.SoilNFormatted
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="white-space: nowrap">
                                        Residual N
                                    </td>
                                    <td
                                        *ngFor="
                                            let cropGrownAnnualPractice of cropGrown.CropGrownAnnualPractices
                                        "
                                    >
                                        {{
                                            cropGrownAnnualPractice.ResidualNFormatted
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="white-space: nowrap">
                                        Organic Matter
                                    </td>
                                    <td
                                        *ngFor="
                                            let cropGrownAnnualPractice of cropGrown.CropGrownAnnualPractices
                                        "
                                    >
                                        {{
                                            cropGrownAnnualPractice.OrganicMatterFormatted
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-sm-12">
            <div class="card mt-4">
                <div class="card-header">Related Nitrogen Phase Reports</div>
                <div
                    class="card-body table-responsive"
                    style="max-height: 500px; overflow-y: auto"
                >
                    <ng-template #noPhaseReports>
                        <p>No phase reports related to this tract.</p>
                    </ng-template>
                    <table
                        class="table table-condensed table-striped"
                        *ngIf="phaseReports.length > 0; else noPhaseReports"
                    >
                        <thead>
                            <tr>
                                <th>Phase Report Name</th>
                                <th>Report Date</th>
                                <th>Status</th>
                                <th>GWMA Cooperator</th>
                                <th>Farm Name</th>
                                <th># of Practices</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let phaseReport of phaseReports">
                                <td style="border: 0 none; white-space: nowrap">
                                    <a
                                        routerLink="/phase-reports/{{
                                            phaseReport.PhaseReportID
                                        }}"
                                        >{{ phaseReport.PhaseReportName }}</a
                                    >
                                </td>
                                <td style="border: 0 none">
                                    {{
                                        phaseReport.ReportDate
                                            | date: "M/d/yyyy"
                                    }}
                                </td>
                                <td style="border: 0 none">
                                    {{ phaseReport.PhaseReportStatus }}
                                </td>
                                <td style="border: 0 none; white-space: nowrap">
                                    <a
                                        routerLink="/accounts/{{
                                            phaseReport.GWMACooperatorID
                                        }}"
                                        >{{
                                            phaseReport.GWMACooperatorFullName
                                        }}</a
                                    >
                                </td>
                                <td style="border: 0 none">
                                    {{ phaseReport.FarmName }}
                                </td>
                                <td style="border: 0 none">
                                    {{ phaseReport.PracticeCount }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
