import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ApplicationRef,
} from '@angular/core';
import { CustomCompileService } from 'src/app/shared/services/custom-compile.service';
import { environment } from 'src/environments/environment';
import * as L from 'leaflet';
import '../../../../../node_modules/leaflet.fullscreen/Control.FullScreen.js';
import { TractService } from 'src/app/shared/generated/api/tract.service';
import { BoundingBoxDto } from 'src/app/shared/generated/model/bounding-box-dto';

declare let $: any;

@Component({
  selector: 'pooling-agreement-detail-tract-map',
  templateUrl: './pooling-agreement-detail-tract-map.component.html',
  styleUrls: ['./pooling-agreement-detail-tract-map.component.scss'],
})
export class PoolingAgreementDetailTractMapComponent implements OnInit {
  @Input() public mapID: string = '';

  @Input() public sendingTractsStyle: string = 'tract_orange';

  @Input() public receivingTractsStyle: string = 'tract_blue';

  @Input() public sendingTractIDs: Array<number> = [];

  @Input() public receivingTractIDs: Array<number> = [];

  @Input() public zoomMapToDefaultExtent: boolean = true;

  @Input() public mapHeight: string = '400px';

  @Input() public defaultFitBoundsOptions?: L.FitBoundsOptions = null;

  @Output() public afterSetControl: EventEmitter<L.Control.Layers> =
    new EventEmitter();

  @Output() public afterLoadMap: EventEmitter<L.LeafletEvent> =
    new EventEmitter();

  @Output() public onMapMoveEnd: EventEmitter<L.LeafletEvent> =
    new EventEmitter();

  public component: any;
  public map: L.Map;
  public featureLayer: any;
  public layerControl: L.Control.Layers;
  public tileLayers: { [key: string]: any } = {};
  public overlayLayers: { [key: string]: any } = {};
  public boundingBox: BoundingBoxDto;
  private defaultTractsWMSOptions: L.WMSOptions;
  public sendingTractsLayer: any;
  public receivingTractsLayer: any;
  public tractsWMSOptions: any;

  constructor(
    private tractService: TractService,
    private appRef: ApplicationRef,
    private compileService: CustomCompileService,
  ) {}

  public ngOnInit(): void {
    // Default bounding box
    this.boundingBox = new BoundingBoxDto();
    this.boundingBox.Left = -98.49706346987776;
    this.boundingBox.Bottom = 40.4303080924072;
    this.boundingBox.Right = -96.95153112621863;
    this.boundingBox.Top = 41.269115170389895;

    this.tileLayers = Object.assign(
      {},
      {
        Aerial: L.tileLayer(
          'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          {
            attribution: 'Aerial',
          },
        ),
        Street: L.tileLayer(
          'https://services.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
          {
            attribution: 'Aerial',
          },
        ),
        Terrain: L.tileLayer(
          'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
          {
            attribution: 'Terrain',
          },
        ),
      },
      this.tileLayers,
    );

    this.defaultTractsWMSOptions = {
      layers: 'Splash:Tracts',
      transparent: true,
      format: 'image/png',
      tiled: true,
    } as L.WMSOptions;

    this.sendingTractsLayer = this.prepareTractLayer(
      this.sendingTractIDs,
      this.sendingTractsStyle,
    );
    this.receivingTractsLayer = this.prepareTractLayer(
      this.receivingTractIDs,
      this.receivingTractsStyle,
    );

    this.fitBoundsToTracts();

    this.overlayLayers = Object.assign(
      {
        '<img src=\'./assets/main/map-legend-images/tract_orange.png\' style=\'height:16px; margin-bottom:3px\'> Sending Tracts':
                    this.sendingTractsLayer,
        '<img src=\'./assets/main/map-legend-images/tract_blue.png\' style=\'height:16px; margin-bottom:3px\'> Receiving Tracts':
                    this.receivingTractsLayer,
      },
      this.overlayLayers,
    );

    this.compileService.configure(this.appRef);
  }

  public prepareTractLayer(tractIDs: Array<number>, style: string): any {
    const WMSOptions = Object.assign(
      { styles: style },
      this.defaultTractsWMSOptions,
    );
    if (tractIDs.length > 0) {
      WMSOptions.cql_filter = this.createTractMapFilter(tractIDs);
    }
    const layer = L.tileLayer.wms(
      environment.geoserverMapServiceUrl + '/wms?',
      WMSOptions,
    );
    return layer;
  }

  public fitBoundsToTracts() {
    const tractIDs = this.sendingTractIDs.concat(this.receivingTractIDs);
    this.tractService
      .tractsBoundingBoxPut(tractIDs)
      .subscribe((boundingBox) => {
        this.boundingBox = boundingBox;
        this.map.fitBounds(
          [
            [this.boundingBox.Bottom, this.boundingBox.Left],
            [this.boundingBox.Top, this.boundingBox.Right],
          ],
          this.defaultFitBoundsOptions,
        );
      });
  }

  private createTractMapFilter(tractIDs: Array<number>): any {
    return `TractID in (${tractIDs.join(',')})`;
  }

  public ngAfterViewInit(): void {
    const mapOptions: L.MapOptions = {
      // center: [46.8797, -110],
      // zoom: 6,
      minZoom: 9,
      maxZoom: 17,
      layers: [
        this.tileLayers.Aerial,
        this.overlayLayers[
          '<img src=\'./assets/main/map-legend-images/tract_orange.png\' style=\'height:16px; margin-bottom:3px\'> Sending Tracts'
        ],
        this.overlayLayers[
          '<img src=\'./assets/main/map-legend-images/tract_blue.png\' style=\'height:16px; margin-bottom:3px\'> Receiving Tracts'
        ],
      ],
      fullscreenControl: true,
    } as L.MapOptions;
    this.map = L.map(this.mapID, mapOptions);

    this.map.on('load', (event: L.LeafletEvent) => {
      this.afterLoadMap.emit(event);
    });
    this.map.on('moveend', (event: L.LeafletEvent) => {
      this.onMapMoveEnd.emit(event);
    });
    this.map.fitBounds(
      [
        [this.boundingBox.Bottom, this.boundingBox.Left],
        [this.boundingBox.Top, this.boundingBox.Right],
      ],
      this.defaultFitBoundsOptions,
    );

    this.setControl();
    this.registerClickEvents();
  }

  public setControl(): void {
    this.layerControl = new L.Control.Layers(
      this.tileLayers,
      this.overlayLayers,
      { collapsed: false },
    ).addTo(this.map);
    this.afterSetControl.emit(this.layerControl);
  }

  public registerClickEvents(): void {
    const leafletControlLayersSelector = '.leaflet-control-layers';
    const closeButtonClass = 'leaflet-control-layers-close';

    const closem = L.DomUtil.create('a', closeButtonClass);
    closem.innerHTML = 'Close';
    L.DomEvent.on(closem, 'click', function () {
      $(leafletControlLayersSelector).removeClass(
        'leaflet-control-layers-expanded',
      );
    });

    $(leafletControlLayersSelector).append(closem);
  }
}
