<dl class="row mb-0">
    <dt class="col-5 text-end">Tract #</dt>
    <dd class="col-7">
        <a
            routerLink="/tracts/{{ feature.properties.TractID }}"
            (click)="detectChanges()"
        >
            {{ feature.properties.TractNumber }}
        </a>
    </dd>
</dl>
<dl class="row mb-0" *ngIf="feature.properties.PoolName">
    <dt class="col-5 text-end">Pool</dt>
    <dd class="col-7">
        <a
            routerLink="/pools/{{ feature.properties.PoolID }}"
            (click)="detectChanges()"
        >
            {{ feature.properties.PoolTypeName }}:
            {{ feature.properties.PoolName }}
        </a>
    </dd>
</dl>
<dl class="row mb-0">
    <dt class="col-5 text-end">APN</dt>
    <dd class="col-7">{{ feature.properties.ParcelNumber }}</dd>
</dl>
<dl class="row mb-0">
    <dt class="col-5 text-end">Acres</dt>
    <dd class="col-7">
        {{ feature.properties.FinalAcres | number: "1.1-1" }}
    </dd>
</dl>
<dl class="row mb-0" *ngIf="feature.properties.LandownerAccountID">
    <dt class="col-5 text-end">Landowner</dt>
    <dd class="col-7">
        <a
            routerLink="/accounts/{{ feature.properties.LandownerAccountID }}"
            (click)="detectChanges()"
        >
            {{ feature.properties.LandownerAccountFullName }}
        </a>
    </dd>
</dl>
<dl class="row mb-0" *ngIf="feature.properties.PrimaryOperatorAccountID">
    <dt class="col-5 text-end">Prim Oper</dt>
    <dd class="col-7">
        <a
            routerLink="/accounts/{{
                feature.properties.PrimaryOperatorAccountID
            }}"
            (click)="detectChanges()"
        >
            {{ feature.properties.PrimaryOperatorAccountFullName }}
        </a>
    </dd>
</dl>
