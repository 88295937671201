import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { forkJoin } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TractWellDto } from 'src/app/shared/generated/model/tract-well-dto';
import { WithdrawalDto } from 'src/app/shared/generated/model/withdrawal-dto';
import { PoolService } from 'src/app/shared/generated/api/pool.service';
import { WellService } from 'src/app/shared/generated/api/well.service';
import { WellDto } from 'src/app/shared/generated/model/well-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';

@Component({
  selector: 'splash-well-detail',
  templateUrl: './well-detail.component.html',
  styleUrls: ['./well-detail.component.scss'],
})
export class WellDetailComponent implements OnInit, OnDestroy {
  private currentUser: UserDto;

  public well: WellDto;
  public today: Date = new Date();
  public tractWells: TractWellDto[];
  public poolWells: WellDto[];
  public withdrawals: WithdrawalDto[];

  public visibleWellIDs: number[];
  public visibleTractIDs: number[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private wellService: WellService,
    private poolService: PoolService,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
  ) {
    // force route reload whenever params change;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    this.authenticationService.getCurrentUser().subscribe((currentUser) => {
      this.currentUser = currentUser;

      const wellID = parseInt(this.route.snapshot.paramMap.get('id'));
      if (!wellID) return;

      forkJoin({
        well: this.wellService.wellsWellIDGet(wellID),
        tractWells: this.wellService.wellsWellIDTractsGet(wellID),
        withdrawals: this.wellService.wellsWellIDWithdrawalsGet(wellID),
      }).subscribe(({ well, tractWells, withdrawals }) => {
        this.well = well;
        this.tractWells = tractWells;
        this.visibleTractIDs = tractWells.map((x) => x.Tract.TractID);
        this.withdrawals = withdrawals;

        if (this.well.Pool) {
          this.poolService
            .poolsPoolIDWellsGet(this.well.Pool.PoolID)
            .subscribe((wells) => {
              this.poolWells = wells;
              this.visibleWellIDs = wells.map((x) => x.WellID);
            });
        } else {
          this.visibleWellIDs = [this.well.WellID];
        }
        this.cdr.detectChanges();
      });
    });
  }

  ngOnDestroy() {
    this.cdr.detach();
  }
}
