import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LinkRendererComponent } from 'src/app/shared/components/ag-grid/link-renderer/link-renderer.component';
import { ColDef } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { FieldDefinitionDto } from 'src/app/shared/generated/model/field-definition-dto';
import { FieldDefinitionService } from 'src/app/shared/generated/api/field-definition.service';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { UtilityFunctionsService } from 'src/app/services/utility-functions.service';

@Component({
  selector: 'splash-field-definition-list',
  templateUrl: './field-definition-list.component.html',
  styleUrls: ['./field-definition-list.component.scss'],
})
export class FieldDefinitionListComponent implements OnInit {
  @ViewChild('fieldDefinitionsGrid') fieldDefinitionsGrid: AgGridAngular;

  private currentUser: UserDto;

  public fieldDefinitions: FieldDefinitionDto[];
  public richTextTypeID: number =
    CustomRichTextTypeEnum.LabelsAndDefinitionsList;
  public columnDefs: ColDef[];
  public defaultColDef: ColDef;

  constructor(
    private fieldDefinitionService: FieldDefinitionService,
    private authenticationService: AuthenticationService,
    private utilityFunctionsService: UtilityFunctionsService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.authenticationService.getCurrentUser().subscribe((currentUser) => {
      this.currentUser = currentUser;

      this.fieldDefinitionService
        .fieldDefinitionsGet()
        .subscribe((fieldDefinitions) => {
          this.fieldDefinitions = fieldDefinitions;
          this.cdr.detectChanges();
        });

      this.columnDefs = [
        {
          headerName: 'Label',
          valueGetter: function (params: any) {
            return {
              LinkValue:
                                params.data.FieldDefinitionType
                                  .FieldDefinitionTypeID,
              LinkDisplay:
                                params.data.FieldDefinitionType
                                  .FieldDefinitionTypeDisplayName,
            };
          },
          cellRenderer: LinkRendererComponent,
          cellRendererParams: {
            inRouterLink: '/labels-and-definitions/',
          },
          filterValueGetter: (params) =>
            params.data.FieldDefinitionType
              .FieldDefinitionTypeDisplayName,
          comparator:
                        this.utilityFunctionsService.linkRendererComparator,
          width: 200,
        },
        {
          headerName: 'Definition',
          field: 'FieldDefinitionValue',
          cellRenderer: (params) =>
            params.data.FieldDefinitionValue
              ? params.data.FieldDefinitionValue
              : '',
          autoHeight: true,
          width: 900,
          cellStyle: { 'white-space': 'normal' },
        },
      ];

      this.defaultColDef = {
        resizable: true,
        sortable: true,
        filter: true,
      };
    });
  }

  ngOnDestroy(): void {
    this.cdr.detach();
  }
}
