<div class="row">
    <div class="col-sm-8 col-md-9 offset-sm-4 offset-md-3">
        <p>
            Select a well or tract from the list or click a location on the map
            to explore.
        </p>
    </div>
</div>
<div class="row">
    <div
        class="col-sm-4 col-md-3 col-sm-pull-8 col-md-pull-9"
        [style.height]="mapHeight"
        style="overflow-y: auto"
    >
        <ul class="list-group">
            <li
                class="list-group-item"
                *ngFor="let tract of visibleTractWithAccountDtos"
                [class.selected]="
                    selectedListItem ==
                    PoolDetailObjectTypeEnum.Tract + tract.TractID
                "
                (click)="
                    getDtoInfoAndHighlightMap(
                        PoolDetailObjectTypeEnum.Tract,
                        tract.TractID
                    )
                "
            >
                Tract {{ tract.TractNumber }}
                <p class="small mb-0">
                    Owner #{{ tract.Landowner?.AccountNumber }}
                </p>
                <p class="small mb-0">
                    Operator #{{ tract.PrimaryOperator?.AccountNumber }}
                </p>
            </li>
            <li
                class="list-group-item"
                *ngFor="let well of visibleWells"
                [class.selected]="
                    selectedListItem ==
                    PoolDetailObjectTypeEnum.Well + well.WellID
                "
                (click)="
                    getDtoInfoAndHighlightMap(
                        PoolDetailObjectTypeEnum.Well,
                        well.WellID
                    )
                "
            >
                Well {{ well.WellNumber }}
                <p class="small mb-0">
                    Operator #{{
                        well.ReportingOperator
                            ? well.ReportingOperator.AccountNumber
                            : "Not Provided"
                    }}
                </p>
            </li>
        </ul>
    </div>
    <div class="col-sm-8 col-md-9 col-sm-push-4 col-md-push-3">
        <div
            id="{{ mapID }}"
            class="location-card"
            [style.height]="mapHeight"
        ></div>
    </div>
</div>
<div class="row mt-4" *ngIf="selectedListItem">
    <div
        class="col-12"
        style="background-color: #eee; background-clip: content-box"
    >
        <div
            class="row"
            *ngIf="selectedObjectType === PoolDetailObjectTypeEnum.Tract"
        >
            <div class="col-xs-12 col-sm-6 col-lg-4 mt-4">
                <h2 class="ms-3">Tract {{ selectedDto.TractNumber }}</h2>
            </div>
            <div class="col-xs-12 col-sm-6 col-lg-4 mt-4 mb-lg-4">
                <p class="ms-3">
                    <strong>
                        <field-definition
                            [fieldDefinitionType]="'Type'"
                        ></field-definition>
                    </strong>
                    {{ selectedDto.TractType }}
                </p>
                <p class="ms-3">
                    <strong>APN </strong>{{ selectedDto.ParcelNumber }}
                </p>
                <p class="ms-3">
                    <strong>
                        <field-definition
                            [fieldDefinitionType]="'FinalAcres'"
                        ></field-definition>
                    </strong>
                    {{ selectedDto.FinalAcres }}
                </p>
                <p class="ms-3">
                    <strong>Owner </strong
                    ><a
                        routerLink="/accounts/{{
                            selectedDto.Landowner.AccountID
                        }}"
                        >{{ selectedDto.Landowner.AccountNumber }}
                        <span *ngIf="selectedDto.Landowner.FullName">
                            - {{ selectedDto.Landowner.FullName }}</span
                        ></a
                    >
                </p>
                <p class="ms-3">
                    <strong>Operator </strong
                    ><a
                        *ngIf="selectedDto.PrimaryOperator"
                        routerLink="/accounts/{{
                            selectedDto.PrimaryOperator.AccountID
                        }}"
                        >{{ selectedDto.PrimaryOperator.AccountNumber }}
                        <span *ngIf="selectedDto.PrimaryOperator.FullName">
                            - {{ selectedDto.PrimaryOperator.FullName }}</span
                        ></a
                    >
                </p>
            </div>
            <div class="col-xs-12 col-sm-12 col-lg-4 mt-4 text-sm-center">
                <a
                    class="btn btn-splash ms-3 mb-4"
                    routerLink="/tracts/{{ selectedDto.TractID }}"
                    >View Tract {{ selectedDto.TractNumber }} Details</a
                >
            </div>
        </div>
        <div
            class="row"
            *ngIf="selectedObjectType === PoolDetailObjectTypeEnum.Well"
        >
            <div class="col-xs-12 col-sm-6 col-lg-4 mt-4">
                <h2 class="ms-3">Well {{ selectedDto.WellNumber }}</h2>
            </div>
            <div class="col-xs-12 col-sm-6 col-lg-4 mt-4 mb-lg-4">
                <p class="ms-3">
                    <strong>
                        <field-definition
                            [fieldDefinitionType]="'PrimaryUse'"
                        ></field-definition>
                    </strong>
                    {{ selectedDto.WellUse }}
                </p>
                <p class="ms-3">
                    <strong>Reg # </strong>{{ selectedDto.RegistrationNumber }}
                </p>
                <p class="ms-3">
                    <strong>Reporting Operator </strong
                    ><a
                        *ngIf="selectedDto.ReportingOperator"
                        routerLink="/accounts/{{
                            selectedDto.ReportingOperator.AccountID
                        }}"
                        >{{ selectedDto.ReportingOperator.AccountNumber }}
                        <span *ngIf="selectedDto.ReportingOperator.FullName">
                            - {{ selectedDto.ReportingOperator.FullName }}</span
                        ></a
                    >
                    <span *ngIf="!selectedDto.ReportingOperator"
                        >Not Provided</span
                    >
                </p>
            </div>
            <div class="col-xs-12 col-sm-12 col-lg-4 mt-4 text-sm-center">
                <a
                    class="btn btn-splash ms-3 mb-4"
                    routerLink="/wells/{{ selectedDto.WellID }}"
                    >View Well {{ selectedDto.WellNumber }} Details</a
                >
            </div>
        </div>
    </div>
</div>
