<div class="table-responsive" *ngIf="allocationPlanToDisplay">
    <table class="table table-sm table-borderless">
        <tr>
            <th class="text-sm-start pb-0 mb-0">
                <a
                    style="cursor: pointer"
                    class="collapsed"
                    (click)="showAcresManagedDetails = !showAcresManagedDetails"
                >
                    <i
                        *ngIf="
                            showAcresManagedDetails;
                            else dontShowAcresManagedDetails
                        "
                        class="fas fa-minus text-muted"
                        style="font-size: smaller"
                    ></i>
                    <ng-template #dontShowAcresManagedDetails>
                        <i
                            class="fas fa-plus text-muted"
                            style="font-size: smaller"
                        ></i>
                    </ng-template>
                    Acres Managed
                </a>
            </th>
            <td class="text-sm-end pb-0 mb-0">
                {{ getTotalTractAcreage() | number: "1.1-2" }} ac
            </td>
        </tr>
        <ng-container *ngIf="showAcresManagedDetails">
            <tr>
                <td colspan="2">
                    <hr class="p-0 m-0" />
                </td>
            </tr>
            <tr *ngFor="let tract of tracts">
                <th class="text-sm-end">
                    <a routerLink="/tracts/{{ tract.TractID }}">{{
                        tract.TractNumber
                    }}</a>
                </th>
                <td class="text-sm-end">
                    {{ tract.FinalAcres | number: "1.1-2" }} ac
                </td>
            </tr>
        </ng-container>
        <tr>
            <td colspan="2" class="text-start pt-0 mt-0">
                <small
                    >Total acres of all irrigated tracts managed under this
                    pool</small
                >
            </td>
        </tr>
        <tr>
            <td colspan="2">
                <hr/>
            </td>
        </tr>
        <tr>
            <th class="text-sm-start pb-0 mb-0">
                <ng-container *ngIf="isRealAllocationPlan()">
                    <a
                        style="cursor: pointer"
                        class="collapsed"
                        (click)="showAllocationDetails = !showAllocationDetails"
                    >
                        <i
                            *ngIf="
                                showAllocationDetails;
                                else dontShowAllocationDetails
                            "
                            class="fas fa-minus text-muted"
                            style="font-size: smaller"
                        ></i>
                        <ng-template #dontShowAllocationDetails>
                            <i
                                class="fas fa-plus text-muted"
                                style="font-size: smaller"
                            ></i>
                        </ng-template>
                        <field-definition
                            [fieldDefinitionType]="'Allocation'"
                            class="ps-1"
                        ></field-definition>
                    </a>
                </ng-container>
                <ng-container *ngIf="!isRealAllocationPlan()">
                    <field-definition
                        [fieldDefinitionType]="'Allocation'"
                    ></field-definition>
                </ng-container>
            </th>
            <td class="text-sm-end pb-0 mb-0">
                <span *ngIf="isRealAllocationPlan()"
                    >{{
                        getResultInUnitsShown(getAllocation()) | number: "1.1-2"
                    }}
                    {{ unitsShown }}</span
                >
                <span *ngIf="!isRealAllocationPlan()">N/A</span>
            </td>
        </tr>
        <ng-container *ngIf="showAllocationDetails && isRealAllocationPlan()">
            <tr>
                <td colspan="2">
                    <hr class="p-0 m-0" />
                </td>
            </tr>
            <tr *ngFor="let tract of tracts">
                <th class="text-sm-end">
                    <a routerLink="/tracts/{{ tract.TractID }}">{{
                        tract.TractNumber
                    }}</a>
                </th>
                <td class="text-sm-end">
                    {{
                        getTractAllocationInUnitsShown(tract) | number: "1.1-2"
                    }}
                    {{ unitsShown }}
                </td>
            </tr>
        </ng-container>
        <tr>
            <td colspan="2" class="text-start pt-0 mt-0">
                <small
                    >Your allocated water budget for
                    {{
                        allocationPlanToDisplay.DisplayName.toLowerCase()
                    }}</small
                >
            </td>
        </tr>
        <tr>
            <th class="text-sm-start pb-0 mb-0">
                <ng-container *ngIf="poolingAgreements.length > 0">
                    <a
                        style="cursor: pointer"
                        class="collapsed"
                        (click)="
                            showPoolingAgreementDetails =
                                !showPoolingAgreementDetails
                        "
                    >
                        <i
                            *ngIf="
                                showPoolingAgreementDetails;
                                else dontShowPoolingAgreementdetails
                            "
                            class="fas fa-minus text-muted"
                            style="font-size: smaller"
                        ></i>
                        <ng-template #dontShowPoolingAgreementdetails>
                            <i
                                class="fas fa-plus text-muted"
                                style="font-size: smaller"
                            ></i>
                        </ng-template>
                        <field-definition
                            [fieldDefinitionType]="'PoolingAgreements'"
                            class="ps-1"
                        ></field-definition>
                    </a>
                </ng-container>
                <ng-container *ngIf="poolingAgreements.length == 0">
                    <field-definition
                        [fieldDefinitionType]="'PoolingAgreements'"
                    ></field-definition>
                </ng-container>
            </th>
            <td class="text-sm-end pb-0 mb-0">
                <span *ngIf="poolingAgreements.length > 0"
                    >{{
                        getResultInUnitsShown(getPoolingAgreementWater())
                            | number: "1.1-2"
                    }}
                    {{ unitsShown }}</span
                >
                <span *ngIf="poolingAgreements.length == 0">N/A</span>
            </td>
        </tr>
        <ng-container *ngIf="showPoolingAgreementDetails">
            <tr>
                <td colspan="2">
                    <hr class="p-0 m-0" />
                </td>
            </tr>
            <tr *ngFor="let poolingAgreement of getFilteredPoolingAgreements()">
                <th class="text-sm-end">
                    <a
                        routerLink="/pooling-agreements/{{
                            poolingAgreement.PoolingAgreementNumber
                        }}"
                        >{{ poolingAgreement.PoolingAgreementNumber }}</a
                    >
                </th>
                <td class="text-sm-end">
                    {{
                        getResultInUnitsShown(
                            getAllocationContributionFromPoolingAgreement(
                                poolingAgreement
                            )
                        ) | number: "1.1-2"
                    }}
                    {{ unitsShown }}
                </td>
            </tr>
        </ng-container>
        <tr>
            <th class="text-sm-start pb-0 mb-0">
                <field-definition
                    [fieldDefinitionType]="'TotalAvailableWater'"
                ></field-definition>
            </th>
            <td class="text-sm-end pb-0 mb-0">
                <span *ngIf="isRealAllocationPlan()"
                    >{{
                        getResultInUnitsShown(getTotalAvailable())
                            | number: "1.1-2"
                    }}
                    {{ unitsShown }}</span
                >
                <span *ngIf="!isRealAllocationPlan()">N/A</span>
            </td>
        </tr>
        <tr>
            <td colspan="2" class="text-start pt-0 mt-0">
                <small>Allocation + Pooling Agreements</small>
            </td>
        </tr>
        <tr>
            <td colspan="2">
                <hr />
            </td>
        </tr>
        <tr>
            <th class="text-sm-start pb-0 mb-0">
                <field-definition
                    [fieldDefinitionType]="'UsageToDate'"
                ></field-definition>
            </th>
            <td class="text-sm-end pb-0 mb-0">
                <span
                    *ngIf="
                        getWaterUsageToDate() as waterUsageToDate;
                        else noWaterUsage
                    "
                >
                    {{
                        getResultInUnitsShown(waterUsageToDate)
                            | number: "1.1-2"
                    }}
                    {{ unitsShown }}
                </span>
                <ng-template #noWaterUsage>
                    <span class="systemText">Not available</span>
                </ng-template>
            </td>
        </tr>
        <tr>
            <td colspan="2" class="text-start pt-0 mt-0">
                <small
                    >Total usage for
                    {{
                        allocationPlanToDisplay.DisplayName.toLowerCase()
                    }}</small
                >
            </td>
        </tr>
        <tr>
            <th class="text-sm-start pb-0 mb-0">
                <strong
                    ><field-definition
                        [fieldDefinitionType]="'RemainingAvailableWater'"
                    ></field-definition
                ></strong>
            </th>
            <td class="text-sm-end pb-0 mb-0">
                <ng-container
                    *ngIf="
                        getWaterUsageToDate() as waterUsageToDate;
                        else noWaterUsage
                    "
                >
                    <span *ngIf="isRealAllocationPlan()">
                        {{
                            getResultInUnitsShown(
                                getTotalAvailable() - waterUsageToDate
                            ) | number: "1.1-2"
                        }}
                        {{ unitsShown }}
                    </span>
                    <span *ngIf="!isRealAllocationPlan()"> N/A </span>
                </ng-container>
                <ng-template #noWaterUsage>
                    <span class="systemText">Not available</span>
                </ng-template>
            </td>
        </tr>
        <tr>
            <td colspan="2" class="text-start pt-0 mt-0">
                <small>Total Available Water - Usage To Date</small>
            </td>
        </tr>

        <tr>
            <th class="text-sm-start pb-0 mb-0">Average Annual Usage</th>
            <td class="text-sm-end pb-0 mb-0">
                {{ getResultInUnitsShown(getAnnualUsage()) | number: "1.1-2" }}
                {{ unitsShown }}
            </td>
        </tr>
        <tr>
            <td colspan="2" class="text-start pt-0 mt-0">
                <small
                    >Average annual usage for
                    {{
                        allocationPlanToDisplay.DisplayName.toLowerCase()
                    }}</small
                >
            </td>
        </tr>
    </table>
</div>
